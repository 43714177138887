import React, { VFC } from "react";

export interface FormButtonI {
  active: boolean;
  text: string;
  className: string;
  activeClassName: string;
  name: string;
  value?: any;
  refName?: string;
  type?: "button" | "submit";
}

interface FormButtonsI {
  onClick: any;
  buttons: FormButtonI[];
}

export const FormButtons: VFC<FormButtonsI> = ({ buttons, onClick }) => {
  return (
    <>
      {buttons.map(
        ({ name, className, activeClassName, active, type, text, value }) => (
          <button
            key={name}
            className={`${className} ${active ? activeClassName : ""}`}
            name={name}
            value={value ?? ""}
            type={type ? type : "button"}
            onClick={onClick}
          >
            {text}
          </button>
        )
      )}
    </>
  );
};
