import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import GoBack from "../../../../Components/Buttons/GoBack/GoBack";
import SaveButton from "../../../../Components/Buttons/SaveButton";
import { AddButton, Alert, FormInput } from "../../../../Components/Form";
import AddPerson from "../../../../Components/PersonBox/AddPerson";
import PersonBox from "../../../../Components/PersonBox/PersonBox";
import { EDIT_GIFT, POST_GIFT } from "../../../../GraphQL/Mutations";
import { allPeopleArrayFn } from "../../../../Helpers";
import {
  useModal,
  useSaveResultToRedux,
  useSendMutation,
} from "../../../../hooks";
import { getGifts, initialPerson } from "../../../../Redux/actions";
import { prepareGiftData } from "../giftsHelpers";
import {
  mortgageFormOptions,
  ownerFormOptions,
  propertyInfoFormOptions,
} from "./formData";

interface PropertyAsGiftPropsI {
  editingGift: any;
}

const PropertyAsGift = (props: PropertyAsGiftPropsI) => {
  const history = useHistory();

  const { register, errors, handleSubmit, reset, getValues } = useForm();

  const [error, setError] = useState("");

  const [isAddPersonModalOpen, toggleAddPersonModal] = useModal();

  const [personSelected, setPersonSelected] = useState<any>();

  const saveToRedux = useSaveResultToRedux();

  const {
    sendMutationRequest: sendPostGiftMutation,
    loading: postGiftLoading,
  } = useSendMutation(POST_GIFT);
  const {
    sendMutationRequest: sendEditGiftMutation,
    loading: editGiftLoading,
  } = useSendMutation(EDIT_GIFT);

  const {
    aboutYouReducer: {
      children,
      marital_status: { partner },
    },
    randomPeopleReducer: randomPeople,
  } = useSelector((state: any) => state);

  const editingGift = !!props?.editingGift?._id;

  const allPeopleArr = allPeopleArrayFn(children, partner, randomPeople);

  async function onSubmit(data: any, e: any) {
    e.preventDefault();

    setError("");

    if (!data.leaveItTo.length) {
      setError("Zvolte alespoň jednu osobu pro zanechání odkazu");

      return;
    }

    if (editingGift) {
      const { updateGift } =
        (await sendEditGiftMutation({
          gift: prepareGiftData(data, "property"),
          id: props.editingGift._id,
        })) ?? {};

      saveToRedux(getGifts, updateGift.gifts);
    } else {
      const { newGift } =
        (await sendPostGiftMutation({
          gift: prepareGiftData(data, "property"),
        })) ?? {};

      saveToRedux(getGifts, newGift);
    }

    history.push("/odkazy");
  }

  function addNewPerson() {
    setPersonSelected({ ...initialPerson, type: "default" });

    toggleAddPersonModal();
  }

  function markNewlyCreatedPersonAsSelected(id: string) {
    reset({ ...getValues(), leaveItTo: [...getValues("leaveItTo"), id] });
  }

  useEffect(() => {
    if (props?.editingGift?._id) {
      reset(props.editingGift);
    }
  }, []);

  return (
    <div className="form form-gift_type">
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          title="Odkázat nemovitost"
          description={[
            "Pamatujte na to, že formou odkazu můžete rozhodovat pouze o 75% svého majetku. Většina lidí nechá svou nemovitost k rozdělení mezi své dědice.",
            "Pokud chcete odkázat nemovitost, vyplňte prosím úplnou adresu nemovitosti nebo její číslo v katastru nemovitostí. Zkrátka tak, aby bylo opravdu jasné, o kterou nemovitost se jedná.",
          ]}
          errors={errors}
          input={{
            name: "gift",
            type: "text",
            register: register({
              required: { value: true, message: "Toto pole je povinné" },
            }),
            placeholder: "Např.: Ulice 123, Praha, 123 45",
          }}
          label="Adresa"
        />

        {propertyInfoFormOptions.map((propertyInfoOption) => (
          <FormInput
            key={propertyInfoOption.inputName}
            className="modal_form"
            label={propertyInfoOption.label}
            errors={errors}
            input={{
              name: propertyInfoOption.inputName,
              register: register({
                maxLength: {
                  value: 120,
                  message: "Maximální počet znaků je 120",
                },
              }),
              type: "text",
              placeholder: propertyInfoOption.placeholder,
            }}
          />
        ))}

        <FormInput
          className="modal_form modal_form-radio"
          label="Platíte na tuto nemovitost hypotéku?"
        >
          {mortgageFormOptions.map((mortgageOption) => (
            <label className="radio-label" key={mortgageOption.value}>
              <input
                name="mortgage"
                value={mortgageOption.value}
                ref={register}
                type="radio"
                className="radio-input"
              />
              <div className="radio-checkbox"></div>
              {mortgageOption.text}
            </label>
          ))}
        </FormInput>

        <FormInput
          className="modal_form modal_form-radio modal_form-ownership"
          label="Kdo je vlastníkem této nemovitosti?"
        >
          {ownerFormOptions.map((ownerOption) => (
            <label className="radio-label" key={ownerOption.value}>
              <input
                name="owner"
                value={ownerOption.value}
                ref={register}
                type="radio"
                className="radio-input"
              />
              <div className="radio-checkbox"></div>
              {ownerOption.text}
            </label>
          ))}
        </FormInput>

        <h3>Komu chcete tuto nemovitost odkázat?</h3>

        {allPeopleArr.map((person: any, idx: number) => (
          <PersonBox
            key={idx}
            id={person.id}
            name="leaveItTo"
            person={person}
            personName={person.fullName}
            description={moment(person.birth, "YYYY-MM-DD").format(
              "DD.MM.YYYY"
            )}
            checkbox={true}
            value={person._id}
            register={register}
          />
        ))}

        <AddButton className="partner_add" onClick={addNewPerson}>
          Přidat osobu
        </AddButton>

        {error && <Alert text={error} />}

        <FormInput
          title="Zanechte osobní zprávu (volitelné)"
          description={[
            "Napište co a proč to zanecháváte. Můžete jim také poradit, jak s danou věcí naložit. Co byste chtěl/a aby s ní udělal/la/li?",
          ]}
          errors={errors}
          label="Osobní zpráva"
        >
          <textarea
            ref={register}
            name="giftMessage"
            cols={58}
            placeholder="Sem vložte vaší zprávu"
          />
        </FormInput>

        <SaveButton
          loading={postGiftLoading || editGiftLoading}
          readyToRoll={true}
        />
      </form>

      {isAddPersonModalOpen && (
        <AddPerson
          toggle={toggleAddPersonModal}
          personType={personSelected.type}
          personSelected={personSelected}
          append={() => {}}
          callback={markNewlyCreatedPersonAsSelected}
        />
      )}
    </div>
  );
};

export default PropertyAsGift;
