import React from "react";
import Modal from "./Modal";

interface NotPaidForWillModalPropsI {
  toggleNotPaidForWillModal: () => void;
  logout: (skipModal: boolean) => void;
}

const NotPaidForWillModal = ({
  toggleNotPaidForWillModal,
  logout,
}: NotPaidForWillModalPropsI) => {
  return (
    <Modal
      toggle={toggleNotPaidForWillModal}
      header="Opravdu se chcete odhlásit?"
    >
      <div className="person_form">
        <p style={{ fontSize: "17px" }}>
          Vaše závěť stále není platná. K tomu, aby platná byla, nejprve
          musíte vyplnit všechny povinné kroky a potom nám závěť odeslat na
          kontrolu a sepsání dokumentu.
        </p>
      </div>
      <div className="modal_footer">
        <button className="modal_cancel" onClick={() => logout(true)}>
          Odhlásit se
        </button>

        <button
          className="modal_save"
          onClick={() => toggleNotPaidForWillModal()}
        >
          Zpět
        </button>
      </div>
    </Modal>
  );
};

export default NotPaidForWillModal;
