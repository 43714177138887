import { gql } from "@apollo/client";

export const NAMES_MUTATION = gql`
  mutation names(
    $fullName: String!
    $nickname: String
    $birth: String!
    $gender: String
  ) {
    names(
      names: {
        fullName: $fullName
        nickname: $nickname
        birth: $birth
        gender: $gender
      }
    ) {
      fullName
      nickname
      birth
      gender
    }
  }
`;

export const ADDRESS_MUTATION = gql`
  mutation address(
    $city: String!
    $street: String!
    $zipCode: String!
    $phoneNumber: String!
    $country: String!
  ) {
    address(
      address: {
        city: $city
        street: $street
        zipCode: $zipCode
        phoneNumber: $phoneNumber
        country: $country
      }
    ) {
      city
      street
      zipCode
      phoneNumber
      country
    }
  }
`;

export const MARITAL_STATUS_MUTATION = gql`
  mutation maritalStatusMutation(
    $status: String
    $partnerName: String
    $birth: String
    $over18: Boolean
    $email: String
    $street: String
    $city: String
  ) {
    maritalStatus(
      maritalStatus: {
        status: $status
        partner: {
          fullName: $partnerName
          over18: $over18
          email: $email
          birth: $birth
          address: { city: $city, street: $street }
        }
      }
    ) {
      status
      partner {
        _id
        fullName
        birth
        address {
          city
          street
        }
      }
    }
  }
`;

export const CHANGE_MARITAL_STATUS_MUTATION = gql`
  mutation changeMaritalStatusMutation($status: String) {
    changeMaritalStatus(status: $status)
  }
`;


export const EDIT_PARTNER_MUTATION = gql`
  mutation editPartnerMutation($partnerInput: partnerInput) {
    editPartner(partner: $partnerInput) {
      maritalStatus {
        partner {
          _id
          fullName
          email
          over18
          birth
          address {
            city
            street
            zipCode
            phoneNumber
            country
          }
        }
      }
    }
  }
`;






export const DELETE_PARTNER_MUTATION = gql`
  mutation deletePartnerMutation($id: ID!) {
    deletePartner(_id: $id) {
      maritalStatus {
        partner {
          _id
        }
      }
      pets {
        _id
        name
        guardian
      }
      gifts {
        _id
        giftType
        gift
        leaveItTo
        giftMessage
        charities
      }
      inheritEstate {
        percentageFromAssets
        charity
        person
        theirKids
        otherInheritors
        backupInheritors {
          person
          percentages
          charity
        }
      }
      children {
        _id
        fullName
        birth
        over18
        guardian
      }
    }
  }
`;

export const ADD_CHILD_MUTATION = gql`
  mutation addChildMutation(
    $fullName: String!
    $birth: String!
    $over18: Boolean!
    $street: String
    $city: String
  ) {
    newChild(
      newChild: {
        fullName: $fullName
        birth: $birth
        over18: $over18
        address: { city: $city, street: $street }
      }
    ) {
      _id
      fullName
      birth
      over18
      guardian
      address {
        city
        street
      }
    }
  }
`;

export const UPDATE_CHILD_MUTATION = gql`
  mutation updateChildMutation(
    $fullName: String
    $birth: String
    $over18: Boolean
    $id: ID!
    $guardian: [ID]
    $street: String
    $city: String
  ) {
    updateChild(
      child: {
        fullName: $fullName
        birth: $birth
        over18: $over18
        guardian: $guardian
        address: { city: $city, street: $street }
      }
      _id: $id
    ) {
      children {
        _id
        fullName
        birth
        over18
        guardian
        address {
          city
          street
        }
      }
    }
  }
`;

export const DELETE_CHILD_MUTATION = gql`
  mutation deleteChildMutation($id: ID!) {
    deleteChild(_id: $id) {
      children {
        _id
        fullName
        birth
        over18
        guardian
        address {
          city
          street
        }
      }
      pets {
        _id
        name
        guardian
      }
      gifts {
        _id
        giftType
        gift
        leaveItTo
        giftMessage
        charities
      }
      inheritEstate {
        percentageFromAssets
        charity
        person
        theirKids
        otherInheritors
        backupInheritors {
          person
          percentages
          charity
        }
      }
    }
  }
`;

export const DELETE_ALL_CHILDREN_MUTATION = gql`
  mutation deleteAllChildren {
    deleteAllChildren
  }
`;

export const ADD_PET_MUTATION = gql`
  mutation addPetMutation($fullName: String!) {
    newPet(newPet: { name: $fullName }) {
      _id
      name
      guardian
    }
  }
`;
export const UPDATE_PET_MUTATION = gql`
  mutation updatePetMutation($fullName: String, $id: ID!, $guardian: [ID]) {
    updatePet(pet: { name: $fullName, guardian: $guardian }, _id: $id) {
      pets {
        _id
        name
        guardian
      }
    }
  }
`;

export const DELETE_PET_MUTATION = gql`
  mutation deletePetMutation($id: ID!) {
    deletePet(_id: $id) {
      pets {
        _id
        name
        guardian
      }
    }
  }
`;

export const DELETE_ALL_PETS_MUTATION = gql`
  mutation {
    deleteAllPets
  }
`;

export const HAS_CHILDREN_MUTATION = gql`
  mutation hasChildren($hasChildren: Boolean) {
    hasChildren(hasChildren: $hasChildren)
  }
`;

export const HAS_PETS_MUTATION = gql`
  mutation hasPets($hasPets: Boolean) {
    hasPets(hasPets: $hasPets)
  }
`;
