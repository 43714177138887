import { gql } from "@apollo/client";

export const DELETE_USER_MUTATION = gql`
  mutation {
    deleteUser
  }
`;

export const SEND_FORGOT_PASSWORD_EMAIL_MUTATION = gql`
  mutation forgotPassword($email: String) {
    forgotPassword(email: $email)
  }
`;

export const SEND_NEW_PASSWORD_MUTATION = gql`
  mutation sendNewPassword($password: String!, $slug: String!) {
    sendNewPassword(password: $password, slug: $slug)
  }
`;

// nekde to bude blbe v tom jak definuju ty props - proto to hazi 400

export const CHANGE_ADMIN_MUTATION = gql`
  mutation changeAdmin($userId: String, $willDownloadURL: String, $orderStatus: String) {
    changeAdmin(userId: $userId, willDownloadURL: $willDownloadURL, orderStatus: $orderStatus)
  }
`;
