import { gql } from "@apollo/client";

export const LOGOUT_QUERY = gql`
  query {
    logout
  }
`;
export const LOGIN_QUERY = gql`
  query loginUser($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      counter
      will {
        names {
          fullName
          nickname
          birth
          personalNumber
          gender
        }
        address {
          city
          street
          zipCode
          country
          phoneNumber
        }
        maritalStatus {
          partner {
            _id
            fullName
            email
            birth
            over18
            address {
              city
              street
            }
          }
          status
        }
        children {
          _id
          fullName
          birth
          over18
          guardian
          address {
            street
            city
          }
        }
        pets {
          _id
          name
          guardian
        }
        assets {
          _id
          type
          bankName
          insuranceProvider
          pensionProvider
          stocksCompany
          ownedBusiness
          address
          mortgage
          owner
          other
        }
        gifts {
          _id
          gift
          giftType
          giftMessage
          leaveItTo
          charities
          mortgage
          owner
          propertyInfo1
          propertyInfo2
          propertyInfo3
        }
        restingPlace {
          place
          message
        }
        specialWishes
        inheritEstate {
          percentageFromAssets
          person
          charity
        }
        people {
          _id
          fullName
          birth
          type
          address {
            city
            street
          }
        }
        hasChildren
        hasPets
        allowInheritorsToDecide
        price
      }
    }
  }
`;


export const ADMIN_QUERY = gql`
  query admin($userId: String!, $password: String!) {
    admin(userId: $userId, password: $password) {
      user {
        active
        refferalCodeforFriends
        refferalCodeforPartner
        paidForWill
        loginCounter
        orderStatus
        willDownloadURL
        sentWillIsNotFinishedMail
        forgottenPasswordSlug
        invoiceId
        _id
        email
        will
        password
        token
        createdAt
        updatedAt
      }
      will {
        names {
          fullName
          nickname
          birth
          personalNumber
          gender
        }
        address {
          city
          street
          zipCode
          country
          phoneNumber
        }
        maritalStatus {
          partner {
            _id
            fullName
            email
            birth
            over18
            address {
              city
              street
            }
          }
          status
        }
        children {
          _id
          fullName
          birth
          over18
          guardian
          address {
            street
            city
          }
        }
        pets {
          _id
          name
          guardian
        }
        assets {
          _id
          type
          bankName
          insuranceProvider
          pensionProvider
          stocksCompany
          ownedBusiness
          address
          mortgage
          owner
          other
        }
        gifts {
          _id
          gift
          giftType
          giftMessage
          leaveItTo
          charities
          mortgage
          owner
          propertyInfo1
          propertyInfo2
          propertyInfo3
        }
        restingPlace {
          place
          message
        }
        specialWishes
        inheritEstate {
          percentageFromAssets
          person
          charity
          theirKids
          otherInheritors
          backupInheritors {
            person
            percentages
            charity
          }
        }
        people {
          _id
          fullName
          birth
          type
          address {
            city
            street
          }
        }
        hasChildren
        hasPets
        allowInheritorsToDecide
        price
      }
    }
  }
`;
