import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import "./PartnerModal.scss";
import googleRating from "../../Images/googleRating.svg";
import firmyRating from "../../Images/firmyRating.svg";
import styled from "styled-components";

const PrepaidWidgetInner = styled.div`
  h3 {
    font-family: NewKansas-Medium;
    font-size: 25px;
    color: #0a1c33;
    line-height: 32px;
  }

  p {
    font-family: VisueltPro-Regular;
    font-size: 16px;
    color: #000000;
    line-height: 25px;
    margin-top: 25px;
    margin-bottom: 25px;
    opacity: 0.7;

    a {
      color: #000000;
    }
  }

  .bubble {
    background: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    width: fit-content;
    border: 2px solid #E5E5E5;
    margin-top: 30px;

    @media (max-width: 400px) {
      width: calc(100% - 4px);
    }

    .top {
      padding: 20px;
      font-family: VisueltPro-Medium;
      text-align: center;
      color: #000;
      background: #E5E5E5;
    }

    .bot {
      padding: 20px;

      p {
        font-family: VisueltPro-Regular;
        opacity: 1;
        text-align: center;
        font-size: 16px;
        color: #595959;
        margin: 20px auto;

        @media (max-width: 400px) {
          max-width: 190px;
        }


        &:first-child {
          margin-top: 0;

          span {
            font-family: VisueltPro-Medium;
            font-size: 20px;
            color: #000;
     
            @media (max-width: 400px) {
              font-size: 23px;
            }
          }
        }

        &:last-child {
          border-top: 1px solid #E5E5E5;
          width: calc(100% - 20px);
          max-width: 300px;
          margin: 0 auto;
          padding-top: 20px;
            
          span {
            font-family: VisueltPro-Medium;
            color: #000;
            font-size: 20px;
     
            @media (max-width: 400px) {
              font-size: 23px;
            }
          }
        }
      }
    }
  }

  .buttons {
    display: flex;
    margin-top: 30px;

    @media (max-width: 430px) {
      display: block;
    }

    a {
      border: none;
      text-align: center;
      font-family: VisueltPro-Medium;
      font-size: 18px;
      color: #243a58;
      background: #cbd3de;
      border-radius: 5px;
      padding: 19px 30px;
      cursor: pointer;
      text-decoration: none;
      width: fit-content;
      margin-right: 20px;

      &:hover {
        background: #b5c4d9;
      }

      &:last-child {
        color: #243a58;
        background: none;
        border: 2px solid #cbd3de;
        padding: 17px 28px;

        @media (max-width: 430px) {
          margin-top: 10px;
        }

        @media (max-width: 400px) {
          width: calc(100% - 4px);
          padding-left: 0;
          padding-right: 0;
        }

        &:hover {
          background: #e1e7ec;
        }
      }

      @media (max-width: 430px) {
        display: block;
      }

      @media (max-width: 400px) {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .trust {
    margin-top: 30px;
    margin-bottom: 0;

    .right {
      .desc {
        font-family: VisueltPro-Medium;
        font-size: 17px;
        color: #575757;
      }

      div {
        display: flex;
        align-items: center;
        margin-top: 15px;

        @media (max-width: 550px) {
          display: block;
        }

        img {
          display: block;
          margin-right: 20px;

          &:last-child {
            margin-right: 0;

            @media (max-width: 550px) {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
`;



interface PrepaidModalPropsI {
  toggle: any;
}

const PrepaidModal = (props: PrepaidModalPropsI) => {

  return (
    <Modal header="Předplacený pohřeb" toggle={props.toggle}>
      <div className="partner-modal-content">
        <PrepaidWidgetInner>
          <h3>
            Klid v duši a jistota, že mám vše zařízeno
          </h3>
          <p>
            Ušetřete svým nejbližším starosti a stres z plánování vašeho pohřbu. Vše s námi domluvte předem: přes internet, spolehlivě a za transparentní cenu.
            <br/><br/>
            Jakmile dojde k vašemu úmrtí, naše <a href="https://goodbye.cz/predplaceny-pohreb/" target="_blank">spolehlivá pohřební služba</a> se o vše postará. Vypravíme vám přesně takový pohřeb, jaký jste si naplánovali, kontaktujeme všechny vaše přátele a rodinu, vyřídíme veškerou dokumentaci na matrice a vaší rodině budeme asistovat po celou dobu dědického řízení.
          </p>

          <div className="bubble">
            <div className="top">Základní cena od</div>
            <div className="bot">
              <p><span>990 Kč měsíčně </span>(po dobu 5 let)</p>
              <p>nebo<span> 39 990 Kč </span>jednorázově</p>
            </div>
          </div>

          <div className="buttons">
            <a target="_blank" href="https://goodbye.cz/predplaceny-pohreb/">Domluvit si schůzku</a>
            <a target="_blank" href="https://goodbye.cz/predplaceny-pohreb/">Zjistit více informací</a>
          </div>

          <div className="trust">
            <div className="right">
              <span className="desc">Nejlépe hodnocená pohřební služba za 2022 (4,95/5 hvězdiček)</span>
              <div>
                <img src={googleRating} alt="googleRating" />
                <img src={firmyRating} alt="firmyRating" />
              </div>
            </div>
          </div>
        </PrepaidWidgetInner>
        
      </div>
    </Modal>
  );
};

export default PrepaidModal;
