import React from 'react';
import './SurveyButton.scss';

interface SaveButtonPropsI {
  readyToRoll: boolean;
  loading: boolean;
  handleContinue?: any;
  loadingText?: string;
  buttonText?: string;
}

const SaveButton = (props: SaveButtonPropsI) => {
  const {
    readyToRoll,
    loading,
    handleContinue,
    buttonText = 'Uložit a pokračovat',
    loadingText = 'Ukládání...'
  } = props;

  return readyToRoll ? (
    <button
      type='submit'
      className='save-continue'
      onClick={handleContinue ? (e) => handleContinue(e) : () => {}}
    >
      {loading ? loadingText : buttonText}
    </button>
  ) : (
    <button type='submit' className='save-continue save-continue-false'>
      {buttonText}
    </button>
  );
};

export default SaveButton;
