interface AssetsState {
  id: string;
  asset_type: string;
  address?: string;
  mortgage?: string;
  owner?: string;
  bankName?: string;
  provider?: string;
  companyName?: string;
}

export const assetsReducer = (state: AssetsState[] = [], action: any) => {
  switch (action.type) {
    case "ADD_ASSET":
      return [...state, action.payload];
    case "REMOVE_ASSET":
      return state.filter((el) => el.id !== action.payload);
    case "GET_ASSETS":
      return action.payload;
    case "EDIT_ASSET":
      return state.map((el) =>
        el.id === action.payload.id ? action.payload : el
      );
    default:
      return state;
  }
};
