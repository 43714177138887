import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import GoBack from "../../../Components/Buttons/GoBack/GoBack";
import SaveButton from "../../../Components/Buttons/SaveButton";
import { AddButton, Alert, FormInput } from "../../../Components/Form";
import DeleteModal from "../../../Components/Modal/DeleteModal";
import AddPerson from "../../../Components/PersonBox/AddPerson";
import PersonBox from "../../../Components/PersonBox/PersonBox";
import { SEO } from "../../../Components/seo/Seo";
import { DELETE_PERSON_MUTATION } from "../../../GraphQL/Mutations";
import { allPeopleArrayFn } from "../../../Helpers";
import {
  useModal,
  useSaveResultToRedux,
  useSendMutation,
} from "../../../hooks";
import {
  aboutYou_children,
  aboutYou_pets,
  addInheritors,
  getGifts,
  getInheritEstate,
  getRandomPeople,
  initialPerson,
} from "../../../Redux/actions";

const People = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const [error, setError] = useState("");

  const [isAddPersonModalOpen, toggleAddPersonModal] = useModal();
  const [isDeleteModalOpen, toggleDeleteModal] = useModal();

  const [personSelected, setPersonSelected] = useState<any>({
    ...initialPerson,
    type: "inheritor",
  });

  const { register, reset, handleSubmit, setValue, getValues } = useForm();

  const {
    aboutYouReducer: {
      children,
      marital_status: { partner },
    },
    randomPeopleReducer: randomPeople,
    inheritEstateReducer: { inheritors },
  } = useSelector((state: any) => state);

  const inheritorsIDsRedux = inheritors.map((i: any) => i.person);

  const eligibleInheritors = allPeopleArrayFn(children, partner, randomPeople);

  const saveToRedux = useSaveResultToRedux();

  const {
    sendMutationRequest: sendDeletePersonMutation,
    loading: deletePersonLoading,
  } = useSendMutation(DELETE_PERSON_MUTATION);

  const onSubmit = (formData: any, e: any) => {
    e.preventDefault();

    if (!formData.inheritors?.length) {
      setError("Zvolte alespoň jednoho dědice");

      return;
    }

    saveToRedux(
      addInheritors,
      Array.isArray(formData.inheritors)
        ? formData.inheritors
        : [formData.inheritors]
    );

    history.push("/dedictvi/charity");
  };

  function addNewInheritor() {
    setPersonSelected({ ...initialPerson, type: "inheritor" });

    toggleAddPersonModal();
  }

  function editPerson(person: any) {
    setPersonSelected(person);

    toggleAddPersonModal();
  }

  function deleteConfirmation(person: any) {
    setPersonSelected(person);

    toggleDeleteModal();
  }

  function markNewlyCreatedPersonAsSelected(id: string) {
    setValue("inheritors", [...getValues("inheritors"), id]);
  }

  async function deletePerson() {
    const { deleteRandomPerson } =
      (await sendDeletePersonMutation({ id: personSelected._id })) ?? {};

    const { people, children, pets, gifts, inheritEstate } = deleteRandomPerson;

    saveToRedux(getRandomPeople, people);
    saveToRedux(aboutYou_children, children);
    saveToRedux(aboutYou_pets, pets);
    saveToRedux(getGifts, gifts);
    dispatch(
      getInheritEstate(
        inheritEstate.filter((i: any) => i.person),
        inheritEstate.filter((i: any) => i.charity)
      )
    );

    toggleDeleteModal();
  }

  useEffect(() => {
    reset({ inheritors: inheritorsIDsRedux });
  }, []);

  return (
    <div className="estate form estate-people">
      <SEO page="estate_people" />

      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          title={"Komu si přejete přenechat svůj majetek?"}
          description={[
            "V další části můžete rozhodnout, jaký podíl každý z dědiců dostane. Také si budete moci vybrat náhradníky v případě, že by některý z dědiců zemřel ještě před vámi.",
          ]}
        >
          {eligibleInheritors.map((el: any, index: number) => {
            return (
              <PersonBox
                id={el._id}
                key={el._id}
                person={el}
                description={moment(el.birth, "YYYY-MM-DD").format(
                  "DD.MM.YYYY"
                )}
                name="inheritors"
                value={el._id}
                personName={el.fullName}
                checkbox={true}
                register={register}
                edit={editPerson}
                deletePerson={
                  el.type === "inheritor" ? deleteConfirmation : false
                }
              />
            );
          })}

          <AddButton className="partner_add" onClick={addNewInheritor}>
            Přidat dědice
          </AddButton>
        </FormInput>

        {error && <Alert text={error} />}

        <SaveButton loading={false} readyToRoll={true} />
      </form>

      {isAddPersonModalOpen && (
        <AddPerson
          toggle={toggleAddPersonModal}
          personType={personSelected.type}
          personSelected={personSelected}
          append={() => {}}
          callback={markNewlyCreatedPersonAsSelected}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteModal
          headerText="Odstranit dědice"
          loading={deletePersonLoading}
          personSelected={personSelected}
          sendDeletion={deletePerson}
          toggleDeleteModal={toggleDeleteModal}
        />
      )}
    </div>
  );
};

export default People;
