import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import GoBack from "../../../Components/Buttons/GoBack/GoBack";
import SaveButton from "../../../Components/Buttons/SaveButton";
import { Alert, FormInput } from "../../../Components/Form";
import { SEO } from "../../../Components/seo/Seo";
import { RESTING_PLACE_MUTATION } from "../../../GraphQL/Mutations";
import { useSendMutation } from "../../../hooks";
import { addRestingPlace } from "../../../Redux/actions";
import "./RestingPlace.scss";
import { PrepaidWidget } from "../../../Components/banners/PrepaidWidget";

const buttonsStatus = [
  { name: "cremation", text: "Kremace" },
  { name: "cemetery", text: "Uložení do hrobu" },
  { name: "natural", text: "Přírodní pohřeb" },
  { name: "science", text: "Darovat tělo na vědecké účely" },
  { name: "family", text: "Nechat rodinu rozhodnout" },
];

const RestingPlace = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const [place, setPlace] = useState("none");

  const restingPlaceRedux = useSelector(
    (state: any) => state.restingPlaceReducer
  );

  const {
    sendMutationRequest: sendRestingPlaceMutation,
    loading: restingPlaceLoading,
  } = useSendMutation(RESTING_PLACE_MUTATION);

  const { register, errors, handleSubmit, reset, setError, clearErrors } =
    useForm();

  const onSubmit = async (formData: any, e: any) => {
    e.preventDefault();

    if (place === "none") {
      setError("manual", {
        message: "Prosím zvolte jednu z možností",
        type: "required",
      });

      return;
    }

    const { restingPlace } =
      (await sendRestingPlaceMutation({
        message: formData.message.trim(),
        place,
      })) ?? {};

    dispatch(addRestingPlace(restingPlace.place, restingPlace.message));

    history.push("/prehled");
  };

  const handleStatusClick = (e: any) => {
    e.preventDefault();

    clearErrors();

    setPlace(e.target.name);
  };

  useEffect(() => {
    reset({ message: restingPlaceRedux.message });

    if (restingPlaceRedux.place) {
      setPlace(restingPlaceRedux.place);
    }
  }, []);

  return (
    <div className="form form-wishes">
      <SEO page="restingPlace" />

      <GoBack />

      <h1 className="form-title">Sepište svá poslední přání</h1>

      <p>
        Vaší rodině určitě moc pomůže, když si předem sepíšete představu vašeho
        pohřbu. Můžete si vybrat mezi různými typy pohřbů, hudbou, která bude
        hrát nebo například místem, kde byste chtěl/a být pochován/a.
      </p>

      <form className="form-wishes-place" onSubmit={handleSubmit(onSubmit)}>
        <FormInput title="Jakým stylem si přejete být pochován/a?">
          <div className="buttons_section">
            {buttonsStatus.map((el, index) => (
              <button
                key={index}
                type="button"
                onClick={(e) => handleStatusClick(e)}
                name={el.name}
                className={`single_button_section ${
                  place === el.name ? "pressed" : ""
                } ${index === 0 ? "first_button" : ""}`}
              >
                {el.text}
              </button>
            ))}
          </div>
        </FormInput>

        {place === "science" && (
          <p>
            Tělo lze darovat pouze tak, že uzavřete darovací smlouvu s
            konkrétním anatomickým ústavem (lékařských fakult).
          </p>
        )}



        

        <PrepaidWidget />




        <FormInput
          label="Vaše zpráva"
          title="Máte nějaké další přání týkající se vašeho pohřbu?"
          description={[
            "Dejte své rodině vědět, jaký pohřeb byste si přál/a. Může to být opravdu cokoliv. Jaká hudba by měla hrát, druh květin, různé náboženské rituály, ohňostroje, konkrétní oblečení a tak dále.",
          ]}
        >
          <textarea
            name="message"
            className="textarea"
            ref={register}
            rows={10}
            cols={45}
          />
        </FormInput>

        {errors?.manual && <Alert text={errors.manual.message} />}

        <SaveButton loading={restingPlaceLoading} readyToRoll={true} />
      </form>
    </div>
  );
};

export default RestingPlace;
