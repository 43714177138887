import { MutationOptions, useMutation } from "@apollo/client";
import { DocumentNode } from "graphql";

export const useSendMutation = (
  MUTATION_QUERY: DocumentNode,
  options?: MutationOptions
) => {
  const [sendMutation, { loading, error }] = useMutation(
    MUTATION_QUERY,
    options ?? {}
  );

  async function sendMutationRequest(variables?: any) {
    if (!variables) {
      const { data } = await sendMutation();

      return data;
    }

    const { data } = await sendMutation({ variables });

    return data;
  }

  return {
    sendMutationRequest,
    loading,
    error,
  };
};
