export const UIStateReducer = (
  state: { willIsLoading: boolean } = { willIsLoading: false },
  action: any
) => {
  switch (action.type) {
    case "WILL_LOADING":
      return { ...state, willIsLoading: action.payload };
    default:
      return state;
  }
};
