import React, { FC } from "react";
import SaveButton from "../../Buttons/SaveButton";

interface FormI {
  onSubmit: any;
  canContinue: boolean;
  isLoading: boolean;

  loadingText?: string;
}

export const Form: FC<FormI> = ({
  children,
  onSubmit,
  canContinue,
  isLoading,
  loadingText,
}) => {
  return (
    <form onSubmit={onSubmit}>
      {children}
      <SaveButton
        readyToRoll={canContinue}
        loading={isLoading}
        loadingText={loadingText ?? ""}
      />
    </form>
  );
};
