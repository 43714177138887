import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormInput } from "../../../Components/Form";
import Modal from "../../../Components/Modal/Modal";

interface CharityPropsI {
  toggle: any;
  editingCharity: { charityName: string };
  nonDefaultCharities: string[];
  setNonDefaultCharities: any;
}

const AddCharity = (props: CharityPropsI) => {
  const { register, errors, reset, watch, handleSubmit } = useForm();

  const updatingOlder = !!props.editingCharity.charityName;
  const charityNameWatch = watch("charityName");

  function onSubmit(data: any, e: any) {
    e.preventDefault();
    if (props.nonDefaultCharities.includes(props.editingCharity.charityName)) {
      props.setNonDefaultCharities((prevState: string[]) =>
        prevState.map((charity: string) =>
          charity === props.editingCharity.charityName ? charityNameWatch : charity
        )
      );
      props.toggle();
    } else {
      props.setNonDefaultCharities((prevState: string[]) => [
        ...prevState,
        charityNameWatch,
      ]);
      props.toggle();
    }
  }
  function handleDeletion(e: any) {
    e.preventDefault();
    props.setNonDefaultCharities((prevState: string[]) =>
      prevState.filter((charity: string) => charity !== props.editingCharity.charityName)
    );
    props.toggle();
  }

  useEffect(() => {
    if (updatingOlder) {
      reset(props.editingCharity);
    }
  }, []);
  return (
    <Modal
      header={
        props.editingCharity?.charityName
          ? "Upravit neziskovou organizaci"
          : "Přidat neziskovou organizaci"
      }
      toggle={props.toggle}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          className="modal_form modal_form-charity"
          description={[
            "Jak se jmenuje nezisková organizace, kterou byste rád zvolil/a?",
          ]}
          label="Jméno, číslo organizace"
          errors={errors}
          input={{
            name: "charityName",
            register: register({
              required: { value: true, message: "Toto pole je povinné" },
            }),
            type: "text",
            placeholder: "",
          }}
        />
        <div className="modal_footer">
          {updatingOlder && (
            <button
              type="button"
              onClick={(e) => handleDeletion(e)}
              className="modal_cancel"
            >
              Odstranit
            </button>
          )}
          <button type="submit" className="modal_save">
            Uložit
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddCharity;
