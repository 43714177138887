import { gql } from '@apollo/client';

export const RESTING_PLACE_MUTATION = gql`
  mutation restingPlaceMutation($place: String, $message: String) {
    restingPlace(restingPlace: { message: $message, place: $place }) {
      message
      place
    }
  }
`;
