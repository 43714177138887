import React, { ReactChildren, useState } from "react";
import "./Tooltip.scss";

export interface TooltipPropsI {
  id: string;
  title: string;
  description: string[];
  children?: ReactChildren | string;
  customText?: string;
}

const Tooltip = (props: TooltipPropsI) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="tooltipWrapper">
      {isVisible && (
        <div className="tooltip">
          <div className="tooltipContent">
            {props.description.map((description, idx: number) => (
              <p className="tooltipText" key={idx}>
                {description}
              </p>
            ))}
          </div>
          {/* <div className="tooltipArrow" /> */}
          <div className="background"></div>
        </div>
      )}
      <div
        className="tooltipChildren"
        onMouseOver={() => setIsVisible(true)}
        onMouseLeave={(e) => setIsVisible(false)}
      >
        {props.customText ? props.customText : "Proč to potřebujeme vědět?"}
        {/* <img style={{ width: "20px" }} src={QuestionMarkIcon} /> */}
      </div>
    </div>
  );
};

export default Tooltip;
