export type GiftTypes = "itemCollection" | "property" | "vehicle" | "money";

export const giftTypeDic: any = {
  itemCollection: "predmet",
  property: "nemovitost",
  vehicle: "auto",
  money: "penize",
};

export function getGiftInfo(
  gift: any,
  allPeopleById: any,
  defaultCharitiesById: any
) {
  if (gift.giftType === "money") {
    return [
      gift.leaveItTo
        .map((person: string) => allPeopleById[person]?.fullName || "")
        .filter((v: string) => v)
        .join(", "),
      gift.charities
        .map((charity: string) => {
          if (Object.keys(defaultCharitiesById).includes(charity)) {
            return defaultCharitiesById[charity].name;
          } else {
            return charity;
          }
        })
        .filter((v: string) => v)
        .join(", "),
    ];
  } else {
    return [
      gift.leaveItTo
        .map((person: string) => allPeopleById[person]?.fullName || "")
        .filter((v: string) => v)
        .join(", "),
    ];
  }
}

export function prepareGiftData(
  updateData: any,
  giftType: GiftTypes,
  charities?: any
) {
  const preparedGiftData: any = {
    giftType,
    gift: updateData.gift,
    giftMessage: updateData.giftMessage,
    leaveItTo: updateData.leaveItTo,
  };

  if (giftType === "money") {
    preparedGiftData.charities = charities;

    if (preparedGiftData.leaveItTo === false) {
      preparedGiftData.leaveItTo = undefined;
    }
  } else if (giftType === "property") {
    const { propertyInfo1, propertyInfo2, propertyInfo3, owner, mortgage } =
      updateData;

    preparedGiftData.propertyInfo1 = propertyInfo1;
    preparedGiftData.propertyInfo2 = propertyInfo2;
    preparedGiftData.propertyInfo3 = propertyInfo3;
    preparedGiftData.owner = owner;
    preparedGiftData.mortgage = mortgage;
  }

  return preparedGiftData;
}
