import React from 'react';
import { Link } from 'react-router-dom';

import './RegisterSuccess.scss';

const RegisterSuccess = () => {
  return (
    <div className='registerSuccess'>
      <h2>Registrace proběhla úspěšně</h2>
      <p>Děkujeme vám za registraci. Teď se přihlaste a můžete začít tvořit.</p>
      <Link to="/prihlaseni">Pokračovat</Link>
    </div>
  );
};

export default RegisterSuccess;
