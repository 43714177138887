import { useDispatch } from "react-redux";

export const useSaveResultToRedux = () => {
  const dispatch = useDispatch();

  function saveToRedux(action: any, result?: any) {
    if (typeof result !== "undefined") {
      dispatch(action(result));
      return;
    }

    dispatch(action());
  }

  return saveToRedux;
};
