import { useLazyQuery, useMutation } from "@apollo/client";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Alert } from "../../../Components/Form";
import { SEO } from "../../../Components/seo/Seo";
import { UPDATE_CHILD_MUTATION } from "../../../GraphQL/Mutations";
import { GET_WILL, LOGIN_QUERY } from "../../../GraphQL/Queries";
import { compareCookies } from "../../../Helpers";
import { preventEnglishErrorMessage } from "../../../Helpers/authentication";
import { sendAllChildrenMutations } from "../../../Helpers/children";
import { redirectTo } from "../../../Helpers/routing";
import { aboutYou_children, user_login, user_logout } from "../../../Redux/actions";
import { updateWholeRedux } from "../../../Redux/UpdateStore";
import "./LoginPage.scss";
import googleRating from "../../../Images/googleRating.svg";
import firmyRating from "../../../Images/firmyRating.svg";
import gdpr from "../../../Images/gdpr.svg";
import greenCheck from "../../../Images/greenCheck.svg";
import computerPic from "../../../Images/computerPic.svg";

const LoginPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();

  const [getWill, { data: dataWill, error: errorWill, loading: loadingWill }] =
    useLazyQuery(GET_WILL, { fetchPolicy: "network-only" });
  const [loginUserQuery, { data: dataLogin, error: errorLogin, loading: loadingLogin }] =
    useLazyQuery(LOGIN_QUERY, { fetchPolicy: "no-cache" });

  const loading = loadingWill || loadingLogin;
  const [editChildMutation] = useMutation(UPDATE_CHILD_MUTATION);

  const onSubmit = (formData: any, e: any) => {
    e.preventDefault();
    const { email, password } = formData;
    loginUserQuery({
      variables: { email, password },
    });
  };

  useEffect(() => {
    if (dataLogin) {
      const { will, counter } = dataLogin.login;
      if (Cookies.get("logged")) {
        compareCookies()
          .then((res) => {
            updateWholeRedux(will);
            if (will.children.length > 0) {
              sendAllChildrenMutations(will.children, editChildMutation)
                .then((res) => {
                  aboutYou_children(res[res.length - 1].data.updateChild.children);
                })
                .catch((err) => console.log(err));
            }
            redirectTo(counter === 1 ? "/prehled" : "/prehled", history);
          })
          .catch((err) => {
            dispatch(user_logout());
            redirectTo("/prihlaseni", history);
          });
      }
    }
  }, [dataLogin]);

  useEffect(() => {
    if (dataWill) {
      const { getWill } = dataWill;
      sendAllChildrenMutations(getWill.children, editChildMutation)
        .then((res) => {
          dispatch(aboutYou_children(res[res.length - 1].data.updateChild.children));
        })
        .catch((err) => console.log(err));
      updateWholeRedux(getWill);
      redirectTo("/prehled", history);
    }
  }, [dataWill]);

  useEffect(() => {
    if (Cookies.get("logged")) {
      compareCookies()
        .then((res) => {
          if (res) {
            getWill();
            dispatch(user_login());
          } else {
            dispatch(user_logout());
          }
        })
        .catch((err) => {
          dispatch(user_logout());
        });
    }
  }, []);

  return (
    <div className="loginPage">
      <SEO page="loginPage" />


      <div className="loginWrap">
        <div className="leftSide">
          <img src={computerPic} alt="family" className="familyPic loginPic" />
          <h1>Vítejte zpět</h1>
          <p className="desc">Přihlašte se zpět do naší aplikace a pokračujte v úpravě vaší závěti. Pokud ještě u nás nemáte založený účet, <a href="/registrace">zaregistrujte se zdarma</a>.</p>
          <div className="trust">
            <div className="left">
            </div>
            <div className="right">
              <p>Stovky spokojených zákazníků (4,95/5 průměrné hodnocení)</p>
              <div>
                <img src={googleRating} alt="googleRating" />
                <img src={firmyRating} alt="firmyRating" />
              </div>
            </div>
            
          </div>
        </div>

        <div className="rightSide">
          <form className="login_form" onSubmit={handleSubmit(onSubmit)}>
            {errorWill?.message && <Alert text={errorWill.message} />}
            {errorLogin?.message && (
              <Alert text={preventEnglishErrorMessage(errorLogin.message)} />
            )}

            <div className="credentialsInput">
              <label>Váš e-mail</label>
              <input
                ref={register({
                  required: {
                    value: true,
                    message: "Vyplňte prosím e-mailovou adresu",
                  },
                })}
                name="email"
                type="email"
                autoComplete="username"
                placeholder="Např.: honza@seznam.cz"
              />
            </div>

            <span className="formError">{errors.email?.message}</span>

            <div className="credentialsInput">
              <label>Vaše heslo</label>
              <input
                ref={register({
                  required: {
                    value: true,
                    message: "Vyplňte prosím toto pole",
                  },
                })}
                name="password"
                type="password"
                autoComplete="current-password"
              />
            </div>

            <span className="formError">{errors.password?.message}</span>
            
            <div className="buttons">
              <button type="submit">{loading ? "Načítání..." : "Přihlásit se"}</button>
              <img src={gdpr} alt="gdpr" />
            </div>

            <p className="loginLink">
              Ještě nemáte založený účet? <Link to="/registrace">Zaregistrujte se</Link>
            </p>
            <p className="loginLink">
              Zapomněli jste své heslo? <Link to="/zapomenute-heslo">Obnovte heslo</Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
