export const initialPerson = {
  fullName: "",
  birth: "",
  address: {
    city: "",
    street: "",
  }
};

export const aboutYou_names = (info: any) => ({
  type: "ABOUT_YOU_NAMES",
  payload: info,
});

export const aboutYou_address = (address: any) => ({
  type: "ABOUT_YOU_ADDRESS",
  payload: address,
});

export const aboutYou_newPartner = (partner: any) => ({
  type: "ABOUT_YOU_NEW_PARTNER",
  payload: partner,
});

export const aboutYou_editPartner = (partner: any) => ({
  type: "ABOUT_YOU_EDIT_PARTNER",
  payload: partner,
});
export const aboutYou_deletePartner = () => ({
  type: "ABOUT_YOU_DELETE_PARTNER",
});

export const aboutYou_maritalStatus = (status: string) => ({
  type: "ABOUT_YOU_STATUS",
  payload: status,
});

export const aboutYou_children = (children: any) => ({
  type: "ABOUT_YOU_CHILDREN",
  payload: children,
});

export const aboutYou_pets = (pets: any) => ({
  type: "ABOUT_YOU_PETS",
  payload: pets,
});

export const aboutYou_pets_update = (pet: any) => ({
  type: "ABOUT_YOU_PETS_UPDATE",
  payload: pet,
});

export const aboutYou_hasChildren = (hasChildren: boolean) => ({
  type: "HAS_CHILDREN",
  payload: hasChildren,
});

export const aboutYou_hasPets = (hasPets: boolean) => ({
  type: "HAS_PETS",
  payload: hasPets,
});
