export const inheritEstateReducer = (
  state: any = {
    inheritors: [],
    charities: [],
    allowOtherInheritorsToDecide: null,
  },
  action: any
) => {
  switch (action.type) {
    case "ADD_INHERITORS":
      const existingInheritorsById = state.inheritors.reduce(
        (acc: any, inheritor: any) => (
          (acc[inheritor.person] = inheritor), acc
        ),
        {}
      );
      const existingInheritorsIds = Object.keys(existingInheritorsById);

      return {
        ...state,
        inheritors: action.payload.map((id: string) =>
          existingInheritorsIds.includes(id)
            ? existingInheritorsById[id]
            : {
                person: id,
                percentageFromAssets: 0,
                charity: "",
              }
        ),
      };
    case "ADD_CHARITIES":
      const existingCharitiesById = state.charities.reduce(
        (acc: any, charity: any) => ((acc[charity.charity] = charity), acc),
        {}
      );
      const existingCharitiesIds = Object.keys(existingCharitiesById);

      return {
        ...state,
        charities: action.payload.map((charity: string) =>
          existingCharitiesIds.includes(charity)
            ? existingCharitiesById[charity]
            : {
                charity: charity,
                percentageFromAssets: 0,
              }
        ),
      };
    case "ADD_INHERITORS_PERCENTAGES":
      return {
        ...state,
        inheritors: state.inheritors.map((estate: any) => ({
          ...estate,
          percentageFromAssets: Number(action.payload[estate.person]),
        })),
      };
    case "ADD_CHARITIES_PERCENTAGES":
      return {
        ...state,
        charities: state.charities.map((estate: any) => ({
          ...estate,
          percentageFromAssets: Number(action.payload[estate.charity]),
        })),
      };
    case "GET_INHERIT_ESTATE":
      return {
        inheritors: action.payload.inheritors,
        charities: action.payload.charities,
        allowOtherInheritorsToDecide:
          action.payload.allowOtherInheritorsToDecide ||
          state.allowOtherInheritorsToDecide,
      };
    case "ADD_BACKUP_INHERITOR":
      const inheritorsSerialized = action.payload.backupInheritors.map(
        (inheritor: string) => ({
          person: inheritor,
          percentages: 0,
        })
      );
      const charitiesSerialized = action.payload.charities
        ? action.payload.charities.map((charity: string) => ({
            percentages: 0,
            charity: charity,
          }))
        : [];
      return {
        ...state,
        inheritors: state.inheritors.map((estate: any) =>
          estate.person === action.payload.inheritorID
            ? {
                ...estate,
                backupInheritors: [
                  ...inheritorsSerialized,
                  ...charitiesSerialized,
                ],
              }
            : estate
        ),
      };
    case "ALLOW_OTHERS_TO_DECIDE":
      return {
        ...state,
        allowOtherInheritorsToDecide: action.payload,
      };
    default:
      return state;
  }
};
