import { gql } from "@apollo/client";

export const ASK_FOR_RECHECKING_MUTATION = gql`
  mutation {
    askForRechecking
  }
`;

export const PAY_WITH_TRANSFER_MUTATION = gql`
  mutation {
    payWithTransfer
  }
`;
