import { combineReducers } from "redux";

import { aboutYouReducer } from "./aboutYou";
import { authenticationReducer } from "./authentication";
import { assetsReducer } from "./assets";
import { inheritEstateReducer } from "./inheritEstate";
import { randomPeopleReducer } from "./randomPeople";
import { progressBarReducer } from "./progressBar";
import { restingPlaceReducer } from "./restingPlace";
import { specialWishesReducer } from "./specialWishes";
import { stripeReducer } from "./stripe";
import { UIStateReducer } from "./UIState";
import { giftsReducer } from "./gifts";
import { paymentReducer } from "./payment";
import { snackbarReducer } from "./snackbar";
// import from "./"

const appReducer = combineReducers({
  aboutYouReducer,
  authenticationReducer,
  assetsReducer,
  inheritEstateReducer,
  randomPeopleReducer,
  progressBarReducer,
  restingPlaceReducer,
  specialWishesReducer,
  stripeReducer,
  UIStateReducer,
  giftsReducer,
  paymentReducer,
  snackbarReducer,
});

export const combinedReducers = (state: any, action: any) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};
