import { gql } from '@apollo/client';

export const ADD_PERSON_MUTATION = gql`
  mutation addPersonMutation($fullName: String!, $birth: String!, $type: String!, $street: String, $city: String) {
    newPerson(newPerson: {
      fullName: $fullName,
      birth: $birth,
      type: $type,
      address: { city: $city, street: $street }
    }) {
      _id
      fullName
      birth
      type
      address {
        city
        street
      }
    }
  }
`;
export const UPDATE_PERSON_MUTATION = gql`
  mutation updatePersonMutation(
    $id: ID!
    $fullName: String!
    $birth: String!
    $type: String!
    $street: String
    $city: String
  ) {
    updateRandomPerson(
      person: {
        fullName: $fullName,
        birth: $birth,
        type: $type,
        address: { city: $city, street: $street }
      }
      _id: $id
    ) {
      people {
        _id
        fullName
        birth
        type
        address {
          city
          street
        }
      }
    }
  }
`;
export const DELETE_PERSON_MUTATION = gql`
  mutation deletePersonMutation($id: ID!) {
    deleteRandomPerson(_id: $id) {
      people {
        _id
        fullName
        birth
        type
      }
      children {
        _id
        fullName
        birth
        over18
        guardian
      }
      pets {
        _id
        name
        guardian
      }
      gifts {
        _id
        giftType
        gift
        leaveItTo
        giftMessage
        charities
      }
      inheritEstate {
        percentageFromAssets
        charity
        person
        theirKids
        otherInheritors
        backupInheritors {
          person
          percentages
          charity
        }
      }
    }
  }
`;
