import { useLazyQuery, useMutation } from "@apollo/client";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Alert } from "../../../Components/Form";
import { SEND_NEW_PASSWORD_MUTATION } from "../../../GraphQL/Mutations";
import { LOGIN_QUERY } from "../../../GraphQL/Queries";
import { compareCookies } from "../../../Helpers";
import { useToastMessage } from "../../../hooks/useToastMessage";
import { user_logout } from "../../../Redux/actions";
import { updateWholeRedux } from "../../../Redux/UpdateStore";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { postToastMessage } = useToastMessage();

  const [loginUserQuery, { data: loginUserData }] = useLazyQuery(LOGIN_QUERY);
  const [resetPwdMutation, { loading: resetPwdLoading, error: resetPwdError }] =
    useMutation(SEND_NEW_PASSWORD_MUTATION);
  const { register, handleSubmit, errors, setError, clearErrors } = useForm();

  function onSubmit(data: any, e: any) {
    clearErrors();
    e.preventDefault();
    if (data.password !== data.passwordRepeat) {
      setError("passwordRepeat", {
        type: "validate",
        message: "Zadaná hesla se neshodují",
      });
      return;
    }
    sendResetPwdMutation(data.password);
  }

  async function sendResetPwdMutation(password: string) {
    try {
      const slug = history.location.pathname.split("/")[2];

      const { data } = await resetPwdMutation({
        variables: { password, slug },
      });

      if (data.sendNewPassword) {
        loginUserQuery({
          variables: { email: data.sendNewPassword, password },
        });

        postToastMessage("Vaše heslo bylo úspěšně změněno");
      }
    } catch (err) {
      // console.log(err)
    }
  }

  useEffect(() => {
    if (resetPwdError && resetPwdError.message === "s1gerr") {
      history.push("/prihlaseni");
    }
  }, [resetPwdError]);

  useEffect(() => {
    if (loginUserData) {
      const { will, counter } = loginUserData.login;
      if (Cookies.get("logged")) {
        compareCookies()
          .then((res) => {
            updateWholeRedux(will);
            history.push(counter === 2 ? "/zacatek" : "/prehled");
          })
          .catch((err) => {
            dispatch(user_logout());
            history.push("/prihlaseni");
          });
      }
    }
  }, [loginUserData]);

  return (
    <div className="loginPage passReset">
      <h1>Obnova vašeho hesla</h1>

      <form className="login_form" onSubmit={handleSubmit(onSubmit)}>
        <p>Zde prosím zadejte své nové heslo.</p>
        <div className="credentialsInput">
          <label>Zadejte nové heslo</label>
          <input
            ref={register({
              required: {
                value: true,
                message: "Vyplňte prosím toto pole",
              },
            })}
            name="password"
            type="password"
          />
          <br />
          <label>Zopakujte heslo</label>
          <input
            ref={register({
              required: {
                value: true,
                message: "Vyplňte prosím toto pole",
              },
            })}
            name="passwordRepeat"
            type="password"
          />
        </div>
        <span className="formError">{errors.passwordRepeat?.message}</span>
        {resetPwdError?.message && <Alert text={resetPwdError.message} />}
        <button type="submit">{resetPwdLoading ? "Načítání..." : "Změnit heslo"}</button>
      </form>
    </div>
  );
};

export default ResetPassword;
