import _ from "lodash";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import GoBack from "../../../Components/Buttons/GoBack/GoBack";
import SaveButton from "../../../Components/Buttons/SaveButton";
import { FormInput } from "../../../Components/Form";
import DeleteModal from "../../../Components/Modal/DeleteModal";
import { SEO } from "../../../Components/seo/Seo";
import { DELETE_GIFT } from "../../../GraphQL/Mutations";
import { allPeopleByIdFn } from "../../../Helpers";
import {
  useModal,
  useSaveResultToRedux,
  useSendMutation,
} from "../../../hooks";
import { getGifts } from "../../../Redux/actions";
import CharitiesList from "../Estate/CharitiesList";
import GiftContainer from "./GiftContainer";
import "./Gifts.scss";
import { giftOptions } from "./giftsData";
import { getGiftInfo, giftTypeDic } from "./giftsHelpers";

const Gifts = () => {
  const history = useHistory();

  const saveToRedux = useSaveResultToRedux();

  const { sendMutationRequest: sendDeleteGiftMutation, loading } =
    useSendMutation(DELETE_GIFT);

  const [isDeleteModalOpen, toggleDeleteModal] = useModal();

  const [giftSelected, setGiftSelected] = useState<any>({});

  const {
    aboutYouReducer: {
      children: childrenRedux,
      marital_status: { partner: partnerRedux },
    },
    giftsReducer: giftsRedux,
    randomPeopleReducer: randomPeopleRedux,
  } = useSelector((state: any) => state);

  const defaultCharitiesById = _.keyBy(CharitiesList, "_id");

  const allPeopleById = allPeopleByIdFn(
    childrenRedux,
    partnerRedux,
    randomPeopleRedux
  );

  function handleSubmitFn(e: any) {
    e.preventDefault();

    history.push("/prehled");
  }

  function handleDeleteConfirmation(id: string, title: string) {
    setGiftSelected({ id: id, gift: title });

    toggleDeleteModal();
  }

  async function handleDeletion() {
    const { deleteGift } =
      (await sendDeleteGiftMutation({ id: giftSelected.id })) ?? {};

    saveToRedux(getGifts, deleteGift.gifts);

    toggleDeleteModal();
  }

  return (
    <div className="form form-gifts">
      <GoBack />

      <SEO page="gifts" />

      <form onSubmit={(e) => handleSubmitFn(e)}>
        <FormInput
          title="Chtěl/a byste někomu přenechat nějaký dárek - něco odkázat?"
          description={[
            "Pokud si přejete osobě zvlášť přenechat určitou věc (např. peníze, šperk, nebo cokoliv jiného pro vás důležitého) můžete k tomu využít institut odkazu.",
            "Odkazem nařídíte vašim dědicům, aby po skončení pozůstalostního řízení danou věc z pozůstalosti vydali právě vámi určené konkrétní osobě. Tato osoba se nestane vaším dědicem (nebude mít např. povinnost hradit dluhy) pouze jí odkazem vznikne nárok na vydání věci. Pamatuje ale, že každému dědici musí z hodnoty dědictví zůstat alespoň jedna čtvrtina odkazy nezatížená. To znamená, že odkázané předměty nesmí svou hodnotou v součtu přesáhnout 75% z celkové hodnoty vaší pozůstalosti.",
            "Ke každému odkazu také můžete napsat vzkaz určený přímo osobě, která má odkaz obdržet."
          ]}
        >
          {giftsRedux.length > 0 && (
            <>
              <h2 className="gifts-heading">Vaše odkazy</h2>

              {giftsRedux.map((gift: any, index: number) => (
                <GiftContainer
                  key={index}
                  title={gift.gift}
                  info={getGiftInfo(gift, allPeopleById, defaultCharitiesById)}
                  remove={handleDeleteConfirmation}
                  edit={`/odkazy/${giftTypeDic[gift.giftType]}/${gift._id}`}
                  id={gift._id}
                />
              ))}
            </>
          )}

          <h2 className="gifts-heading">Přidejte odkaz</h2>

          {giftOptions.map((giftOption) => (
            <GiftContainer
              key={giftOption.redirectTo}
              icon={giftOption.icon}
              title={giftOption.title}
              info={giftOption.info}
              add={giftOption.redirectTo}
            />
          ))}
        </FormInput>

        <SaveButton loading={false} readyToRoll={true} buttonText="Hotovo" />
      </form>

      {isDeleteModalOpen && (
        <DeleteModal
          headerText="Odstranit dárek"
          loading={loading}
          personSelected={giftSelected}
          sendDeletion={handleDeletion}
          toggleDeleteModal={toggleDeleteModal}
        />
      )}
    </div>
  );
};

export default Gifts;
