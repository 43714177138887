import { useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InvitePartner } from "../../Components/banners/InvitePartner";
import NotPaidForWillModal from "../../Components/Modal/NotPaidForWillModal";
import PartnerModal from "../../Components/Modal/PartnerModal";
import { ProgressBar } from "../../Components/progressBar/ProgressBar";
import { SEO } from "../../Components/seo/Seo";
import { Steps } from "../../Components/steps/Steps";
import { GET_PAID_FOR_WILL } from "../../GraphQL/Queries";
import { useLogout, useModal, useStepsCompleted } from "../../hooks";
import { setPaidForWill } from "../../Redux/actions";
import "./Summary.scss";
import SummaryInfoBox from "./SummaryInfoBox";
import { PrepaidBubble } from "../../Components/banners/PrepaidBubble";
import PrepaidModal from "../../Components/Modal/PrepaidModal";

const Summary = () => {
  const dispatch = useDispatch();

  const { data: getPaidForWillData } = useQuery(GET_PAID_FOR_WILL, {
    fetchPolicy: "cache-and-network",
  });

  const {
    logout,
    logoutLoading,
    isNotPaidForWillModalOpen,
    toggleNotPaidForWillModal,
  } = useLogout();

  const [isPartnerModalOpen, togglePartnerModal] = useModal();
  const [isPrepaidModalOpen, togglePrepaidModal] = useModal();
  const stepsCompleted = useStepsCompleted();

  const {
    aboutYouReducer: { children, pets },
  } = useSelector((state: any) => state);

  const childrenUnder18 = children.filter((el: any) => !el.over18);

  useEffect(() => {
    if (getPaidForWillData) {
      dispatch(setPaidForWill(getPaidForWillData.paidForWill));
    }
  }, [getPaidForWillData]);

  return (
    <div className="summary">
      <SEO page="summary" />

      <div className="progress">
        <ProgressBar />

        <SummaryInfoBox
          handleLogout={logout}
          logoutLoading={logoutLoading}
          stepsCompleted={stepsCompleted}
          pets={pets}
          childrenU18={childrenUnder18}
        />

        <InvitePartner togglePartnerModal={togglePartnerModal} />
        <PrepaidBubble togglePrepaidModal={togglePrepaidModal} />



      </div>

      <Steps />

      {isPartnerModalOpen && <PartnerModal toggle={togglePartnerModal} />}
      {isPrepaidModalOpen && <PrepaidModal toggle={togglePrepaidModal} />}

      {isNotPaidForWillModalOpen && (
        <NotPaidForWillModal
          toggleNotPaidForWillModal={toggleNotPaidForWillModal}
          logout={logout}
        />
      )}
    </div>
  );
};

export default Summary;
