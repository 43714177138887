import moment from "moment";

export function isValidBirth(v: string) {
  if (moment(v, "YYYY-MM-DD").isAfter(moment())) {
    return false;
  } else {
    return true;
  }
}

interface DateObject {
  year: string;
  month: string;
  day: string;
}

/**
 *
 * @param year
 * @param month
 * @param day
 * @returns String formatted as `YYYY-MM-DD`
 */
export function formatBirthObjectToString(
  year: string,
  month: string,
  day: string
) {
  return `${year}-${month}-${day}`;
}

export function formatBirthStringToObject(birth: string): DateObject {
  const [year, month, day] = birth.split("-");

  return {
    year,
    month,
    day,
  };
}

interface PersonWithBirthI {
  birth: string;
}

export function isPersonOlderThan18Years(person: PersonWithBirthI) {
  return moment().diff(moment(person.birth, "YYYY-MM-DD"), "months") >= 216;
}

export function isOlderThan(
  person1: PersonWithBirthI,
  person2: PersonWithBirthI
) {
  return moment(person1.birth, "YYYY-MM-DD").isBefore(
    moment(person2.birth, "YYYY-MM-DD")
  );
}
