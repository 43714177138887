import React from "react";
import close from "../../../Images/alertX.svg";
import info from "../../../Images/info.svg";
import "./Alert.scss";

interface AlertProps {
  text: string;
  type?: string;
}

export const Alert = ({ text, type = "danger" }: AlertProps) => {
  if (!text) {
    return null;
  }

  return (
    <div className={type === "info" ? "info" : "alert"}>
      <img src={type === "info" ? info : close} alt="info ikona" />
      <p className="alertText">{typeof text === "string" ? text : text[0]}</p>
    </div>
  );
};
