import { gql } from "@apollo/client";

export const GET_WILL = gql`
  {
    getWill {
      names {
        fullName
        nickname
        birth
        personalNumber
        gender
      }
      address {
        city
        street
        zipCode
        country
        phoneNumber
      }
      maritalStatus {
        partner {
          _id
          fullName
          email
          over18
          birth
          address {
            city
            street
          }
        }
        status
      }
      children {
        _id
        fullName
        birth
        over18
        guardian
        address {
          city
          street
        }
      }
      pets {
        _id
        name
        guardian
      }
      assets {
        _id
        type
        bankName
        insuranceProvider
        pensionProvider
        stocksCompany
        ownedBusiness
        address
        mortgage
        owner
        other
      }
      gifts {
        _id
        gift
        giftType
        giftMessage
        leaveItTo
        charities
        mortgage
        owner
        propertyInfo1
        propertyInfo2
        propertyInfo3
      }
      restingPlace {
        place
        message
      }
      specialWishes
      inheritEstate {
        percentageFromAssets
        person
        charity
        theirKids
        otherInheritors
        backupInheritors {
          person
          percentages
          charity
        }
      }
      people {
        _id
        fullName
        birth
        type
        address {
          city
          street
        }
      }
      hasChildren
      hasPets
      allowInheritorsToDecide
      price
    }
  }
`;
