import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";

import { Alert } from "../../../Components/Form";
import InviteFriends from "../../../Components/PaymentComponents/InviteFriends.tsx/InviteFriends";
import OrderStatus from "../../../Components/PaymentComponents/OrderStatus/OrderStatus";
import { ASK_FOR_RECHECKING_MUTATION } from "../../../GraphQL/Mutations";
import { GET_WILL_DOWNLOAD_URL } from "../../../GraphQL/Queries";

interface FinishedPropsI {
  friendsCode: string;
  createFriendsCode: any;
  createFriendsCodeLoading: boolean;
}

const Finished = (props: FinishedPropsI) => {
  const [askForRecheckingMutation, { called: askForRecheckingCalled }] =
    useMutation(ASK_FOR_RECHECKING_MUTATION);

  const { loading: willDownloadLinkLoading, data: willDownloadLinkData } =
    useQuery(GET_WILL_DOWNLOAD_URL);

  const [askedForRefetchingInfo, setAskedForRefetchingInfo] = useState(false);
  const [willLink, setWillLink] = useState("");

  async function sendAskForRechecking() {
    try {
      const { data } = await askForRecheckingMutation();
      if (data.askForRechecking === "processing") {
        setAskedForRefetchingInfo(true);
        setTimeout(() => {
          setAskedForRefetchingInfo(false);
        }, 10000);
      }
    } catch (err) {
      console.log(err);
    }
  }

  //TODO: add query to download specific link

  // tady jsem to trochu předělal aby to bylo zatím všechno v jednom komponentu.
  // Zatím pojďme řešit tu query download logiku v processing.tsx
  // Pak to budu chtít stejně asi předělat pro lepší UX takže tenhle file se nam jeste bude hodit.

  useEffect(() => {
    if (willDownloadLinkData) {
      setWillLink(willDownloadLinkData.willDownloadURL);
    }
  }, [willDownloadLinkData]);

  return (
    <div className="payment-processing">
      <div>
        {willDownloadLinkLoading ? (
          <p>Načítání...</p>
        ) : (
          <OrderStatus
            status={"finished"}
            loading={false}
            willLink={willLink}
          />
        )}
      </div>

      {!askForRecheckingCalled && (
        <div className="askForRecheck">
          <button
            className="edit-done"
            onClick={sendAskForRechecking}
            type="button"
          >
            Zažádat o překontrolování
          </button>
        </div>
      )}

      {askedForRefetchingInfo && (
        <Alert text="Vaše závěť byla úspěšně zaslána ke kontrole" type="info" />
      )}

      <div className="payment-right">
        <InviteFriends
          friendsCode={props.friendsCode}
          createFriendsCode={props.createFriendsCode}
          createFriendsCodeLoading={props.createFriendsCodeLoading}
        />
      </div>
    </div>
  );
};

export default Finished;
