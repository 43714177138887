export const modalTexts: any = {
  partner: ["Přidat partnera", "Upravit partnera", "Odstranit partnera"],
  child: ["Přidat dítě", "Upravit dítě", "Odstranit dítě"],
  pet: ["Přidat zvíře", "Upravit zvíře", "Odstranit zvíře"],
  guardian: ["Přidat opatrovníka", "Upravit opatrovníka"],
  inheritor: ["Přidat dědice", "Upravit dědice"],
  default: ["Přidat osobu", "Upravit osobu"],
};

export const typesWithoutBirth = ["pet"];
