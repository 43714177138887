import moment from "moment";

export function sendAllChildrenMutations(children: any[], mutation: any) {
  return Promise.all(
    children.map((child: any) =>
      mutation({
        variables: {
          ...child,
          street: child.address.street,
          id: child._id,
          over18: moment().diff(child.birth, "years") >= 18,
        },
      })
    )
  );
}
