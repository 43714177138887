import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import GoBack from "../../../../Components/Buttons/GoBack/GoBack";
import SaveButton from "../../../../Components/Buttons/SaveButton";
import { AddButton, Alert, FormInput } from "../../../../Components/Form";
import AddPerson from "../../../../Components/PersonBox/AddPerson";
import PersonBox from "../../../../Components/PersonBox/PersonBox";
import { EDIT_GIFT, POST_GIFT } from "../../../../GraphQL/Mutations";
import { allPeopleArrayFn } from "../../../../Helpers";
import {
  useModal,
  useSaveResultToRedux,
  useSendMutation,
} from "../../../../hooks";
import { getGifts, initialPerson } from "../../../../Redux/actions";
import { prepareGiftData } from "../giftsHelpers";

interface VehiclePropsI {
  editingGift: any;
}

const Vehicle = (props: VehiclePropsI) => {
  const history = useHistory();

  const saveToRedux = useSaveResultToRedux();

  const { register, errors, handleSubmit, reset, getValues } = useForm();

  const [error, setError] = useState("");

  const [isAddPersonModalOpen, toggleAddPersonModal] = useModal();

  const [personSelected, setPersonSelected] = useState<any>();

  const {
    sendMutationRequest: sendPostGiftMutation,
    loading: postGiftLoading,
  } = useSendMutation(POST_GIFT);
  const {
    sendMutationRequest: sendEditGiftMutation,
    loading: editGiftLoading,
  } = useSendMutation(EDIT_GIFT);

  const {
    aboutYouReducer: {
      children,
      marital_status: { partner },
    },
    randomPeopleReducer: randomPeople,
  } = useSelector((state: any) => state);

  const editingGift = !!props?.editingGift?._id;

  const allPeopleArr = allPeopleArrayFn(children, partner, randomPeople);

  async function onSubmit(data: any, e: any) {
    setError("");

    e.preventDefault();

    if (!data.leaveItTo.length) {
      setError("Zvolte alespoň jednu osobu pro zanechání odkazu");

      return;
    }

    if (editingGift) {
      const { updateGift } =
        (await sendEditGiftMutation({
          gift: prepareGiftData(data, "vehicle"),
          id: props.editingGift._id,
        })) ?? {};

      saveToRedux(getGifts, updateGift.gifts);
    } else {
      const { newGift } =
        (await sendPostGiftMutation({
          gift: prepareGiftData(data, "vehicle"),
        })) ?? {};

      saveToRedux(getGifts, newGift);
    }

    history.push("/odkazy");
  }

  function addNewPerson() {
    setPersonSelected({ ...initialPerson, type: "default" });

    toggleAddPersonModal();
  }

  function checkAddedPerson(id: string) {
    reset({ ...getValues(), leaveItTo: [...getValues("leaveItTo"), id] });
  }

  useEffect(() => {
    if (props?.editingGift?._id) {
      reset(props.editingGift);
    }
  }, []);

  return (
    <div className="form form-gift_type">
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          title="Odkázat vozidlo"
          description={[
            "Snažte se nepsat jen “Moje auto”. Je lepší, když danou věc popíšete do co největšího detailu: například “Moje Škoda Octavia s SPZ 5AU 7221”, VIN ….",
            "Můžete ale také napsat “Moje auto v době mého úmrtí”. Tím zajistíte, že odkážete opravdu to poslední auto, které vlastníte.",
          ]}
          errors={errors}
          input={{
            name: "gift",
            type: "text",
            register: register({
              required: { value: true, message: "Toto pole je povinné" },
            }),
            placeholder: "Moje Škoda Octavia SPZ 11A 1111, VIN: ...",
          }}
          label="Popis vozidla"
        />

        <h3>Komu chcete toto vozidlo odkázat?</h3>

        {allPeopleArr.map((person: any, idx: number) => (
          <PersonBox
            key={idx}
            id={person.id}
            name={`leaveItTo`}
            person={person}
            personName={person.fullName}
            description={moment(person.birth, "YYYY-MM-DD").format(
              "DD.MM.YYYY"
            )}
            checkbox={true}
            value={person._id}
            register={register}
          />
        ))}

        <AddButton className="partner_add" onClick={addNewPerson}>
          Přidat osobu
        </AddButton>

        {error && <Alert text={error} />}

        <FormInput
          title="Zanechte osobní zprávu (volitelné)"
          description={[
            "Napište co a proč to zanecháváte. Můžete jim také poradit, jak s danou věcí naložit. Co byste chtěl/a aby s ní udělal/la/li?",
          ]}
          errors={errors}
          label="Osobní zpráva"
        >
          <textarea
            ref={register}
            name="giftMessage"
            cols={58}
            placeholder="Sem vložte vaší zprávu"
          />
        </FormInput>

        <SaveButton
          loading={postGiftLoading || editGiftLoading}
          readyToRoll={true}
        />
      </form>

      {isAddPersonModalOpen && (
        <AddPerson
          toggle={toggleAddPersonModal}
          personType={personSelected.type}
          personSelected={personSelected}
          append={() => {}}
          callback={checkAddedPerson}
        />
      )}
    </div>
  );
};

export default Vehicle;
