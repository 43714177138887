export const initialEditingAsset = {
  _id: '',
  type: '',
  name: '',
  address: '',
  mortgage: '',
  owner: '',
  bankName: '',
  insuranceProvider: '',
  pensionProvider: '',
  other: '',
  stocksCompany: '',
  ownedBusiness: '',
  propertyInfo1: '',
  propertyInfo2: '',
  propertyInfo3: ''
};
export interface AssetI {
  _id: string;
  type: string;
  name?: string;
  address?: string;
  mortgage?: string;
  owner?: string;
  bankName?: string;
  insuranceProvider?: string;
  pensionProvider?: string;
  other?: string;
  stocksCompany?: string;
  ownedBusiness?: string;
  propertyInfo1?: string;
  propertyInfo2?: string;
  propertyInfo3?: string;
}

export const addAsset = (asset: any) => {
  return {
    type: 'ADD_ASSET',
    payload: asset
  };
};
export const removeAsset = (assetID: string) => {
  return {
    type: 'REMOVE_ASSET',
    payload: assetID
  };
};
export const getAssets = (assets: any) => {
  return {
    type: 'GET_ASSETS',
    payload: assets
  };
};
export const editAsset = (asset: any) => {
  return {
    type: 'EDIT_ASSET',
    payload: asset
  };
};
