export const user_login = (value?: boolean) => {
  return {
    type: 'USER_LOGIN',
    payload: value,
  };
};

export const user_logout = (value?: boolean) => {
  return {
    type: 'USER_LOGOUT',
    payload: value,
  };
};
