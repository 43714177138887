import React, { KeyboardEvent, useState } from "react";

import "./FormInput.scss";
import { Alert } from "../../../Components/Form";

interface InputObject {
  type: string;
  name: string;
  register: any;
  placeholder?: string;
  resetBirth?: string;
}

interface FormInputI {
  title?: string;
  description?: string[];
  label?: string;
  input?: InputObject;
  children?: any;
  errors?: any;
  className?: string;
}

export const FormInput = (props: FormInputI) => {
  const [month, setMonth] = useState<number | null>(
    Number(props?.input?.resetBirth?.[1]) || null
  );
  const [year, setYear] = useState<number | null>(
    Number(props?.input?.resetBirth?.[0]) || null
  );

  const { title, description, label, input, children, errors, className } =
    props;

  function preventCommaAndPeriod(e: KeyboardEvent) {
    const { key } = e;
    if (key === "." || key === ",") {
      e.preventDefault();
      return;
    }
  }
  function handleMonthChange(e: any) {
    setMonth(e.target.value);
  }
  function handleYearChange(e: any) {
    setYear(e.target.value);
  }

  return (
    <div className={`input_section ${className ? className : ""}`}>
      {title && <h1 className="input_section-title">{title}</h1>}
      {description &&
        description.map((el, index) => {
          return (
            <p key={index} className="input_section-description">
              {el}
            </p>
          );
        })}
      {label && <label className="input_section-label">{label}</label>}
      <div className="input_section-children">
        {input && input.type === "date" ? (
          <>
            <div className="input_section-date">
              <input
                type="number"
                name="day"
                min={1}
                max={year && month ? new Date(year, month, 0).getDate() : 31}
                step={1}
                onKeyDown={(e) => preventCommaAndPeriod(e)}
                // className="input_section-input"
                placeholder="DD"
                ref={input.register}
              />
              <input
                type="number"
                name="month"
                min={1}
                max={12}
                onChange={(e) => handleMonthChange(e)}
                step={1}
                onKeyDown={(e) => preventCommaAndPeriod(e)}
                // className="input_section-input"
                placeholder="MM"
                ref={input.register}
              />
              <input
                type="number"
                min={1900}
                max={new Date().getFullYear()}
                step={1}
                onChange={(e) => handleYearChange(e)}
                name="year"
                onKeyDown={(e) => preventCommaAndPeriod(e)}
                // className="input_section-input"
                placeholder="RRRR"
                ref={input.register}
              />
            </div>
            {(errors?.day || errors?.month || errors?.year) && (
              <Alert text="Toto pole je povinné" />
            )}
          </>
        ) : (
          input && (
            <>
              <input
                type={input.type}
                className="input_section-input"
                name={input.name}
                placeholder={input.placeholder}
                ref={input.register}
              />
            </>
          )
        )}
        {/* {input && (
          <>
            <input
              type={input.type}
              className="input_section-input"
              name={input.name}
              placeholder={input.placeholder}
              ref={input.register}
            />
          </>
        )} */}
        {children && Array.isArray(children)
          ? children.map((el: any, index: number) => {
              return (
                <div key={index} className={`children${index}`}>
                  {el}
                </div>
              );
            })
          : children}
      </div>
      {input && errors[input.name] && (
        <Alert text={errors[input.name].message} />
      )}
    </div>
  );
};
