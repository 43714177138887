import React from 'react';
import SurveyButton from '../../../Components/Buttons/SurveyButton';

import "./NotFound.scss";

const NotFoundPage = () => (
  <div className="notFound">
    <h1>404 - stránka nenalezena</h1>
    <p>
      Bohužel, stránka, kterou hledáte neexistuje. Zkuste se vrátit zpět nebo přejděte na úvodní stránku webu.
    </p>
    <div>
      <SurveyButton to="/prehled" text="Vrátit zpět" />
    </div>
  </div>
);

export default NotFoundPage;
