export const buttonsStatus = [
  { name: "single", text: "Svobodný/á" },
  { name: "partner", text: "Žijící ve vztahu, ale ne v manželství" },
  { name: "married", text: "Ženatý/vdaná" },
  { name: "civil_partnership", text: "Registrované partnerství" },
  { name: "engaged", text: "Zasnoubený/á" },
  { name: "widowed", text: "Ovdovělý/á" },
  { name: "married_separated", text: "Ženatý/vdaná, ale žijeme odděleně" },
  { name: "divorced", text: "Rozvedený/á" },
];
