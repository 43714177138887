import React, { FC, useEffect } from "react";
import { Line } from "rc-progress";
import { useState } from "react";
import { Steps, useStepsCompleted } from "../../hooks";
import { useSelector } from "react-redux";

const sectionsProgressValues: { [key in Steps[number]]: number } = {
  aboutYou: 25,
  guardians: 25,
  assetsProperty: 25,
  estate: 15,
  gifts: 5,
  restingPlace: 5,
};

export const ProgressBar: FC = () => {
  const stepsCompleted = useStepsCompleted();

  const { giftsReducer: giftsRedux, aboutYouReducer: aboutYouRedux } =
    useSelector((state: any) => state);

  const [progressBar, setProgressBar] = useState(0);

  useEffect(() => {
    const stepsCompletedKeys = Object.keys(stepsCompleted) as Steps[number][];

    const result = stepsCompletedKeys.reduce(
      (acc, curr) =>
        stepsCompleted[curr]
          ? (acc += sectionsProgressValues[curr])
          : (acc += 0),
      0
    );

    setProgressBar(() => result);
  }, [aboutYouRedux.names.fullName, giftsRedux]);

  return (
    <div className="progress-bar">
      <span>Váš pokrok: {progressBar}%</span>

      <Line
        percent={progressBar}
        strokeColor="#49B25D"
        strokeWidth={3}
        trailWidth={3}
      />
    </div>
  );
};
