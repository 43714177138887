import { createStore } from "redux";
import { combinedReducers } from "../Redux/reducers";
import { composeWithDevTools } from "redux-devtools-extension";

const composeEnhancers = composeWithDevTools({ trace: true });

export const store =
  process.env.NODE_ENV === "production"
    ? createStore(combinedReducers)
    : createStore(
        combinedReducers,
        composeEnhancers()
        // (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
        //   (window as any).__REDUX_DEVTOOLS_EXTENSION__()
      );
