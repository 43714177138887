export const giftsReducer = (state: any[] = [], action: any) => {
  switch (action.type) {
    case "GET_GIFTS":
      return action.payload;
    case "POST_GIFT":
      return [...state, action.payload];
    case "EDIT_GIFT":
      return state.map((gift: any) =>
        gift._id === action.payload._id ? action.payload : gift
      );
    default:
      return state;
  }
};
