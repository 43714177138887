import CharitiesList from "../Steps/Estate/CharitiesList";
import _ from "lodash";

const emptyStepContentTexts = {
  aboutYou: "Pár důležitých detailů o vás a vaší rodině.",
  children: "Zvolte poručníky a opatrovníky pro vaše děti a zvířata.",
  pets: "Zvolte opatrovníky pro vaše zvířata.",
  assets: "Vypište všechny důležité věci ve kterých máte uložené peníze.",
  estate: "Určete kdo zdědí váš majetek.",
  gifts: "Odkažte blízkým různé dárky a osobní zprávy.",
  restingPlace: "Vyberte si, jak by měl probíhat váš pohřeb.",
};

export function getAboutYouContent(aboutYou: any) {
  const aboutYouContent = [];
  if (aboutYou.names.fullName) {
    aboutYouContent.push(aboutYou.names.fullName);
  } else {
    return [emptyStepContentTexts.aboutYou];
  }
  if (aboutYou.marital_status.partner.fullName) {
    aboutYouContent.push(`Žiji s ${aboutYou.marital_status.partner.fullName}.`);
  }
  if (aboutYou.children.length === 1) {
    aboutYouContent.push("Mám jedno dítě.");
  } else if (aboutYou.children.length > 1 && aboutYou.children.length < 5) {
    aboutYouContent.push("Mám " + aboutYou.children.length + " děti.");
  } else if (aboutYou.children.length > 4) {
    aboutYouContent.push("Mám " + aboutYou.children.length + " dětí.");
  }
  if (aboutYou.pets.length === 1) {
    aboutYouContent.push("Starám se o jedno zvíře.");
  } else if (aboutYou.pets.length > 1 && aboutYou.pets.length < 5) {
    aboutYouContent.push("Starám se o  " + aboutYou.pets.length + " zvířata.");
  } else if (aboutYou.pets.length > 4) {
    aboutYouContent.push("Starám se o " + aboutYou.pets.length + " zvířat.");
  }
  if (aboutYouContent.join("").length > 80) {
    return [aboutYouContent[0]];
  }
  return aboutYouContent;
}

export function getChildrenContent(
  childrenU18: any[],
  allPeople: any[],
  pets?: any[]
) {
  const childrenContent = childrenU18
    .filter((child: any) => child.guardian.length !== 0)
    .map((childWithGuardian: any) => {
      const thisChildGuardians = allPeople.filter((people: any) =>
        childWithGuardian.guardian.includes(people._id)
      );
      if (!thisChildGuardians[0]?.fullName) {
        return "";
      }
      const contentMessage = `O ${childWithGuardian.fullName} se bude starat ${thisChildGuardians[0]?.fullName}`;
      if (childWithGuardian.guardian.length > 1) {
        return contentMessage + " a další...";
      } else {
        return contentMessage + ".";
      }
    })
    .filter((el: any) => el);
  if (pets && pets?.length > 0) {
    pets.map((pet: any) => {
      const thisPetGuardians = allPeople.filter((people: any) =>
        pet.guardian.includes(people._id)
      );
      if (pet.guardian.length > 1) {
        childrenContent.push(
          `O ${pet.name} se bude starat ${thisPetGuardians[0]?.fullName} a další...`
        );
      } else if (pet.guardian.length === 1) {
        childrenContent.push(
          `O ${pet.name} se bude starat ${thisPetGuardians[0]?.fullName}.`
        );
      }
      return;
    });
  }
  if (childrenContent.length === 0) {
    return [emptyStepContentTexts.children];
  }
  if (childrenContent.length > 3) {
    return childrenContent.slice(0, 4);
  } else {
    return childrenContent;
  }
}

export function getPetsContent(pets: any[], allPeople: any[]) {
  const petsContent = pets
    .filter((pet: any) => pet.guardian.length !== 0)
    .map((petWithGuardian: any) => {
      const thisPetGuardians = allPeople.filter((people: any) =>
        petWithGuardian.guardian.includes(people._id)
      );
      const contentMessage = `O ${petWithGuardian.name} se bude starat ${thisPetGuardians[0]?.fullName}`;
      if (thisPetGuardians.length > 1) {
        return contentMessage + " a další...";
      } else {
        return contentMessage + ".";
      }
    });
  if (petsContent.length === 0) {
    return [emptyStepContentTexts.pets];
  } else if (petsContent.length > 3) {
    return petsContent.slice(0, 4);
  } else {
    return petsContent;
  }
}
export function getAssetsContent(assets: any) {
  const assetsContent = assets.map((asset: any) => {
    switch (asset.type) {
      case "stocks":
        return `Akcie - ${asset.stocksCompany}`;
      case "bank":
        return `Banka - ${asset.bankName}`;
      case "insurance":
        return `Životní pojištění - ${asset.insuranceProvider}`;
      case "pension":
        return `Penzijní připojištění - ${asset.pensionProvider}`;
      case "property":
        return `Nemovitost - ${asset.address}`;
      case "business":
        return `Obchodní společnost - ${asset.ownedBusiness}`;
      default:
        return "Majetek";
    }
  });
  if (assetsContent.length === 0) {
    return [emptyStepContentTexts.assets];
  } else if (assetsContent.length > 3) {
    return assetsContent.slice(0, 4);
  } else {
    return assetsContent;
  }
}

export function getEstateContent(inheritEstate: any, allPeopleById: any) {
  if (
    inheritEstate.inheritors.length === 0 &&
    inheritEstate.charities.length === 0
  ) {
    return [emptyStepContentTexts.estate];
  }
  const inheritorsContent = inheritEstate.inheritors.map(
    (inheritor: any) =>
      `${allPeopleById[inheritor.person]?.fullName} zdědí ${
        inheritor.percentageFromAssets
      }%`
  );
  const defaultCharitiesById = _.keyBy(CharitiesList, "_id");

  const charitiesContent = inheritEstate.charities.map((charity: any) => {
    if (Object.keys(defaultCharitiesById).includes(charity.charity)) {
      return `${defaultCharitiesById[charity.charity].name} dostane ${
        charity.percentageFromAssets
      }%`;
    } else {
      return `${charity.charity} dostane ${charity.percentageFromAssets}%`;
    }
  });
  return [...inheritorsContent, ...charitiesContent];
}

export function getRestingPlaceContent(restingPlace: any) {
  const restingPlaceContent = [];
  const restingPlaceDictionary: any = {
    cemetery: "Uložení do hrobu",
    natural: "Přírodní pohřeb",
    family: "O pohřbu rozhodne rodina",
    cremation: "Kremace",
    science: "Darovat tělo na vědecké účely",
  };
  if (!restingPlace.place) {
    return [emptyStepContentTexts.restingPlace];
  } else {
    restingPlaceContent.push(restingPlaceDictionary[restingPlace.place]);
  }
  if (restingPlace?.message) {
    restingPlaceContent.push("Přidali jste přání týkající se vašeho pohřbu.");
  } else {
    restingPlaceContent.push(
      "Nebylo přídáno žádné přání týkající se vašeho pohřbu."
    );
  }
  return restingPlaceContent;
}

export function getGiftsContent(gifts: any[]) {
  if (gifts.length === 0) {
    return [emptyStepContentTexts.gifts];
  }
  const giftTypeDicReadable: any = {
    itemCollection: "Předmět, sbírka",
    property: "Nemovitost",
    vehicle: "Vozidlo",
    money: "Peníze",
  };
  return gifts.map(
    (gift: any) =>
      `${giftTypeDicReadable[gift.giftType]} - ${gift.gift}${
        gift.giftType === "money" ? "Kč" : ""
      }`
  );
}

export function allChildrenU18HaveGuardians(childrenU18: any[]) {
  if (childrenU18.length === 0) {
    return true;
  } else {
    return childrenU18.every((child: any) => child.guardian.length > 0);
  }
}

export function allPetsHaveGuardians(pets: any[]) {
  if (pets.length === 0) {
    return true;
  } else {
    return pets.every((pet: any) => pet.guardian.length > 0);
  }
}
