import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import GoBack from "../../../Components/Buttons/GoBack/GoBack";
import SaveButton from "../../../Components/Buttons/SaveButton";
import { Alert, FormInput } from "../../../Components/Form";
import PersonBox from "../../../Components/PersonBox/PersonBox";
import { SEO } from "../../../Components/seo/Seo";
import { INHERIT_ESTATE_MUTATION } from "../../../GraphQL/Mutations/estate";
import {
  allPeopleArrayFn,
  prepareInheritEstateData,
  preventMoreThan2Decimal,
} from "../../../Helpers";
import { useSaveResultToRedux, useSendMutation } from "../../../hooks";
import {
  addCharitiesPercentages,
  addInheritorsPercentages,
} from "../../../Redux/actions";
import CharitiesList from "./CharitiesList";
import { RealTimeShareSum } from "./RealTimeShareSum";
import { getDefaultShareValues } from "./utils";

const Share = () => {
  const history = useHistory();

  const saveToRedux = useSaveResultToRedux();

  const {
    aboutYouReducer: {
      children: childrenRedux,
      marital_status: { partner: partnerRedux },
    },
    randomPeopleReducer: randomPeopleRedux,
    inheritEstateReducer: {
      charities: charitiesRedux,
      inheritors: inheritorsRedux,
    },
  } = useSelector((state: any) => state);

  const [error, setError] = useState("");
  const [sumRealTime, setSumRealTime] = useState<any>(0);
  const [charitiesListIDs] = useState(
    () => new Set(CharitiesList.map((charity: any) => charity._id))
  );

  const nonDefaultCharities = charitiesRedux
    .filter((charity: any) => !charitiesListIDs.has(charity.charity))
    .map((charity: any) => ({
      _id: charity.charity,
      type: "charity",
      name: charity.charity,
    }));

  const inheritorsIDs = inheritorsRedux.map(
    (inheritor: any) => inheritor.person
  );
  const charitiesIDs = charitiesRedux.map((charity: any) => charity.charity);

  const inheritors = allPeopleArrayFn(
    childrenRedux,
    partnerRedux,
    randomPeopleRedux
  ).filter((el: any) => inheritorsIDs.includes(el._id));

  const charities = [...CharitiesList, ...nonDefaultCharities].filter(
    (el: any) =>
      charitiesIDs.includes(el._id) || charitiesIDs.includes(el.charity)
  );

  const { register, errors, watch, handleSubmit } = useForm({
    defaultValues: getDefaultShareValues(inheritorsRedux, charitiesRedux),
  });

  const charitiesWatch = watch("charities");

  const inheritorsWatch = watch("inheritors");

  const {
    sendMutationRequest: sendInheritEstateMutation,
    loading: inheritEstateLoading,
  } = useSendMutation(INHERIT_ESTATE_MUTATION);

  function onSubmit(formData: any, e: any) {
    e.preventDefault();

    const { inheritors: inheritorsData, charities: charitiesData } = formData;

    setError("");

    if (Number(sumRealTime) < 99.99) {
      setError("Součet musí dávat dohromady 100%");
      return;
    }

    inheritEstateMutation(inheritorsData, charitiesData);
  }

  async function inheritEstateMutation(
    inheritorsData: any[],
    charitiesData: any[]
  ) {
    const serializeData = prepareInheritEstateData(
      inheritorsData,
      charitiesData,
      inheritorsRedux,
      charitiesRedux
    );

    await sendInheritEstateMutation({ inheritEstate: serializeData });

    saveToRedux(addInheritorsPercentages, inheritorsData);
    saveToRedux(addCharitiesPercentages, charitiesData);

    history.push(`/dedictvi/nahradnik/${inheritorsRedux[0].person}`);
  }

  useEffect(() => {
    if (charitiesWatch || inheritorsWatch) {
      const shareReduced: any = [
        ...Object.values(inheritorsWatch || []),
        ...Object.values(charitiesWatch || []),
      ].reduce((acc, curr): any => Number(acc) + Number(curr), 0);

      setSumRealTime(() => shareReduced.toFixed(2));
    }
  }, [inheritorsWatch, charitiesWatch]);

  useEffect(() => {
    setError("");

    if (Number(sumRealTime) > 100) {
      setError("Můžete rozdělit maximálně 100% svého majetku");
    }
  }, [sumRealTime]);

  return (
    <div className="estate form">
      <SEO page="estate_share" />

      <GoBack />

      <div className="share">
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            title={"Určete, komu bude náležet jaký podíl."}
            description={["Náhradníky budete moci zvolit v dalších krocích."]}
            errors={errors}
          >
            {inheritors.map((el: any, index: number) => (
              <div key={index}>
                <PersonBox
                  person={el}
                  id={el._id}
                  description={el.birth}
                  name={`inheritors[${el._id}]`}
                  input={true}
                  personName={el.fullName || el.name}
                  onChange={preventMoreThan2Decimal}
                  register={register}
                />

                {errors?.inheritors?.[el._id] && (
                  <Alert text={errors.inheritors[el._id].message} />
                )}
              </div>
            ))}

            {charities.map((charity: any, index: number) => (
              <div key={index}>
                <PersonBox
                  person={charity}
                  id={charity["_id"]}
                  description="Nezisková organizace"
                  name={`charities["${charity._id}"]`}
                  input={true}
                  personName={charity.name}
                  onChange={preventMoreThan2Decimal}
                  register={register({
                    min: { value: 1, message: "Nejmenší možná hodnota je 1" },
                  })}
                />

                {errors?.charities?.[charity._id] && (
                  <Alert text={errors.charities[charity._id].message} />
                )}
              </div>
            ))}
          </FormInput>

          {error && <Alert text={error} />}

          <div className="share-right">
            <RealTimeShareSum sumRealTime={sumRealTime} />
          </div>

          <SaveButton loading={inheritEstateLoading} readyToRoll={(sumRealTime === "100.00") ? true : false} />
        </form>
      </div>
    </div>
  );
};

export default Share;
