import { useDispatch } from "react-redux";
import { addSnackbarMessage, removeSnackbarMessage } from "../Redux/actions";

export const useToastMessage = () => {
  const dispatch = useDispatch();

  function postToastMessage(message: string, isVisible: boolean = true) {
    dispatch(addSnackbarMessage({ message, isVisible }));

    setTimeout(() => {
      dispatch(removeSnackbarMessage());
    }, 6500);
  }

  return { postToastMessage };
};
