import { OneRandomPeople } from "../actions";

export const randomPeopleReducer = (
  state: OneRandomPeople[] = [],
  action: any
) => {
  switch (action.type) {
    case "GET_RANDOM_PEOPLE":
      return action.payload;
    case "ADD_TO_RANDOM_PEOPLE":
      return [...state, action.payload];
    default:
      return state;
  }
};
