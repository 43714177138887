import React from 'react';
import SaveButton from '../../Buttons/SaveButton';
import './InviteFriends.css';

interface InviteFriendsPropsI {
  createFriendsCode: any;
  createFriendsCodeLoading: boolean;
  friendsCode: string;
}

const InviteFriends = (props: InviteFriendsPropsI) => {
  return (
    <div className='invite_friends'>
      <h4>Sleva pro přátele</h4>
      <p>
        Vygenerujte si kód pro vaše přátele, kteří ho mohou využít a dostanou tak slevu na vlastní závěť.
      </p>
      {props.friendsCode ? (
        <div className='ref_code-generated'>
          <p>Slevový kód pro vaše přátele:</p>
          <b>{props.friendsCode}</b>
        </div>
      ) : (
        <SaveButton
          handleContinue={props.createFriendsCode}
          loading={props.createFriendsCodeLoading}
          loadingText='Načítání...'
          buttonText='Získat kód'
          readyToRoll={true}
        />
      )}
    </div>
  );
};

export default InviteFriends;
