import { useLazyQuery, useMutation } from "@apollo/client";
import Cookies from "js-cookie";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Header from "./Components/Header/Header";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import Snackbars from "./Components/Snackbars/Snackbars";
import { UPDATE_CHILD_MUTATION } from "./GraphQL/Mutations";
import { GET_WILL } from "./GraphQL/Queries";
import { compareCookies } from "./Helpers";
import AppRoutes from "./AppRoutes";
import { aboutYou_children } from "./Redux/actions";
import { user_logout } from "./Redux/actions/authentication";
import { updateWholeRedux } from "./Redux/UpdateStore";

const App = () => {
  const dispatch = useDispatch();

  const [getWill, { data: dataWill }] = useLazyQuery(GET_WILL, {
    fetchPolicy: "network-only",
  });
  const [editChildMutation] = useMutation(UPDATE_CHILD_MUTATION);

  const [cookieIsBeingCompared, setCookieIsBeingCompared] = useState(true);

  function sendAllChildrenMutations(children: any[]) {
    return Promise.all(
      children.map(({ address, ...child }: any) =>
        editChildMutation({
          variables: {
            ...child,
            street: address.street,
            id: child._id,
            over18: moment().diff(child.birth, "years") >= 18,
          },
        })
      )
    );
  }

  useEffect(() => {
    if (dataWill) {
      const { getWill } = dataWill;

      updateWholeRedux(getWill);
      if (getWill.children.length > 0) {
        // update children's 'over18' field on log in
        sendAllChildrenMutations(getWill.children)
          .then((res) => {
            dispatch(aboutYou_children(res[res.length - 1].data.updateChild.children));
          })
          .catch((err) => console.log(err));
      }
      setCookieIsBeingCompared(false);
    }
  }, [dataWill]);

  useEffect(() => {
    const authCookie = Cookies.get("logged");
    if (authCookie) {
      compareCookies()
        .then((res) => {
          if (res) {
            getWill();
          } else {
            dispatch(user_logout());
            setCookieIsBeingCompared(false);
          }
        })
        .catch((err) => {
          dispatch(user_logout());
        });
    } else {
      dispatch(user_logout(false));
      setCookieIsBeingCompared(false);
    }
  }, []);
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Snackbars />
      <Header />
      <AppRoutes cookieIsBeingCompared={cookieIsBeingCompared} />
    </BrowserRouter>
  );
};

export default App;
