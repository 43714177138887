import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import GoBack from "../../../../Components/Buttons/GoBack/GoBack";
import { Alert, Form, FormInput } from "../../../../Components/Form";
import { SEO } from "../../../../Components/seo/Seo";
import { NAMES_MUTATION } from "../../../../GraphQL/Mutations";
import { formatBirthStringToObject, isValidBirth } from "../../../../Helpers";
import { useSaveResultToRedux } from "../../../../hooks/useSaveResultToRedux";
import { useSendMutation } from "../../../../hooks/useSendMutation";
import { aboutYou_names } from "../../../../Redux/actions/index";
import { GenderRadio } from "./GenderRadio/GenderRadio";
import Tooltip from "../../../../Components/Tooltip/Tooltip";

const Names = () => {
  const history = useHistory();

  const { sendMutationRequest, loading } = useSendMutation(NAMES_MUTATION);
  const saveToRedux = useSaveResultToRedux();

  const [canContinue, setCanContinue] = useState(false);

  const names = useSelector((state: any) => state.aboutYouReducer.names);

  const { register, errors, handleSubmit, reset, watch, setError, clearErrors } =
    useForm();

  const fullNameWatch = watch("fullName");
  const dayWatch = watch("day");
  const monthWatch = watch("month");
  const yearWatch = watch("year");

  const onSubmit = async (formData: any, e: any) => {
    e.preventDefault();

    const constructedBirth = `${formData.year}-${formData.month}-${formData.day}`;

    if (!isValidBirth(constructedBirth)) {
      setError("manual", {
        type: "validate",
        message: "Datum narození nemůže být starší než dnešní datum",
      });
    }

    const { fullName, gender } = formData;
    const resultData = await sendMutationRequest({
      fullName,
      birth: constructedBirth,
      gender,
    });

    saveDataAndRedirect(resultData);
  };

  function saveDataAndRedirect(data: any) {
    const { fullName, birth, gender } = data.names;

    saveToRedux(aboutYou_names, { fullName, birth, gender });

    history.push("/o-vas/adresa");
  }

  useEffect(() => {
    if (!fullNameWatch) {
      setCanContinue(false);
      return;
    }

    setCanContinue(true);
  }, [fullNameWatch]);

  useEffect(() => {
    if (errors?.manual) clearErrors("manual");
  }, [dayWatch, monthWatch, yearWatch]);

  useEffect(() => {
    if (names?.birth) {
      const dateValues = formatBirthStringToObject(names.birth);

      reset({
        ...names,
        ...dateValues,
      });
    } else {
      reset(names);
    }
  }, []);

  return (
    <div className="form">
      <SEO page="names" />

      <GoBack />

      <Form
        onSubmit={handleSubmit(onSubmit)}
        canContinue={canContinue}
        isLoading={loading}
      >
        <FormInput
          title="Jaké je vaše celé jméno?"
          description={[
            "To je to, co máte v občanském průkaze, řidičském průkaze, bankovní kartě...",
          ]}
          label="Vaše celé jméno"
          errors={errors}
          input={{
            type: "string",
            placeholder: "Např.: Jan Novák",
            name: "fullName",
            register: register({
              required: { value: true, message: "Toto pole je povinné" },
            }),
          }}
        />

        {/* <FormInput
          title="Jak bychom vás měli dále oslovovat?"
          description={[
            'Napříkad pokud vám budeme posílat e-mail nebo se s vámi bavit po telefonu.',
          ]}
          label="Vaše preferované jméno"
          errors={errors}
          input={{
            type: 'string',
            placeholder: '',
            name: 'nickname',
            register: register(),
          }}
        /> */}

        <FormInput
          title="Jaké je vaše datum narození?"
          label="Datum narození"
          input={{
            name: "birth",
            register: register({
              required: { value: true, message: "Toto pole je povinné" },
            }),
            type: "date",
          }}
          errors={errors}
        />

        {errors?.manual && <Alert text={errors.manual.message} />}

        <Tooltip id="birthdate" title="Datum narození" customText="Je vám více než 18 let?" description={["K sepsání platné alografní závěti je třeba být zcela svéprávný a zletilý, v případě, že jste ještě nedosáhli 18 let, obraťte se prosím na notáře."]} />

        <GenderRadio register={register} />
      </Form>
    </div>
  );
};

export default Names;
