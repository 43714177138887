import React, { VFC } from "react";
import { DELETE_PET_MUTATION } from "../../GraphQL/Mutations";
import { useSaveResultToRedux, useSendMutation } from "../../hooks";
import { aboutYou_pets } from "../../Redux/actions";
import { FormInput } from "../Form";

interface PetFormI {
  onSubmit: any;

  toggleModal: any;
  errors: any;
  register: any;

  reset?: any;
  id?: string;
}

export const PetForm: VFC<PetFormI> = ({
  onSubmit,
  errors,
  register,
  id,
  reset,
  toggleModal,
}) => {
  const editing = !!id;

  const {
    sendMutationRequest: sendDeletePetMutation,
    loading: deletePetLoading,
  } = useSendMutation(DELETE_PET_MUTATION);

  const saveToRedux = useSaveResultToRedux();

  async function deletePet() {
    const { deletePet } = (await sendDeletePetMutation({ id })) ?? {};

    saveToRedux(aboutYou_pets, deletePet.pets);

    reset({ pets: deletePet.pets });

    toggleModal();
  }

  return (
    <form className="person_form" onSubmit={onSubmit}>
      <FormInput
        className="modal_form"
        label="Jméno domácího zvířete"
        errors={errors}
        input={{
          name: "fullName",
          register: register({
            maxLength: {
              value: 30,
              message: "Maximální počet znaků je 30",
            },
            required: { value: true, message: "Toto pole je povinné" },
          }),
          type: "text",
          placeholder: "Např.: Punťa, Micka...",
        }}
      />

      <div className="modal_footer">
        <button type="submit" className="modal_save">
          Uložit
        </button>

        {editing && (
          <button
            type="button"
            onClick={() => deletePet()}
            className="modal_cancel"
          >
            {deletePetLoading ? "Odstraňování..." : "Odstranit zvíře"}
          </button>
        )}
      </div>
    </form>
  );
};
