export const specialWishesReducer = (
  state: { wish: string } = { wish: "" },
  action: any
) => {
  switch (action.type) {
    case "SPECIAL_WISH":
      return { wish: action.payload };
    default:
      return state;
  }
};
