import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  ADD_CHILD_MUTATION,
  ADD_PERSON_MUTATION,
  ADD_PET_MUTATION,
  EDIT_PARTNER_MUTATION,
  MARITAL_STATUS_MUTATION,
  UPDATE_CHILD_MUTATION,
  UPDATE_PERSON_MUTATION,
  UPDATE_PET_MUTATION,
} from "../../GraphQL/Mutations";
import {
  formatBirthObjectToString,
  isPersonOlderThan18Years,
  isValidBirth,
} from "../../Helpers";
import { useSaveResultToRedux, useSendMutation } from "../../hooks";
import {
  aboutYou_children,
  aboutYou_editPartner,
  aboutYou_newPartner,
  aboutYou_pets,
  getRandomPeople,
} from "../../Redux/actions";
import Modal from "../Modal/Modal";
import { modalTexts, typesWithoutBirth } from "./helpers";
import "./PersonBox.scss";
import { PersonForm } from "./PersonForm";
import { PetForm } from "./PetForm";

export type PersonTypes =
  | "guardian"
  | "partner"
  | "child"
  | "pet"
  | "inheritor"
  | "default";

interface AddPersonInterface {
  toggle: any;
  personType: PersonTypes;
  append: (fullName: string, birth?: string, id?: string) => any;
  reset?: any;
  personSelected?: any;
  callback?: (id: string) => any;
}

const AddPerson = (props: AddPersonInterface) => {
  const {
    toggle,
    personType,
    append,
    personSelected,
    reset: propsReset,
  } = props;


  

  const updatingPerson = !!personSelected?._id;

  const {
    register,
    handleSubmit,
    errors,
    reset,
    setError,
    clearErrors,
    watch,
  } = useForm();

  const maritalStatusStatusRedux = useSelector(
    (state: any) => state.aboutYouReducer.marital_status.status
  );

  const saveToRedux = useSaveResultToRedux();

  const {
    sendMutationRequest: sendMaritalStatusMutation,
    loading: maritalStatusLoading,
  } = useSendMutation(MARITAL_STATUS_MUTATION);

  const {
    sendMutationRequest: sendEditPartnerMutation,
    loading: editPartnerLoading,
  } = useSendMutation(EDIT_PARTNER_MUTATION, {
    fetchPolicy: "no-cache",
    mutation: EDIT_PARTNER_MUTATION,
  });

  const {
    sendMutationRequest: sendAddChildMutation,
    loading: addChildLoading,
  } = useSendMutation(ADD_CHILD_MUTATION);

  const {
    sendMutationRequest: sendEditChildMutation,
    loading: editChildLoading,
  } = useSendMutation(UPDATE_CHILD_MUTATION);

  const { sendMutationRequest: sendAddPetMutation, loading: addPetLoading } =
    useSendMutation(ADD_PET_MUTATION);

  const { sendMutationRequest: sendEditPetMutation, loading: editPetLoading } =
    useSendMutation(UPDATE_PET_MUTATION);

  const {
    sendMutationRequest: sendAddPersonMutation,
    loading: addPersonLoading,
  } = useSendMutation(ADD_PERSON_MUTATION);

  const {
    sendMutationRequest: sendEditPersonMutation,
    loading: editPersonLoading,
  } = useSendMutation(UPDATE_PERSON_MUTATION);

  const mutationLoading =
    maritalStatusLoading ||
    editPartnerLoading ||
    addChildLoading ||
    editChildLoading ||
    addPetLoading ||
    editPetLoading ||
    addPersonLoading ||
    editPersonLoading;

  const dayWatch = watch("day");
  const monthWatch = watch("month");
  const yearWatch = watch("year");

  const onSubmit = (formData: any, e: any) => {
    e.preventDefault();

    if (mutationLoading) {
      return;
    }

    const { year, month, day } = formData;

    const constructedBirth =
      personType !== "pet" ? formatBirthObjectToString(year, month, day) : "";

    if (
      !typesWithoutBirth.includes(personType) &&
      !isValidBirth(constructedBirth)
    ) {
      setError("date", {
        type: "manual",
        message: "Datum narození nemůže být starší než dnešní datum",
      });

      return;
    }

    if (updatingPerson) {


      switch (personType) {


        case "partner":
          editPartnerMutation({ ...formData, birth: constructedBirth });
          break;
        case "child":
          editChildMutation({ ...formData, birth: constructedBirth });
          break;
        case "pet":
          editPetMutation(formData);
          break;
        case "guardian":
        case "inheritor":
        case "default":
          editPersonMutation({ ...formData, birth: constructedBirth });
          break;
        default:
          break;
      }
    } else {
      switch (personType) {
        case "partner":
          sendNewPartnerMutation({ ...formData, birth: constructedBirth });

          break;
        case "child":
          addChild({ ...formData, birth: constructedBirth });

          break;
        case "pet":
          addPetMutation(formData);

          break;
        case "guardian":
        case "default":
        case "inheritor":
          addPersonMutation({ ...formData, birth: constructedBirth });

          break;
        default:
          break;
      }
    }
  };

  async function editPartnerMutation(data: any) {
    const { editPartner } =
      (await sendEditPartnerMutation({
        partnerInput: {
          _id: personSelected._id,
          fullName: data.fullName,
          birth: data.birth,
          address: {
            city: data.city,
            street: data.street,
          },
        },
      })) ?? {};
      
    saveToRedux(aboutYou_editPartner, editPartner.maritalStatus.partner);

    toggle();
  }

  async function editChildMutation(data: any) {
    const { updateChild } =
      (await sendEditChildMutation({
        id: personSelected._id,
        fullName: data.fullName,
        birth: data.birth,
        city: data.city,
        street: data.street,
        over18: isPersonOlderThan18Years(data),
        guardian: personSelected.guardian,
      })) ?? {};

    saveToRedux(aboutYou_children, updateChild.children);

    if (propsReset) {
      propsReset({ children: updateChild.children });
    }

    toggle();
  }

  async function editPetMutation(data: any) {
    const { updatePet } =
      (await sendEditPetMutation({
        id: personSelected._id,
        fullName: data.fullName,
        guardian: personSelected.guardian,
      })) ?? {};

    saveToRedux(aboutYou_pets, updatePet.pets);

    propsReset({ pets: updatePet.pets });

    toggle();
  }

  async function editPersonMutation(data: any) {
    const { updateRandomPerson } =
      (await sendEditPersonMutation({
        id: personSelected._id,
        fullName: data.fullName,
        birth: data.birth,
        type: personSelected.type,
        street: data.street,
        city: data.city,
      })) ?? {};

    saveToRedux(getRandomPeople, updateRandomPerson.people);

    toggle();
  }

  async function sendNewPartnerMutation(data: any) {
    const { maritalStatus } =
      (await sendMaritalStatusMutation({
        status: maritalStatusStatusRedux,
        city: data.city,
        street: data.street,
        partnerName: data.fullName,
        birth: data.birth,
        over18: isPersonOlderThan18Years(data),
      })) ?? {};

    saveToRedux(aboutYou_newPartner, maritalStatus.partner);

    toggle();
  }

  async function addChild(data: any) {
    const { newChild } =
      (await sendAddChildMutation({
        fullName: data.fullName,
        birth: data.birth,
        street: data.street,
        city: data.city,
        over18: isPersonOlderThan18Years(data),
      })) ?? {};

    saveToRedux(aboutYou_children, newChild);

    append(data.fullName, data.birth);

    toggle();
  }

  async function addPetMutation(data: any) {
    const { newPet } =
      (await sendAddPetMutation({ fullName: data.fullName })) ?? {};

    saveToRedux(aboutYou_pets, newPet);

    append(data.fullName);

    toggle();
  }

  async function addPersonMutation(data: any) {
    const { newPerson } =
      (await sendAddPersonMutation({
        fullName: data.fullName,
        birth: data.birth,
        type: personSelected.type,
        street: data.street,
        city: data.city,
      })) ?? {};

    saveToRedux(getRandomPeople, newPerson);

    append(data.fullName, data.birth);

    if (props.callback) {
      const newPersonID = newPerson.find(
        (person: any) =>
          person.birth === data.birth && person.fullName === data.fullName
      );

      props.callback(newPersonID._id);
    }

    toggle();
  }

  useEffect(() => {
    if (errors?.date) {
      clearErrors("date");
    }
  }, [dayWatch, monthWatch, yearWatch]);

  useEffect(() => {
    if (personType === "pet") {
      reset({ fullName: personSelected.name });

      return;
    }

    const dateInputs = personSelected?.birth.split("-");

    if (personType === "guardian") {
      reset({
        fullName: personSelected.fullName,
        day: dateInputs[2],
        month: dateInputs[1],
        year: dateInputs[0],
        city: personSelected.address?.city,
        street: personSelected.address?.street,
      });
    } else {
      reset({
        fullName: personSelected.fullName,
        day: dateInputs[2],
        month: dateInputs[1],
        year: dateInputs[0],
        city: personSelected.address?.city,
        street: personSelected.address?.street,
      });
    }
  }, []);

  return (
    <Modal
      header={modalTexts[personType][updatingPerson ? 1 : 0]}
      toggle={toggle}
    >
      {personType === "pet" ? (
        <PetForm
          register={register}
          errors={errors}
          onSubmit={handleSubmit(onSubmit)}
          toggleModal={toggle}
          id={personSelected._id ?? ""}
          reset={reset}
        />
      ) : (
        <PersonForm
          errors={errors}
          register={register}
          reset={propsReset}
          onSubmit={handleSubmit(onSubmit)}
          personSelected={personSelected}
          personType={personType}
          toggleModal={toggle}
        />
      )}
    </Modal>
  );
};

export default AddPerson;
