import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import GoBack from "../../../../Components/Buttons/GoBack/GoBack";
import SaveButton from "../../../../Components/Buttons/SaveButton";
import {
  AddButton,
  Alert,
  FormButtonI,
  FormButtons,
  FormInput,
} from "../../../../Components/Form";
import DeleteModal from "../../../../Components/Modal/DeleteModal";
import AddPerson from "../../../../Components/PersonBox/AddPerson";
import PersonBox from "../../../../Components/PersonBox/PersonBox";
import { SEO } from "../../../../Components/seo/Seo";
import Tooltip from "../../../../Components/Tooltip/Tooltip";
import { tooltipData } from "../../../../Data/tooltipData";
import {
  DELETE_ALL_PETS_MUTATION,
  DELETE_PET_MUTATION,
  HAS_PETS_MUTATION,
} from "../../../../GraphQL/Mutations";
import {
  useIsReadyToContinue,
  useModal,
  useSaveResultToRedux,
  useSendMutation,
} from "../../../../hooks";
import { aboutYou_hasPets, aboutYou_pets } from "../../../../Redux/actions";

const initialPet = {
  _id: "",
  fullName: "",
  type: "pet",
};

const Pets = () => {
  const history = useHistory();

  const {
    sendMutationRequest: sendDeleteAllPetsMutation,
    loading: deleteAllPetsLoading,
  } = useSendMutation(DELETE_ALL_PETS_MUTATION);
  const { sendMutationRequest: sendHasPetsMutation, loading: hasPetsLoading } =
    useSendMutation(HAS_PETS_MUTATION);
  const {
    sendMutationRequest: sendDeletePetMutation,
    loading: deletePetLoading,
  } = useSendMutation(DELETE_PET_MUTATION);

  const { reset, handleSubmit } = useForm();

  const [hasPets, setHasPets] = useState<any>();
  const [petSelected, setPetSelected] = useState<any>(initialPet);

  const [isAddPetModalOpen, toggleAddPetModal] = useModal();
  const [isDeletePetModalOpen, toggleDeletePetModal] = useModal();

  const [error, setError] = useState("");
  const [requiredError, setRequiredError] = useState(false);

  const pets = useSelector((state: any) => state.aboutYouReducer.pets);
  const hasPetsRedux = useSelector(
    (state: any) => state.aboutYouReducer.hasPets
  );

  const saveToRedux = useSaveResultToRedux();
  const isReadyToContinue = useIsReadyToContinue({
    conditions: [hasPets === true && pets.length, hasPets === false],
  });

  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();
    if (hasPets !== true && hasPets !== false) {
      // option not chosen yet
      setRequiredError(true);
      return;
    }

    if (hasPets && !pets.length) {
      setError("Musíte zvolit alespoň jedno domácí zvíře");

      return;
    }

    if (!hasPets) {
      const [, hasPetsResult] = await Promise.all([
        sendDeleteAllPetsMutation(),
        sendHasPetsMutation({ hasPets }),
      ]);

      saveToRedux(aboutYou_hasPets, hasPetsResult?.hasPets);

      saveToRedux(aboutYou_pets, []);

      history.push("/prehled");
    } else {
      const hasPetsResult = await sendHasPetsMutation({ hasPets });

      saveToRedux(aboutYou_hasPets, hasPetsResult?.hasPets);

      history.push("/prehled");
    }
  };

  const handleClick = (e: any) => {
    e.target.name === "yes" ? setHasPets(true) : setHasPets(false);
  };

  function addPet() {
    setPetSelected(initialPet);

    toggleAddPetModal();
  }

  function editPet(pet: any) {
    setPetSelected({ ...pet, type: "pet" });

    toggleAddPetModal();
  }

  function deleteConfirmation(pet: any) {
    setPetSelected(pet);

    toggleDeletePetModal();
  }

  async function deletePetMutation() {
    try {
      const result = await sendDeletePetMutation({ id: petSelected._id });
      saveToRedux(aboutYou_pets, result?.deletePet.pets);

      toggleDeletePetModal();
    } catch (err) {
      console.log(err);
    }
  }

  function appendPet() {
    reset({ pets: pets });
  }

  useEffect(() => {
    if ((hasPets === true && pets.length) || hasPets === false) {
      setError("");
    }

    if (hasPets === true || hasPets === false) {
      setRequiredError(false);
    }
  }, [hasPets, pets]);

  useEffect(() => {
    if (hasPetsRedux === true) {
      setHasPets(true);

      reset({ pets: pets });
    }

    if (hasPetsRedux === false) {
      setHasPets(false);
    }
  }, []);

  const buttonsData: FormButtonI[] = [
    {
      name: "yes",
      className: "single_button_section first_button",
      active: hasPets === true,
      activeClassName: "pressed",
      text: "Ano",
    },
    {
      name: "no",
      className: "single_button_section",
      active: hasPets === false,
      activeClassName: "pressed",
      text: "Ne",
    },
  ];

  return (
    <div className="form">
      <SEO page="pets" />

      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput title="Máte nějaké domácí zvíře?">
          <FormButtons buttons={buttonsData} onClick={handleClick} />

          {requiredError && <Alert text="Zvolte prosím jednu z možností" />}
        </FormInput>

        {hasPets && (
          <FormInput title="Vaše domácí zvířata">
            {pets.length > 0
              ? pets.map((el: any, index: number) => (
                  <PersonBox
                    person={el}
                    id={el._id}
                    key={index}
                    name={`pets[${index}]`}
                    personName={el.name || el.fullName}
                    description={el.description}
                    edit={editPet}
                    deletePerson={deleteConfirmation}
                  />
                ))
              : null}

            <AddButton className="partner_add" onClick={addPet}>
              Přidat zvíře
            </AddButton>

            <Tooltip
              id={tooltipData["pet"].id}
              title={tooltipData["pet"].title}
              description={tooltipData["pet"].description}
            />
          </FormInput>
        )}

        {error && <Alert text={error} />}

        <SaveButton
          loading={deleteAllPetsLoading || hasPetsLoading}
          readyToRoll={isReadyToContinue}
        />
      </form>

      {isAddPetModalOpen && (
        <AddPerson
          personType={petSelected.type}
          append={appendPet}
          reset={reset}
          personSelected={petSelected}
          toggle={toggleAddPetModal}
        />
      )}

      {isDeletePetModalOpen && (
        <DeleteModal
          headerText="Odstranit zvíře"
          toggleDeleteModal={toggleDeletePetModal}
          loading={deletePetLoading}
          personSelected={petSelected}
          sendDeletion={deletePetMutation}
        />
      )}
    </div>
  );
};

export default Pets;
