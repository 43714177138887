import React from "react";
import GoBack from "../../../Components/Buttons/GoBack/GoBack";
import Button from "../../../Components/Buttons/SurveyButton";
import { SEO } from "../../../Components/seo/Seo";
import checkIn from "../../../Images/check.svg";
import close from "../../../Images/nope.svg";
import "./estate.scss";
import Tooltip from "../../../Components/Tooltip/Tooltip";

const Introduction = () => {
  // TODO: Query charities from BE -> save them to redux
  // const {data: charitiesData,  } = useQuery(GET_ALL_CHARITIES)

  // useEffect(() =>  {

  //   if(charitiesData) {
  //     // TODO: SAVE TO REDUX for further use

  //     // TODO:
  //     // - migrate people who already have charity selected to
  //     //   use these database charities based on the slug by which
  //     //   their charity is saved..
  //     //   take care of the FEATURED ones and the not FEATURED ones.
  //     //   filter by existence in DB
  //   }
  // },[charitiesData])

  return (
    <div className="introduction form">
      <SEO page="estate_introduction" />

      <GoBack />

      <h1>Co to znamená “pozůstalost”?</h1>

      <p>
        Pozůstalost tvoří celé jmění zůstavitele – patří sem jak majetek (dům, stavební spoření i osobní věci) tak dluhy (dluh z úvěrové smlouvy i nezaplacená pokuta za parkování). V případě, že vaši dědicové přijmou v řízení o pozůstalosti dědictví, zdědí po vás tedy veskrze vše.
      </p>

      <div className="divBlock">
        <img src={checkIn} alt="checkIn" />

        <h3>Co bude obsahem pozůstalosti:</h3>
      </div>

      <ol>
        <li>Vaše bankovní účty</li>

        <li>Vaše nemovitosti</li>

        <li>Vaše akcie a cenné papíry</li>

        <li>Vaše hotovost</li>

        <li>Vaše dluhy (z úvěrových smluv i pokuty)</li>

        <li>Náklady na pohřeb</li>
      </ol>

      {/* <div>
        <img src={close} alt="close" />
        <h3>Co nebude obsahem pozůstalosti:</h3>
      </div>

      <ol>
        <li>Konkrétní odkazy (dárky), které přidáte později</li>

        <li>Většina životních pojistek a penzijního připojištění</li>
      </ol> */}

      <div className="divBlock">
        <h4>Na co pamatovat?</h4>
      </div>

      <Tooltip
        id="SJM"
        title="SJM"
        customText="Jste ženatý/vdaná?"
        description={["V případě, že jste ženatý/vdaná je potřeba pamatovat na společné jmění manželů, které vzniklo okamžikem uzavření manželství a trvá až do jeho skončení. V případě, že za trvání manželství nedošlo k úpravě společného jmění (např. jeho oddělením nebo zúžením notářským zápisem) platí, že manželé jsou bezpodílovými vlastníky a k věcem, které nabydou za trvání manželství mají stejná práva. V praxi to znamená, že ač jsou bankovní účet nebo auto zakoupené během manželství evidovány fakticky na vás, váš manžel/manželka má nárok na “fiktivní polovinu”. V rámci řízení o pozůstalosti se tedy nejprve vypořádá majetek ve společném jmění manželů a do pozůstalosti je zahrnuta jen zůstavitelova „část“ majetku oddělená tímto vypořádáním (například při zůstavení bankovního účtu s obnosem 1 mil. Kč do pozůstalosti po vypořádání připadne pouze 500.000 Kč)."]}
      />

      <Tooltip
        id="Odkaz"
        title="Odkaz"
        customText="Co to je odkaz?"
        description={[
          "Pokud si přejete osobě zvlášť přenechat určitou věc (např. peníze, šperk nebo obraz) můžete k tomu využít institut odkazu, který je k dispozici v dalším kroku naší aplikace.",
          "Odkazem nařídíte vašim dědicům, aby po skončení pozůstalostního řízení danou věc z pozůstalosti vydali konkrétní osobě. Tato osoba se nestane vaším dědicem (nebude mít např. povinnost hradit dluhy) pouze jí odkazem vznikne nárok na vydání věci vůči vašim dědicům."
        ]}
      />

      <div>
        <h4>Komu mohu svůj majetek přenechat a jak to funguje?</h4>
      </div>

      <p>
        Váš majetek můžete přenechat komu chcete. Dokonce i různým neziskovým organizacím.
        {/* <br />
        <br />
        V případě, že v závěti pořídíte o všem svém majetku, dědickým titulem (právním důvodem) na základě kterého bude Vámi uvedeným osobám svědčit dědické právo bude pouze závěť, nikoliv zákon a nemusíte se tedy bát, že dojde k dědění dle zákonné posloupnosti dle šesti po sobě jdoucích dědických tříd.
        <br />
        <br />
        Pokud ale pořídíte pouze o části svého majetku, zbývající majetek nezmíněný v závěti bude rozdělen dle zákona a připadne vašim zákonným dědicům (jednotlivé dědické třídy viz. článek v poradně).  */}
      </p>
      <Button text="Dále" to="/dedictvi/dedici" />
    </div>
  );
};

export default Introduction;
