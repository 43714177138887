import React, { FC } from "react";

interface InvitePartnerI {
  togglePartnerModal: () => void;
}

export const InvitePartner: FC<InvitePartnerI> = ({ togglePartnerModal }) => {
  return (<>
    <div className="invite-partner">
      <h3>
        Ve dvou ušetříte 1 000 Kč{" "}
        <span role="img" aria-label="Smajlík peněz">
          💸
        </span>
      </h3>
      <p>
        Přizvěte svého partnera k vytvoření vlastní závěti za zvýhodněnou cenu.
      </p>
      <button onClick={() => togglePartnerModal()}>Pojďme na to</button>
    </div>

    {/* <div className="invite-partner annoucement">
      <h3>
        Změna v provozu
      </h3>
      <p>
        Od 23.8. do 1.9. bohužel z interních důvodů nemůžeme manuálně kontrolovat vaše závěti. Všechny závěti odeslané v této době budou zpracovány v následujících pár dnech. Omlouváme se za opoždění.
      </p>
    </div> */}
    </>
  );
};
