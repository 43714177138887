import { gql } from "@apollo/client";

export const INHERIT_ESTATE_MUTATION = gql`
  mutation inheritEstateMutation($inheritEstate: [inheritEstateInput]) {
    inheritEstate(inheritEstate: $inheritEstate) {
      percentageFromAssets
      person
      charity
    }
  }
`;

export const INHERIT_ESTATE_BACKUPS = gql`
  mutation addBackupsMutation($inheritEstate: [inheritEstateInput]) {
    inheritEstate(inheritEstate: $inheritEstate) {
      percentageFromAssets
      person
      charity
      theirKids
      otherInheritors
      backupInheritors {
        person
        percentages
        charity
      }
    }
  }
`;

export const ALLOW_OTHERS_TO_DECIDE = gql`
  mutation allowOthersToDecide($allow: Boolean!) {
    allowInheritorsToDecide(allow: $allow)
  }
`;
