import { compare } from "bcryptjs";
import Cookies from "js-cookie";

export async function compareCookies() {
  try {
    return await compare(
      // @ts-ignore
      process.env.REACT_APP_API_LOGIN_HASH,
      Cookies.get("logged")
    );
  } catch (err) {
    console.log(err);
  }
}

/**
 *
 * @param {Array} children Array of children from redux.
 * @param {Object} partner Partner object from redux.
 * @param {Array} randomPeople Array of all randomPeople from redux.
 * @param {String} omit ID of a person you want to leave out.
 *
 * @return {Array} returns an array with all the people.
 */
export function allPeopleArrayFn(
  children: any[],
  partner: any,
  randomPeople: any[],
  omit?: string
) {
  const childrenWithType = children.map((child: any) => ({
    ...child,
    type: "child",
  }));
  const partnerWithType = { ...partner, type: "partner" };
  const arr = [...childrenWithType, ...randomPeople];
  if (!!partner.fullName) {
    arr.push(partnerWithType);
  }
  if (omit) {
    return arr.filter((person: any) => person._id !== omit);
  } else {
    return arr;
  }
}

/**
 * Returns object of all possible inheritors by Id.
 *
 * @param {Array} children Array of children from redux.
 *
 * @param {Object} partner Partner object from redux.
 *
 * @param {Array} randomPeople Array of all randomPeople from redux.
 *
 * @return {Object} returns object of all people by id
 */
export function allPeopleByIdFn(
  children: any[],
  partner: any,
  randomPeople: any[]
) {
  const arr = [...children, ...randomPeople, partner];
  return arr.reduce((all, curr) => ((all[curr?._id] = curr), all), {});
}

/**
 * Prepares inheritEstate data to be sent to graphQL mutation.
 *
 * @param {Array} inheritorsData Array of Inheritors data.
 *
 * @param {Array} charitiesData Array of Charities data.
 *
 * @param {Object} inheritorsRedux Object of Inheritors from redux state.
 *
 * @param {Object} charitiesRedux Object of Charities from redux state.
 *
 * @return {Array} return value: array of inheritEstate in graphQL schema.
 */
export function prepareInheritEstateData(
  inheritorsData: any[],
  charitiesData: any[],
  inheritorsRedux: any,
  charitiesRedux: any
) {
  const charitiesSerialized = charitiesRedux.map((estate: any) => ({
    ...estate,
    percentageFromAssets: Number(charitiesData[estate.charity]),
  }));
  const inheritorsSerialized = inheritorsRedux.map((estate: any) => ({
    ...estate,
    percentageFromAssets: Number(inheritorsData[estate.person]),
  }));

  return [...charitiesSerialized, ...inheritorsSerialized];
}

export const mailRegex = /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/;

export function preventMoreThan2Decimal(e: any) {
  const { value } = e.target;
  if (value.split(".")[1]?.length > 2) {
    e.target.value = e.target.value.slice(0, -1);
    return;
  }
}

export * from "./functions/birth";
