import React, { FunctionComponent } from "react";

interface AddButtonI {
  onClick: any;
  className: string;
}

export const AddButton: FunctionComponent<AddButtonI> = ({
  onClick,
  className,
  children,
}) => {
  return (
    <button type="button" className={className} onClick={onClick}>
      {children}
    </button>
  );
};
