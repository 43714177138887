import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { SEND_FORGOT_PASSWORD_EMAIL_MUTATION } from "../../../GraphQL/Mutations";
import { useSendMutation } from "../../../hooks";

const SendEmail = () => {
  const [emailSent, setEmailSent] = useState(false);

  const { handleSubmit, errors, register } = useForm();

  const {
    sendMutationRequest: sendForgotPWDMutation,
    loading: forgotPWDLoading,
  } = useSendMutation(SEND_FORGOT_PASSWORD_EMAIL_MUTATION);

  async function onSubmit(data: any, e: any) {
    e.preventDefault();

    const result = await sendForgotPWDMutation({ email: data.email });

    if (result) {
      setEmailSent(true);
    }
  }

  return (
    <>
      {emailSent ? (
        <div className="notFound">
          <h1>Hotovo</h1>

          <p>
            Zkontrolujte prosím svou e-mailovou schránku. Najdete tam odkaz pro
            obnovu vašeho hesla.
          </p>
        </div>
      ) : (
        <div className="loginPage passReset">
          <h1>Zapomenuté heslo</h1>

          <form className="login_form" onSubmit={handleSubmit(onSubmit)}>
            <p>
              Zde zadejte e-mail, pod kterým jste se zaregistrovali a my vám
              obratem zašleme odkaz na obnovení hesla.
            </p>

            <div className="credentialsInput">
              <label>Váš e-mail</label>

              <input
                ref={register({
                  required: {
                    value: true,
                    message: "Vyplňte prosím e-mailovou adresu",
                  },
                })}
                name="email"
                type="email"
                autoComplete="email"
                placeholder="Např.: honza@seznam.cz"
              />
            </div>

            <span className="formError">{errors.email?.message}</span>


            <button type="submit">{!forgotPWDLoading ? "Zaslat e-mail" : "Načítání..."}</button>
          </form>
        </div>
      )}
    </>
  );
};

export default SendEmail;
