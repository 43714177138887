import { gql } from '@apollo/client';

export const POST_GIFT = gql`
  mutation postGiftMutation($gift: gift) {
    newGift(newGift: $gift) {
      _id
      giftType
      gift
      giftMessage
      leaveItTo
      charities
      mortgage
      owner
      propertyInfo1
      propertyInfo2
      propertyInfo3
    }
  }
`;

export const EDIT_GIFT = gql`
  mutation updateGift($gift: gift, $id: ID) {
    updateGift(gift: $gift, _id: $id) {
      gifts {
        _id
        giftType
        gift
        giftMessage
        leaveItTo
        charities
        mortgage
        owner
        propertyInfo1
        propertyInfo2
        propertyInfo3
      }
    }
  }
`;

export const DELETE_GIFT = gql`
  mutation deleteGift($id: ID) {
    deleteGift(_id: $id) {
      gifts {
        _id
        giftType
        gift
        giftMessage
        leaveItTo
        charities
        mortgage
        owner
        propertyInfo1
        propertyInfo2
        propertyInfo3
      }
    }
  }
`;
