export const progressBarReducer = (state: number = 0, action: any) => {
  switch (action.type) {
    case "PLUS_PROGRESS":
      return state + action.payload;
    case "MINUS_PROGRESS":
      return state - action.payload;
    default:
      return state;
  }
};
