import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import GoBack from "../../../../Components/Buttons/GoBack/GoBack";
import SaveButton from "../../../../Components/Buttons/SaveButton";
import { FormInput } from "../../../../Components/Form";
import { SEO } from "../../../../Components/seo/Seo";
import { ADDRESS_MUTATION } from "../../../../GraphQL/Mutations";
import { useSaveResultToRedux, useSendMutation } from "../../../../hooks";
import { aboutYou_address } from "../../../../Redux/actions";

const Address = () => {
  const history = useHistory();

  const { register, errors, handleSubmit, reset, watch } = useForm();

  const saveToRedux = useSaveResultToRedux();
  const { sendMutationRequest, loading } = useSendMutation(ADDRESS_MUTATION);

  const address = useSelector((state: any) => state.aboutYouReducer.address);

  const [readyToRoll, setReadyToRoll] = useState(false);

  const cityWatch = watch("city");
  const streetWatch = watch("street");
  const zipCodeWatch = watch("zipCode");
  const countryWatch = watch("country");
  const phoneNumberWatch = watch("phoneNumber");

  const onSubmit = async (formData: any, e: any) => {
    e.preventDefault();

    const result = await sendMutationRequest(formData);

    saveAndRedirect(result);
  };

  function saveAndRedirect(resultData: any) {
    const result = resultData.address;

    saveToRedux(aboutYou_address, result);

    history.push("/o-vas/partner");
  }

  useEffect(() => {
    if (!cityWatch || !streetWatch || !zipCodeWatch || !phoneNumberWatch) {
      setReadyToRoll(false);
    } else {
      setReadyToRoll(true);
    }
  }, [cityWatch, streetWatch, zipCodeWatch, countryWatch, phoneNumberWatch]);

  useEffect(() => {
    reset(address);
  }, []);

  return (
    <div className="form">
      <SEO page="basics" />

      <GoBack />

      <h1 className="form-title">Jaká je adresa vašeho trvalého bydliště?</h1>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          label="Adresa 1. řádek"
          errors={errors}
          input={{
            type: "text",
            placeholder: "Např.: Černokostelecká 123",
            name: "street",
            register: register({
              required: { value: true, message: "Toto pole je povinné" },
            }),
          }}
        />

        <FormInput
          label="Adresa 2. řádek (nepovinné)"
          errors={errors}
          input={{
            name: "country",
            type: "text",
            placeholder: "",
            register,
          }}
        />

        <FormInput
          label="Město"
          errors={errors}
          input={{
            type: "text",
            placeholder: "Např.: Ostrava",
            name: "city",
            register: register({
              required: { value: true, message: "Toto pole je povinné" },
            }),
          }}
        />

        <FormInput
          label="PSČ"
          errors={errors}
          input={{
            name: "zipCode",
            type: "text",
            placeholder: "Např.: 131 00",
            register: register({
              required: { value: true, message: "Toto pole je povinné" },
            }),
          }}
        />

        <FormInput
          errors={errors}
          title="Vaše telefonní číslo"
          description={[
            "Telefonovat Vám budeme pouze v případě, pokud budete potřebovat pomoci s vaší závětí.",
          ]}
          label="Telefonní číslo"
          input={{
            placeholder: "Např.: 123 456 789",
            type: "text",
            name: "phoneNumber",
            register: register({
              required: { value: true, message: "Toto pole je povinné" },
            }),
          }}
        />

        <SaveButton readyToRoll={readyToRoll} loading={loading} />
      </form>
    </div>
  );
};

export default Address;
