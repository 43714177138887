import React from "react";
import { useHistory } from "react-router-dom";
import IlustaceJumbo from "../../Images/ilustraceJumbo.png";
import IlustaceJumbo2 from "../../Images/ilustraceJumbo2.png";
import Telefon from "../../Images/phone-call.svg";
import SupportPic from "../../Images/podporaPicHeader.png";
import Background from "../../Images/yellowBackground.svg";

import Tuzka from "../../Images/tuzka.svg";
import Ucet from "../../Images/ucet.svg";
import Lupa from "../../Images/lupa.svg";
import Podpis from "../../Images/podpis.svg";

import "./Introduction.scss";
import { redirectTo } from "../../Helpers/routing";
import {
  CUSTOMER_PHONE_NUMBER,
  CUSTOMER_PHONE_NUMBER_DISPLAY,
} from "../../lib/constants";

const Introduction = () => {
  const history = useHistory();

  function handleContinue() {
    redirectTo("/prehled", history);
  }

  return (
    <div className="wills">
      <div className="willsInner">
        <div className="left">
          <img className="topImg" src={IlustaceJumbo} alt="Závěť" />
          <h1>Ještě než začnete…</h1>
          <p className="perex">
            Ještě, než se vrhnete do tvorby své závěti, rádi bychom vám vysvětlili, jak celý proces vůbec funguje, a co vše musíte udělat pro to, aby vaše závěť splňovala všechny náležitosti a byla tak platná.
          </p>

          <div className="bod">
            <img src={Tuzka} alt="Tuzka" />
            <div>
              <h2>1. Závěť si nejprve v aplikaci sami vyplníte</h2>
              <p>
                Hned po kliknutí na tlačítko “Začít” se dostanete do aplikace. V
                ní potom máte možnost si celou závěť navolit. Pokud byste se
                potřebovali s něčím poradit, zdarma můžete zavolat na naši
                zákaznickou linku, kde vám vše ochotně vysvětlíme.
              </p>
            </div>
          </div>

          <div className="bod">
            <img src={Ucet} alt="Ucet" />
            <div>
              <h2>2. Platba</h2>
              <p>
                Jakmile budete mít vše vyplněno - závěť si u nás můžete
                objednat. Standartní cena je 2190 Kč (žádné skryté poplatky).
                Ihned po přijetí platby se potom začínáme vašemu dokumentu
                idividuálně věnovat.
              </p>
            </div>
          </div>

          <div className="bod">
            <img src={Lupa} alt="Lupa" />
            <div>
              <h2>3. Kontrola a finální podoba závěti</h2>
              <p>
                Manuálně zkontrolujeme, zdali nejsou ve vaší závěti žádné chyby, překlepy a nemůžou tak v budoucnu nastat žádné komplikace. Pokud na něco přijdeme, okamžitě vás kontaktujeme. Dokument potom sepíšeme a do 48 hodin Vám ho pošleme na email.
              </p>
            </div>
          </div>

          <div className="bod">
            <img src={Podpis} alt="Podpis" />
            <div>
              <h2>4. Vytisknout, podepsat a uschovat</h2>
              <p>
                Jako poslední krok musíte již opravený dokument vytisknout a vlastnoručně podepsat před dvěma svědky. Vše je jednoduše popsané v návodu, který Vám pošleme společně se závětí. Hned po podepsání je proces dokončen a závěť se stává platným dědickým titulem.<br/><br/>Každému našemu zákazníkovi navíc zakládáme přístup do digitálního archivu. Společně s digitální konverzí vaší závěti (přes Czechpoint) si do něj můžete nahrát všechny vaše důležíté dokumenty, instrukce nebo hesla. Po vaší smrti se všechny soubory automaticky pošlou lidem které v aplikaci vyberete. 
              </p>
            </div>
          </div>

          <div className="podporaBox podporaBox1">
            <h2>Máte nějaké otázky?</h2>
            <img className="supportPic" src={SupportPic} alt="Naše podpora" />
            <div className="text">
              <div className="allDay">
                <div className="greenDot" />
                <span>K dispozici 24/7</span>
              </div>
              <div className="phone">
                <img src={Telefon} alt="Telefon" />
                <a href={`tel:00420${CUSTOMER_PHONE_NUMBER}`}>
                  <span>{CUSTOMER_PHONE_NUMBER_DISPLAY}</span> — Pomocná linka
                </a>
              </div>
            </div>
          </div>

          <button onClick={() => handleContinue()} className="button">
            Začít
          </button>
        </div>
        <div className="right">
          <img className="mainImg" src={IlustaceJumbo} alt="Závěť" />
          <div>
            <div className="podporaBox podporaBox2">
              <h2>Máte nějaké otázky?</h2>
              <img className="supportPic" src={SupportPic} alt="Naše podpora" />
              <div className="text">
                <div className="allDay">
                  <div className="greenDot" />
                  <span>K dispozici 24/7</span>
                </div>
                <div className="phone">
                  <img src={Telefon} alt="Telefon" />
                  <a href={`tel:00420${CUSTOMER_PHONE_NUMBER}`}>
                    <span>{CUSTOMER_PHONE_NUMBER_DISPLAY}</span> — Pomocná linka
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src={Background} className="background" alt="" />
      </div>
    </div>
  );
};

export default Introduction;
