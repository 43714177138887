export const snackbarReducer = (
  state: { message: string; isVisible: boolean } = {
    message: "",
    isVisible: false,
  },
  action: any
) => {
  switch (action.type) {
    case "ADD_SNACKBAR_MESSAGE":
      return action.payload;
    case "REMOVE_SNACKBAR_MESSAGE":
      return { message: "", isVisible: false };
    default:
      return state;
  }
};
