import { useLazyQuery, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingScreen from "../../../Components/LoadingScreen/Loading";
import { OrderStatusStatus } from "../../../Components/PaymentComponents/OrderStatus/OrderStatus";
import { FRIENDS_CODE_MUTATION } from "../../../GraphQL/Mutations";
import {
  GET_CODE_QUERY,
  GET_ORDER_STATUS,
  GET_PAID_FOR_WILL,
} from "../../../GraphQL/Queries";
import { useSendMutation } from "../../../hooks";
import { setPaidForWill } from "../../../Redux/actions";
import Finished from "./Finished";
import NotPaid from "./NotPaid";
import "./Payment.scss";
import Processing from "./Processing";

const Payment = () => {
  const dispatch = useDispatch();

  const [getPaidForWillQuery, { data: getPaidForWillData }] = useLazyQuery(
    GET_PAID_FOR_WILL,
    {
      fetchPolicy: "network-only",
    }
  );

  const {
    loading: orderStatusLoading,
    data: orderStatusData,
    refetch: orderStatusRefetch,
  } = useQuery(GET_ORDER_STATUS, { fetchPolicy: "cache-and-network" });

  const { loading: friendsCodeQueryLoading, data: friendsCodeQueryData } =
    useQuery(GET_CODE_QUERY, {
      variables: { type: "friends" },
      fetchPolicy: "cache-and-network",
    });

  const {
    sendMutationRequest: sendFriendsCodeMutation,
    loading: friendsCodeLoading,
  } = useSendMutation(FRIENDS_CODE_MUTATION);

  const [friendsCode, setFriendsCode] = useState("");

  const paidForWillRedux = useSelector(
    (state: any) => state.paymentReducer.paidForWill
  );

  const loading =
    orderStatusLoading || friendsCodeQueryLoading || friendsCodeLoading;

  async function createFriendsCode() {
    try {
      const { createRefferalCode } = (await sendFriendsCodeMutation()) ?? {};

      setFriendsCode(createRefferalCode);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (friendsCodeQueryData) {
      setFriendsCode(friendsCodeQueryData.getMyCode);
    }
  }, [friendsCodeQueryData]);

  useEffect(() => {
    if (getPaidForWillData) {
      dispatch(setPaidForWill(getPaidForWillData.paidForWill));
    }
  }, [getPaidForWillData]);

  useEffect(() => {
    orderStatusRefetch();
  }, [paidForWillRedux]);

  const pagesByStatus: { [key in OrderStatusStatus]: any } = {
    not_paid: (
      <NotPaid
        createFriendsCodeLoading={loading}
        createFriendsCode={createFriendsCode}
        friendsCode={friendsCode}
        refetchPaidForWill={getPaidForWillQuery}
      />
    ),
    paid: (
      <Processing
        createFriendsCodeLoading={loading}
        createFriendsCode={createFriendsCode}
        friendsCode={friendsCode}
        orderStatus={orderStatusData?.orderStatus}
        orderStatusLoading={false}
      />
    ),
    processing: (
      <Processing
        createFriendsCodeLoading={loading}
        createFriendsCode={createFriendsCode}
        friendsCode={friendsCode}
        orderStatus={orderStatusData?.orderStatus}
        orderStatusLoading={false}
      />
    ),
    finished: (
      <Finished
        friendsCode={friendsCode}
        createFriendsCode={createFriendsCode}
        createFriendsCodeLoading={loading}
      />
    ),
    // @ts-ignore
    null: <LoadingScreen />,
  };

  if (loading) {
    return <LoadingScreen />;
  } else {
    // @ts-ignore
    return pagesByStatus[orderStatusData.orderStatus];
  }
};

export default Payment;
