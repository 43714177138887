export const addSnackbarMessage = (snackbar: {
  message: string;
  isVisible: boolean;
}) => ({
  type: "ADD_SNACKBAR_MESSAGE",
  payload: snackbar,
});

export const removeSnackbarMessage = () => ({
  type: "REMOVE_SNACKBAR_MESSAGE",
});
