import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import GoBack from "../../../../Components/Buttons/GoBack/GoBack";
import SaveButton from "../../../../Components/Buttons/SaveButton";
import { AddButton, Alert, FormInput } from "../../../../Components/Form";
import AddPerson from "../../../../Components/PersonBox/AddPerson";
import PersonBox, {
  NonDefaultCharityBox,
} from "../../../../Components/PersonBox/PersonBox";
import { POST_GIFT } from "../../../../GraphQL/Mutations";
import { allPeopleArrayFn } from "../../../../Helpers";
import { useModal, useSaveResultToRedux, useSendMutation } from "../../../../hooks";
import { getGifts, initialPerson } from "../../../../Redux/actions";
import AddCharity from "../../Estate/AddCharity";
import CharitiesList from "../../Estate/CharitiesList";
import { prepareGiftData } from "../giftsHelpers";

interface MoneyPropsI {
  editingGift: any;
}

const Money = (props: MoneyPropsI) => {
  const history = useHistory();

  const saveToRedux = useSaveResultToRedux();

  const { register, errors, handleSubmit, reset, getValues } = useForm();

  const { sendMutationRequest: sendPostGiftMutation, loading: postGiftLoading } =
    useSendMutation(POST_GIFT);
  const { sendMutationRequest: sendEditGiftMutation, loading: editGiftLoading } =
    useSendMutation(POST_GIFT);

  const defaultCharitiesById = _.keyBy(CharitiesList, "_id");

  const {
    aboutYouReducer: {
      children,
      marital_status: { partner },
    },
    inheritEstateReducer: { charities },
    randomPeopleReducer: randomPeople,
  } = useSelector((state: any) => state);

  const existingNonDefaultCharities = charities
    .map((charity: any) => charity.charity)
    .filter((charity: string) => !Object.keys(defaultCharitiesById).includes(charity));

  const editingGift = !!props?.editingGift?._id;

  const allPeopleArr = allPeopleArrayFn(children, partner, randomPeople);

  const [error, setError] = useState("");

  const [selectedCharities, setSelectedCharities] = useState<string[]>([]);

  const [isAddCharityModalOpen, toggleCharityModal] = useModal();
  const [isAddPersonModalOpen, toggleAddPersonModal] = useModal();

  const [personSelected, setPersonSelected] = useState<any>();

  const [nonDefaultCharities, setNonDefaultCharities] = useState<any[]>([]);

  const [editingCharity, setEditingCharity] = useState({ charityName: "" });

  async function onSubmit(data: any, e: any) {
    setError("");

    e.preventDefault();
    if (!data.leaveItTo.length && !selectedCharities.length) {
      setError(
        "Zvolte alespoň jednu osobu či neziskovou organizaci pro zanechání odkazu"
      );
      return;
    }

    let charitiesData = [...selectedCharities];

    if (data.nonDefaultCharities && Array.isArray(data.nonDefaultCharities)) {
      charitiesData.push(...data.nonDefaultCharities);
    } else if (data.nonDefaultCharities && !Array.isArray(data.nonDefaultCharities)) {
      charitiesData.push(data.nonDefaultCharities);
    }

    if (editingGift) {
      const { updateGift } =
        (await sendEditGiftMutation({
          gift: prepareGiftData(data, "money", charitiesData),
          id: props.editingGift._id,
        })) ?? {};

      saveToRedux(getGifts, updateGift.gifts);
    } else {
      const { newGift } =
        (await sendPostGiftMutation({
          gift: prepareGiftData(data, "money", charitiesData),
        })) ?? {};

      saveToRedux(getGifts, newGift);
    }

    history.push("/odkazy");
  }

  function addNewPerson() {
    setPersonSelected({ ...initialPerson, type: "default" });

    toggleAddPersonModal();
  }

  function addNewCharity() {
    setEditingCharity({ charityName: "" });

    toggleCharityModal();
  }

  function selectCharity(e: any, id: string) {
    e.preventDefault();

    setSelectedCharities((prevState) =>
      selectedCharities.includes(id)
        ? prevState.filter((ids) => ids !== id)
        : [...prevState, id]
    );
  }

  function markNewlyCreatedPersonAsAdded(id: string) {
    reset({ ...getValues(), leaveItTo: [...getValues("leaveItTo"), id] });
  }

  useEffect(() => {
    if (props?.editingGift?._id) {
      setSelectedCharities(() =>
        props.editingGift.charities.filter((charity: string) =>
          Object.keys(defaultCharitiesById).includes(charity)
        )
      );
      const allNonDefaultCharities = [
        ...new Set([
          ...existingNonDefaultCharities,
          ...props.editingGift.charities.filter(
            (charity: string) => !Object.keys(defaultCharitiesById).includes(charity)
          ),
        ]),
      ];
      setNonDefaultCharities(allNonDefaultCharities);
      reset({
        ...props.editingGift,
        nonDefaultCharities: props.editingGift.charities,
      });
    } else {
      setNonDefaultCharities(() =>
        charities
          .map((charity: any) => charity.charity)
          .filter(
            (charity: string) => !Object.keys(defaultCharitiesById).includes(charity)
          )
      );
    }
  }, []);

  return (
    <div className="form form-gift_type">
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          title="Odkázat peníze"
          description={[
            "Zadejte prosím částku, jakou chcete odkázat, včetně měny. Například “1000 Kč”. Pro každý odkaz formou peněz můžete vybrat pouze jednu osobu nebo neziskovou organizaci.",
          ]}
          errors={errors}
          input={{
            name: "gift",
            type: "text",
            register: register({
              required: { value: true, message: "Toto pole je povinné" },
            }),
            placeholder: "Např.: 1000 Kč",
          }}
          label="Jakou částku chcete odkázat?"
        />

        <h3>Komu chcete tuto částku odkázat?</h3>

        {allPeopleArr.map((person: any, idx: number) => (
          <PersonBox
            key={idx}
            id={person.id}
            name={`leaveItTo`}
            person={person}
            personName={person.fullName}
            description={moment(person.birth, "YYYY-MM-DD").format("DD.MM.YYYY")}
            checkbox={true}
            value={person._id}
            register={register}
          />
        ))}

        <AddButton className="partner_add" onClick={addNewPerson}>
          Přidat osobu
        </AddButton>

        <h3>Přidat neziskovou organizaci</h3>
        <>
          <div className="charities-list">
            <div className="charities-list-left">
              {CharitiesList.slice(0, 3).map((el: any, index: number) => (
                <div
                  onClick={(e) => selectCharity(e, el._id)}
                  className={`charities-box ${
                    selectedCharities.includes(el._id) ? "selected" : ""
                  }`}
                  key={index}
                >
                  <div className="helper"></div>
                  <img style={el.style} alt={el.name} src={el.img} />
                </div>
              ))}
            </div>
            <div className="charities-list-right">
              {CharitiesList.slice(3).map((el: any, index: number) => (
                <div
                  onClick={(e) => selectCharity(e, el._id)}
                  className={`charities-box ${
                    selectedCharities.includes(el._id) ? "selected" : ""
                  }`}
                  key={index}
                >
                  <div className="helper"></div>
                  <img style={el.style} alt={el.name} src={el.img} />
                </div>
              ))}
            </div>
          </div>

          {nonDefaultCharities.map((charity, index) => (
            <NonDefaultCharityBox
              key={index}
              charityName={charity.charity || charity}
              name={"nonDefaultCharities"}
              value={charity.charity || charity}
              checkbox={true}
              register={register}
            />
          ))}

          <AddButton className="partner_add" onClick={addNewCharity}>
            + Přidat vlastní neziskovou organizaci
          </AddButton>
        </>

        {error && <Alert text={error} />}

        <FormInput
          title="Zanechte osobní zprávu (volitelné)"
          description={[
            "Napište co a proč to zanecháváte. Můžete jim také poradit, jak s danou věcí naložit. Co byste chtěl/a aby s ní udělal/la/li?",
          ]}
          errors={errors}
          label="Osobní zpráva"
        >
          <textarea
            ref={register}
            name="giftMessage"
            cols={58}
            placeholder="Sem vložte vaší zprávu"
          />
        </FormInput>

        <SaveButton loading={postGiftLoading || editGiftLoading} readyToRoll={true} />
      </form>

      {isAddCharityModalOpen && (
        <AddCharity
          toggle={toggleCharityModal}
          editingCharity={editingCharity}
          nonDefaultCharities={nonDefaultCharities}
          setNonDefaultCharities={setNonDefaultCharities}
        />
      )}

      {isAddPersonModalOpen && (
        <AddPerson
          toggle={toggleAddPersonModal}
          personType={personSelected.type}
          personSelected={personSelected}
          append={() => {}}
          callback={markNewlyCreatedPersonAsAdded}
        />
      )}
    </div>
  );
};

export default Money;
