import React from "react";
import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import _ from "lodash";

import Introduction from "./Introduction";
import People from "./People";
import Share from "./Share";
import Charities from "./Charities";
import BackupInheritor from "./BackupInheritor";
import BackupInheritorShare from "./BackupInheritorShare";
import NotFoundPage from "../../Other/404/NotFound";
import AllowOthersToDecide from "./AllowOthersToDecide";

const Index: any = () => {
  const inheritEstateRedux = useSelector(
    (state: any) => state.inheritEstateReducer
  );
  const inheritorsIDs = inheritEstateRedux.inheritors.map(
    (inheritor: any) => inheritor.person
  );
  const inheritorsById = _.keyBy(inheritEstateRedux.inheritors, "person");

  return (
    <Switch>
      <Route path="/dedictvi/uvod" component={Introduction} />

      <Route path="/dedictvi/dedici" component={People} />

      <Route path="/dedictvi/charity" component={Charities} />

      <Route path="/dedictvi/rozdeleni" component={Share} />

      {inheritorsIDs.length &&
        inheritorsIDs.map((id: string, index: number) => (
          <Route
            path={`/dedictvi/nahradnik/${id}`}
            key={index}
            exact={true}
            render={(props) => (
              <BackupInheritor
                personID={id}
                redirectTo={`/dedictvi/nahradnik/${inheritorsIDs[index]}/cast`}
                redirectToNoShare={
                  index < inheritorsIDs.length - 1
                    ? `/dedictvi/nahradnik/${inheritorsIDs[index + 1]}`
                    : "/dedictvi/rozhodnuti"
                }
                key={index}
                {...props}
              />
            )}
          />
        ))}

      {inheritorsIDs.length &&
        inheritorsIDs.map((id: string, index: number) => (
          <Route
            path={`/dedictvi/nahradnik/${id}/cast`}
            key={index}
            exact={true}
            render={(props) => (
              <BackupInheritorShare
                personID={id}
                backupCharities={inheritorsById[id].backupInheritors.filter(
                  (el: any) => el.charity
                )}
                backupInheritors={inheritorsById[id].backupInheritors.filter(
                  (el: any) => el.person
                )}
                redirectTo={
                  index < inheritorsIDs.length - 1
                    ? `/dedictvi/nahradnik/${inheritorsIDs[index + 1]}`
                    : "/dedictvi/rozhodnuti"
                }
                key={index}
                {...props}
              />
            )}
          />
        ))}

      <Route path="/dedictvi/rozhodnuti" component={AllowOthersToDecide} />

      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  );
};

export default Index;
