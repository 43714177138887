import React, { FC } from "react";
import { useSelector } from "react-redux";
import { allPeopleArrayFn, allPeopleByIdFn } from "../../Helpers";
import { useStepsCompleted } from "../../hooks";
import {
  getAboutYouContent,
  getAssetsContent,
  getChildrenContent,
  getEstateContent,
  getPetsContent,
} from "../../Pages/Summary/SummaryFn";
import Step from "./step/Step";

interface RequiredStepsI {}

export const RequiredSteps: FC<RequiredStepsI> = (props) => {
  const {
    aboutYouReducer: aboutYou,
    assetsReducer: assets,
    inheritEstateReducer: inheritEstate,
    randomPeopleReducer: randomPeople,
  } = useSelector((state: any) => state);

  const pets = aboutYou.pets;

  const childrenUnder18 = aboutYou.children.filter((el: any) => !el.over18);

  const stepsCompleted = useStepsCompleted();

  const allPeopleById = allPeopleByIdFn(
    aboutYou.children,
    aboutYou.marital_status.partner,
    randomPeople
  );
  const allPeople = allPeopleArrayFn(
    aboutYou.children,
    aboutYou.marital_status.partner,
    randomPeople
  );

  const isAboutYouStepCompleted = stepsCompleted.aboutYou;

  const hasChildrenUnder18AndIsPreviousSectionCompleted =
    childrenUnder18.length > 0 && isAboutYouStepCompleted;

  const hasPetsAndIsPreviousSectionCompleted = pets.length > 0 && isAboutYouStepCompleted;

  return (
    <>
      <Step
        stepN="Krok č.1"
        title="Vy a vaše rodina"
        redirectTo="/o-vas/jmeno"
        content={getAboutYouContent(aboutYou)}
        isCompleted={stepsCompleted.aboutYou}
        canBeEdited={true}
      />

      {!isAboutYouStepCompleted && (
        <Step
          stepN="Krok č.2"
          title="Opatrovnictví a poručenství"
          content={getChildrenContent(childrenUnder18, allPeople, pets)}
          isCompleted={false}
          redirectTo="placeholder"
          canBeEdited={false}
        />
      )}

      {hasChildrenUnder18AndIsPreviousSectionCompleted ? (
        <Step
          stepN="Krok č.2"
          title="Opatrovnictví a poručenství"
          content={getChildrenContent(childrenUnder18, allPeople, pets)}
          isCompleted={stepsCompleted.guardians}
          redirectTo={`/opatrovnictvi/${childrenUnder18[0]._id}/zvolit`}
          canBeEdited={true}
        />
      ) : (
        hasPetsAndIsPreviousSectionCompleted && (
          <Step
            stepN="Krok č.2"
            title="Opatrovnictví a poručenství"
            canBeEdited={true}
            isCompleted={stepsCompleted.guardians}
            content={getPetsContent(pets, allPeople)}
            redirectTo={`/opatrovnictvi/${pets[0]._id}/zvolit`}
          />
        )
      )}

      <Step
        stepN={
          // Changes based on existence of the guardian step
          childrenUnder18.length || pets.length || !stepsCompleted.aboutYou
            ? "Krok č.3"
            : "Krok č.2"
        }
        content={getAssetsContent(assets)}
        title="Váš majetek"
        canBeEdited={stepsCompleted.guardians}
        isCompleted={stepsCompleted.assetsProperty}
        redirectTo="/majetek"
      />

      <Step
        stepN={
          !stepsCompleted.aboutYou || childrenUnder18.length || pets.length
            ? "Krok č.4"
            : "Krok č.3"
        }
        title="Rozdělení pozůstalosti"
        canBeEdited={stepsCompleted.assetsProperty}
        content={getEstateContent(inheritEstate, allPeopleById)}
        isCompleted={stepsCompleted.estate}
        redirectTo="/dedictvi/uvod"
      />
    </>
  );
};
