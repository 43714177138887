export const tooltipData: { [key: string]: any } = {
  partner: {
    id: "partner",
    title: "Partner",
    description: [
      "V případě, že chcete, aby váš partner něco dědil, je potřeba to do závěti napsat i s partnerovými údaji.",
      "Naopak pokud chcete, aby partner nedědil nic, je potřeba o tom mít písemný záznam - tzv. negativní závěť.",
    ],
  },
  pet: {
    id: "pet",
    title: "Domácí zvíře",
    description: [
      "V závěti máte možnost určit, kdo se bude o vaše domácí mazlíčky starat po vaší smrti.",
      "Můžete si tak být jistí, že i nadále budou v dobrých rukách.",
    ],
  },
  child: {
    id: "child",
    title: "Dítě",
    description: [
      "Vaše děti jsou ve vztahu k Vám nepominutelnými dědici s nárokem na povinný díl. Nedědí-li některé z nich, povinný díl pak náleží jejich potomkům. Zletilému dítěti náleží min. ¼, nezletilému dítěti min. ¾ z jeho zákonného podílu (tedy z podílu, na který by měl potomek nárok v případě, kdy by žádná závěť neexistovala a postupovalo by se dle zákonné dědické posloupnosti). I v případě, že své děti v závěti nezmíníte a odpovídající díl majetku jim přímo nezůstavíte, budou mít právo svůj povinný díl (v podobě odpovídající finanční částky) nárokovat vůči vašim dědicům.",
      "Pokud byste uvažovali, že vaše děti z práva na povinný díl vyloučíte (vydědíte je), doporučujeme se obrátit na notáře. Vydědění je totiž možné pouze z 5 výslovně uvedených důvodů, u kterých je potřeba posoudit jejich naplnění.",
    ],
  },
};
