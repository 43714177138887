import React from "react";

import AboutYou from "./AboutYou";
import Guardians from "./Guardians/Guardians";
import Estate from "./Estate";
import Gifts from "./Gifts";

import "./Steps.scss";
import { Route, Switch } from "react-router-dom";
import AssetsPropertyPage from "./AssetsProperty/AssetsPropertyPage";
import RestingPlace from "./Wishes/RestingPlace";
import NotFoundPage from "../Other/404/NotFound";

const Steps = () => {
  return (
    <Switch>
      <Route path="/o-vas" render={(props) => <AboutYou {...props} />} />

      <Route
        path="/opatrovnictvi"
        render={(props) => <Guardians {...props} />}
      />

      <Route path="/majetek" component={AssetsPropertyPage} />

      <Route path="/dedictvi" render={(props) => <Estate {...props} />} />

      <Route path="/odkazy" render={(props) => <Gifts {...props} />} />

      <Route path="/posledni-prani" component={RestingPlace} />

      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  );
};

export default Steps;
