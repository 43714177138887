import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Alert } from "../../Components/Form";
import { PARTNER_CODE_MUTATION } from "../../GraphQL/Mutations";
import { GET_CODE_QUERY } from "../../GraphQL/Queries";
import { mailRegex } from "../../Helpers";
import { useSendMutation } from "../../hooks";
import SaveButton from "../Buttons/SaveButton";
import Modal from "./Modal";
import "./PartnerModal.scss";

interface PartnerModalPropsI {
  toggle: any;
}

const PartnerModal = (props: PartnerModalPropsI) => {
  const { register, handleSubmit, errors } = useForm();
  const [code, setCode] = useState("");

  const { data: codeData, loading: codeLoading } = useQuery(GET_CODE_QUERY, {
    variables: { type: "partner" },
    // checks whether server data modifies cached data, if so => updates
    fetchPolicy: "cache-and-network",
  });

  const {
    sendMutationRequest: sendPartnerCodeMutation,
    loading: partnerCodeLoading,
    error: partnerCodeError,
  } = useSendMutation(PARTNER_CODE_MUTATION);

  async function onSubmit(data: any, e: any) {
    e.preventDefault();

    const { createRefferalCode } =
      (await sendPartnerCodeMutation({ email: data.email })) ?? {};

    if (createRefferalCode) {
      setCode(createRefferalCode);
    }
  }

  useEffect(() => {
    if (codeData) {
      setCode(codeData.getMyCode);
    }
  }, [codeData]);

  return (
    <Modal header="Partnerský kód" toggle={props.toggle}>
      <div className="partner-modal-content">
        <h4 className="ref_code-title">
          Získejte slevový kód pro vašeho partnera
        </h4>

        <p className="ref_code-text">
          Abyste mohli získat slevový kód, zadejte e-mailovou adresu vašeho
          partnera, pod kterou je registrován nebo tu, pod kterou se teprve
          zaregistruje.
        </p>

        <p className="ref_code-text">
          Získáte od nás kód, který bude moct váš partner následně použít a
          získat slevu 1000 Kč
        </p>

        {!code && !codeLoading ? (
          <form className="partner_code-form" onSubmit={handleSubmit(onSubmit)}>
            <label>Zde zadejte e-mail vašeho partnera:</label>

            <input
              className="input_section-input"
              placeholder="Např.: jannovak@seznam.cz"
              name="email"
              ref={register({
                required: true,
                validate: (v) => mailRegex.test(v),
              })}
            />

            {errors?.email?.type === "validate" && (
              <Alert text="Zadejte platnou e-mailovou adresu" />
            )}

            {partnerCodeError && <Alert text={partnerCodeError.message} />}

            <SaveButton
              loading={partnerCodeLoading}
              readyToRoll={true}
              buttonText="Získat kód"
              loadingText="Načítání..."
            />
          </form>
        ) : (
          <div className="ref_code-generated">
            <p>Slevový kód pro vašeho partnera:</p>

            <b>{code}</b>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PartnerModal;
