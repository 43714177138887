import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import GoBack from "../../../Components/Buttons/GoBack/GoBack";
import SaveButton from "../../../Components/Buttons/SaveButton";
import { Alert, FormButtons, FormInput } from "../../../Components/Form";
import { SEO } from "../../../Components/seo/Seo";
import { UPDATE_CHILD_MUTATION } from "../../../GraphQL/Mutations";
import {
  useIsReadyToContinue,
  useSaveResultToRedux,
  useSendMutation,
} from "../../../hooks";
import { aboutYou_children } from "../../../Redux/actions";

interface ResponsibilityI {
  subjectID: string;
  type: string;
  name: string;
  nextStep: string;
}

const Responsibility = (props: ResponsibilityI) => {
  const { name, nextStep, subjectID } = props;

  const history = useHistory();

  const [hasResponsibility, setHasResponsibility] = useState<{
    [key: string]: number;
  }>({});

  const { sendMutationRequest: sendEditChildMutation, loading } =
    useSendMutation(UPDATE_CHILD_MUTATION);

  const { handleSubmit, errors, setError, clearErrors } = useForm();

  const {
    aboutYouReducer: {
      marital_status: { partner },
      children,
    },
    randomPeopleReducer: randomPeople,
  } = useSelector((state: any) => state);

  const thisChild = children.find((el: any) => el._id === subjectID);

  const thisChildGuardians = [...randomPeople, ...children, partner].filter(
    (person: any) => thisChild.guardian.includes(person._id)
  );

  const saveToRedux = useSaveResultToRedux();

  const isReadyToContinue = useIsReadyToContinue({
    conditions: [
      thisChildGuardians.length === Object.values(hasResponsibility).length,
    ],
  });

  const onSubmit = async (formData: any, e: any) => {
    e.preventDefault();

    const selectedGuardians = Object.keys(hasResponsibility);

    const guardiansWithoutResp = Object.values(hasResponsibility).filter(
      (value: any) => value === 2
    );

    if (selectedGuardians.length > 0 && guardiansWithoutResp.length === 0) {
      setError("manual", {
        message:
          "Vraťte se a zvolte prosím poručníka, který nemá rodičovskou odpovědnost nad " +
          name,
        type: "required",
      });

      return;
    }

    const constructedGuardians = [];

    for (const property in hasResponsibility) {
      if (hasResponsibility[property] === 2) {
        constructedGuardians.push(property);
      }
    }

    const { updateChild } =
      (await sendEditChildMutation({
        id: subjectID,
        fullName: thisChild.fullName,
        birth: thisChild.birth,
        city: thisChild.address?.city,
        street: thisChild.address?.street,
        over18: thisChild.over18,
        guardian: constructedGuardians,
      })) ?? {};

    saveToRedux(aboutYou_children, updateChild.children);

    history.push(nextStep);
  };

  const handleClick = (e: any) => {
    const { name: buttonName, value: buttonValue } = e.target;

    clearErrors();

    setHasResponsibility((prevState) => ({
      ...prevState,
      [buttonValue]: buttonName === "yes" ? 1 : 2,
    }));
  };

  useEffect(() => {
    if (!thisChildGuardians.length) {
      history.push("/prehled");
    }
  }, []);

  return (
    <div className="form">
      <SEO page="guardians_responsibility" />

      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)}>
        {thisChildGuardians.map((el: any, index) => (
          <FormInput
            key={index}
            title={`Má ${el.fullName} rodičovskou odpovědnost za ${name}?`}
            description={[
              "Rodičovskou odpovědnost mají oba rodiče (matka i otec) nehledě na to, jakým způsobem jejich rodičovství vzniklo. Není podstatné, zda jsou rodiče manželé, nebo žijí jako nesezdaný pár, či zda došlo k určení otcovství souhlasným prohlášením nebo osvojením.",
            ]}
          >
            <FormButtons
              onClick={handleClick}
              buttons={[
                {
                  name: "yes",
                  value: el._id,
                  className: "single_button_section first_button",
                  activeClassName: "pressed",
                  active: hasResponsibility[el._id] === 1,
                  text: "Ano",
                },
                {
                  name: "no",
                  value: el._id,
                  className: "single_button_section",
                  activeClassName: "pressed",
                  active: hasResponsibility[el._id] === 2,
                  text: "Ne",
                },
              ]}
            />
          </FormInput>
        ))}

        {errors?.manual && <Alert text={errors.manual.message} />}

        <SaveButton loading={loading} readyToRoll={isReadyToContinue} />
      </form>
    </div>
  );
};

export default Responsibility;
