export function getDefaultShareValues(inheritors: any[], charities: any[]) {
  const inheritorsAndCharitiesLength = inheritors.length + charities.length;
  const all = [...inheritors, ...charities];
  const percentages = all.map((i) => i.percentageFromAssets);
  const isFirstTimeSettingPercentages = percentages.every((v) => v === 0);

  let equalValue;
  if (inheritorsAndCharitiesLength === 3) {
    equalValue = 33.33;
  }

  if (inheritorsAndCharitiesLength !== 3) {
    equalValue = 100 / inheritorsAndCharitiesLength;
  }

  return {
    inheritors: inheritorsArrToObject(
      inheritors,
      isFirstTimeSettingPercentages,
      equalValue
    ),
    charities: charitiesArrToObject(charities, isFirstTimeSettingPercentages, equalValue),
  };
}

export function inheritorsArrToObject(
  inheritors: any[],
  isFirstTime: boolean,
  equalValue?: number
) {
  const reducedArray = inheritors.reduce((acc, currentInheritor) => {
    if (!isFirstTime) {
      acc[currentInheritor.person] = currentInheritor.percentageFromAssets.toString();
      return acc;
    }

    acc[currentInheritor.person] = equalValue?.toString();
    return acc;
  }, {});

  return reducedArray;
}

export function charitiesArrToObject(
  charities: any[],
  isFirstTime: boolean,
  equalValue?: number
) {
  const reducedArray = charities.reduce((acc, currentCharity) => {
    if (!isFirstTime) {
      acc[`"${currentCharity.charity}"`] = currentCharity.percentageFromAssets.toString();
      return acc;
    }

    acc[`"${currentCharity.charity}"`] = equalValue?.toString();
    return acc;
  }, {});
  return reducedArray;
}
