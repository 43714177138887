export const addToProgress = (number: number) => {
  return {
    type: 'ADD_TO_PROGRESS',
    payload: number
  };
};

export const willIsLoading = (loading: boolean) => ({
  type: 'WILL_IS_LOADING',
  payload: loading
});
