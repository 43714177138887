import React, { VFC } from "react";
import { Alert, FormInput } from "../Form";
import { modalTexts } from "./helpers";

import { PersonTypes } from "./AddPerson";
import { useSaveResultToRedux, useSendMutation } from "../../hooks";
import {
  DELETE_CHILD_MUTATION,
  DELETE_PARTNER_MUTATION,
} from "../../GraphQL/Mutations";
import { aboutYou_children, aboutYou_deletePartner } from "../../Redux/actions";

interface PersonFormI {
  onSubmit: any;
  errors: any;
  toggleModal: any;
  register: any;
  reset: any;
  personType: PersonTypes;
  personSelected: any;
}

export const PersonForm: VFC<PersonFormI> = ({
  onSubmit,
  errors,
  register,
  toggleModal,
  reset,
  personType,
  personSelected,
}) => {
  const editing = !!personSelected?._id;

  const saveToRedux = useSaveResultToRedux();

  const {
    sendMutationRequest: sendDeletePartnerMutation,
    loading: deletePartnerLoading,
  } = useSendMutation(DELETE_PARTNER_MUTATION);
  const {
    sendMutationRequest: sendDeleteChildMutation,
    loading: deleteChildLoading,
  } = useSendMutation(DELETE_CHILD_MUTATION);

  const deleteLoading = deleteChildLoading || deletePartnerLoading;

  async function deletePerson() {
    if (personType === "child") {
      const { deleteChild } =
        (await sendDeleteChildMutation({ id: personSelected._id })) ?? {};

      saveToRedux(aboutYou_children, deleteChild.children);

      reset({ children: deleteChild.children });
    } else {
      await sendDeletePartnerMutation({ id: personSelected._id });

      saveToRedux(aboutYou_deletePartner);
    }

    toggleModal();
  }

  return (
    <form className="person_form" onSubmit={onSubmit}>
      <FormInput
        className="modal_form"
        label="Celé jméno"
        errors={errors}
        input={{
          name: "fullName",
          register: register({
            maxLength: {
              value: 40,
              message: "Maximální počet znaků je 40",
            },
            required: { value: true, message: "Toto pole je povinné" },
          }),
          type: "text",
          placeholder: "Např.: Jan Novák",
        }}
      />

      <FormInput
        className="modal_form"
        label="Datum narození"
        errors={errors}
        input={{
          name: "birth",
          register: register({
            required: { value: true, message: "Toto pole je povinné" },
          }),
          type: "date",
          resetBirth: personSelected?.birth.split("-"),
        }}
      />

      {errors?.date && <Alert text={errors.date.message} />}

      {/* {personType === "child" && ( */}
        <FormInput
          className="modal_form"
          label="Bydliště"
          errors={errors}
          input={{
            name: "street",
            register: register({
              required: { value: true, message: "Toto pole je povinné" },
            }),
            type: "text",
            placeholder: "",
          }}
        />
      {/* )} */}

      <div className="modal_footer">
        <button type="submit" className="modal_save">
          {deleteLoading ? "Ukládání..." : "Uložit"}
        </button>

        {editing && (personType === "child" || personType === "partner") && (
          <button
            type="button"
            onClick={() => deletePerson()}
            className="modal_cancel"
          >
            {deleteLoading ? "Odstraňování..." : modalTexts[personType][2]}
          </button>
        )}
      </div>
    </form>
  );
};
