import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from "@apollo/client";
import { onError } from "apollo-link-error";
import dotenv from "dotenv";
import Cookie from "js-cookie";
import "normalize.css";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import "./index.scss";
import { store } from "./lib/reduxStore";
import { user_logout } from "./Redux/actions/authentication";

dotenv.config();

const backendURL =
  process.env.NODE_ENV === "production"
    ? "https://api.goodbye.cz/graphql"
    : "http://localhost:4000/graphql";

// authentication layer on every GraphQL Mutation | Query
const authLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      // @ts-ignore
      if (err.extensions.code === "UNAUTHENTICATED") {
        if (!Cookie.get("logged")) {
          store.dispatch(user_logout(false));
        }
        return forward(operation);
      }
    }
  }
});

const httpLink = new HttpLink({
  uri: backendURL,
  credentials: "include",
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  // @ts-ignore
  link: authLink.concat(httpLink),
});

/// <reference path="App.tsx" />

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
