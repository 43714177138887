import { FormInput } from "../../../../../Components/Form";
import React, { FC } from "react";

interface GenderRadioI {
  register: any;
}

export const GenderRadio: FC<GenderRadioI> = ({ register }) => {
  return (
    <FormInput title={"Vaše pohlaví"}>
      <label className="radio-label">
        <input
          name="gender"
          value="male"
          ref={register}
          type="radio"
          className="radio-input"
        />
        <div className="radio-checkbox"></div>
        Muž
      </label>
      <label className="radio-label">
        <input
          name="gender"
          value="female"
          ref={register}
          type="radio"
          className="radio-input"
        />
        <div className="radio-checkbox"></div>
        Žena
      </label>
    </FormInput>
  );
};
