import React, { BaseSyntheticEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import GoBack from "../../../Components/Buttons/GoBack/GoBack";
import SaveButton from "../../../Components/Buttons/SaveButton";
import { Alert, FormButtons, FormInput } from "../../../Components/Form";
import { SEO } from "../../../Components/seo/Seo";
import { ALLOW_OTHERS_TO_DECIDE } from "../../../GraphQL/Mutations";
import { useSaveResultToRedux, useSendMutation } from "../../../hooks";
import { allowOtherInheritorsToDecide } from "../../../Redux/actions";

const AllowOthersToDecide = () => {
  const history = useHistory();

  const saveToRedux = useSaveResultToRedux();

  const { handleSubmit } = useForm();

  const allowOthersToDecideRedux = useSelector(
    (state: any) => state.inheritEstateReducer.allowOtherInheritorsToDecide
  );

  const {
    sendMutationRequest: sendAllowOthersToDecideMutation,
    loading: allowOthersLoading,
  } = useSendMutation(ALLOW_OTHERS_TO_DECIDE);

  const [allowOthersToDecide, setAllowOthersToDecide] =
    useState<undefined | boolean>();

  const [requiredError, setRequiredError] = useState(false);

  async function onSubmit(data: any, e: BaseSyntheticEvent | undefined) {
    if (allowOthersToDecide !== true && allowOthersToDecide !== false) {
      setRequiredError(true);

      return;
    }

    const { allowInheritorsToDecide } =
      (await sendAllowOthersToDecideMutation({
        allow: allowOthersToDecide,
      })) ?? {};

    saveToRedux(allowOtherInheritorsToDecide, allowInheritorsToDecide);

    history.push("/prehled");
  }

  function handleClick(e: BaseSyntheticEvent) {
    const { name } = e.target;

    setAllowOthersToDecide(() => (name === "yes" ? true : false));
  }

  useEffect(() => {
    if (
      allowOthersToDecideRedux === false ||
      allowOthersToDecideRedux === true
    ) {
      setAllowOthersToDecide(allowOthersToDecideRedux);
    }
  }, []);

  return (
    <div className="estate form">
      <SEO page="estate_share" />

      <GoBack />

      <div className="share">
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            title={"Dovolíte dědicům se dohodnout i jinak?"}
            description={[
              "Připouštíte, aby se uvedení dědicové při pozůstalostním řízení dohodli o rozdělení majetku i jinak, než je uvedeno závěti?",
              "Na základě předchozích kroků jste určili velikost podílů jednotlivých dědiců. V případě, že dědicové nebudou mít možnost se dohodnout jinak, znamená to, že všechny položky vašeho majetku nabydou v režimu spoluvlastnictví.",
              "Jakmile bude po skončení řízení potřeba s majetkem jakkoli disponovat, bude k tomu tak potřeba součinnosti a souhlasu všech dědiců. V praxi to znamená, že např. ke zrušení bankovního účtu bude potřeba, aby banku navštívili všichni vaši dědicové společně, což může být v důsledku komplikované."
            ]}
          >
            <FormButtons
              buttons={[
                {
                  name: "yes",
                  className: "single_button_section first_button",
                  active: allowOthersToDecide === true,
                  activeClassName: "pressed",
                  text: "Ano",
                },
                {
                  name: "no",
                  className: "single_button_section",
                  active: allowOthersToDecide === false,
                  activeClassName: "pressed",
                  text: "Ne",
                },
              ]}
              onClick={handleClick}
            />

            {requiredError && <Alert text="Zvolte prosím jednu z možností" />}
          </FormInput>

          <SaveButton
            loading={allowOthersLoading}
            readyToRoll={
              allowOthersToDecide === true || allowOthersToDecide === false
            }
          />
        </form>
      </div>
    </div>
  );
};

export default AllowOthersToDecide;
