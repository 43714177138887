import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Alert } from "../../../Components/Form";
import { REGISTER_CONFIRM_MUTATION } from "../../../GraphQL/Mutations";
import "./RegisterSuccess";

const RegisterConfirm = () => {
  const history = useHistory();

  const [confirmSuccess, setConfirmSuccess] = useState(false);

  const [registerConfirmMutation, { error: registerConfirmError, loading }] = useMutation(
    REGISTER_CONFIRM_MUTATION
  );

  const registerConfirm = () => {
    const token = history.location.pathname.split("/")[2];
    sendRegisterConfirmation(token);
  };

  async function sendRegisterConfirmation(token: string) {
    try {
      await (
        await registerConfirmMutation({
          variables: { token },
        })
      ).data;
      setConfirmSuccess(true);
      setTimeout(() => {
        history.push("/prihlaseni");
      }, 4000);
    } catch (err) {}
  }
  return (
    <div className="registerSuccess">
      <h2 className="register-success_title">
        Potvrďte registraci kliknutím na tlačítko
      </h2>
      {confirmSuccess ? (
        <p className="confirm-success">
          Potvrzení proběhlo úspěšně. Prosíme vyčkejte, nyní budete automaticky
          přesměrováni na přihlašovací stránku.
        </p>
      ) : (
        <button
          className="button"
          onClick={loading ? () => alert("Načítání...") : () => registerConfirm()}
        >
          Potvrdit registraci
        </button>
      )}
      {registerConfirmError && <Alert text={registerConfirmError.message} />}
    </div>
  );
};

export default RegisterConfirm;
