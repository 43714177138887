import React from "react";
import { useCloseModalOnEscape } from "../../hooks/useCloseModalOnEscape";
import closeB from "../../Images/closeB.svg";
import "./Modal.scss";

interface ModalProps {
  header: string;
  children: any;
  toggle: () => void;
}

const Modal = (props: ModalProps) => {
  useCloseModalOnEscape({ toggle: props.toggle });

  return (
    <div className="modal-backdrop">
      <div className="modal">
        <div className="modal-header">
          <h1>{props.header}</h1>
          <button>
            <img src={closeB} alt="Zavřít" onClick={() => props.toggle()} />
          </button>
        </div>
        <div className="modal-content">{props.children}</div>
      </div>
    </div>
  );
};

export default Modal;
