import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import GoBack from "../../../../Components/Buttons/GoBack/GoBack";
import SaveButton from "../../../../Components/Buttons/SaveButton";
import {
  AddButton,
  Alert,
  FormButtonI,
  FormButtons,
  FormInput,
} from "../../../../Components/Form";
import DeleteModal from "../../../../Components/Modal/DeleteModal";
import AddPerson from "../../../../Components/PersonBox/AddPerson";
import PersonBox from "../../../../Components/PersonBox/PersonBox";
import { SEO } from "../../../../Components/seo/Seo";
import Tooltip from "../../../../Components/Tooltip/Tooltip";
import { tooltipData } from "../../../../Data/tooltipData";
import {
  DELETE_ALL_CHILDREN_MUTATION,
  DELETE_CHILD_MUTATION,
  HAS_CHILDREN_MUTATION,
} from "../../../../GraphQL/Mutations";
import {
  useIsReadyToContinue,
  useModal,
  useSaveResultToRedux,
  useSendMutation,
} from "../../../../hooks";
import {
  aboutYou_children,
  aboutYou_hasChildren,
  aboutYou_pets,
  getGifts,
  getInheritEstate,
  initialPerson,
} from "../../../../Redux/actions";

const Children = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const { register, handleSubmit, reset } = useForm();

  const [personSelected, setPersonSelected] = useState<any>({
    ...initialPerson,
    type: "child",
  });

  const [hasChildren, setHasChildren] = useState<any>();

  const [isAddChildModalOpen, toggleAddChildModal] = useModal();
  const [isDeleteModalOpen, toggleDeleteModal] = useModal();

  const [error, setError] = useState("");
  const [requiredError, setRequiredError] = useState(false);

  const children = useSelector((state: any) => state.aboutYouReducer.children);
  const hasChildrenRedux = useSelector(
    (state: any) => state.aboutYouReducer.hasChildren
  );

  const isReadyToContinue = useIsReadyToContinue({
    conditions: [!hasChildren, hasChildren && children.length],
  });

  const {
    sendMutationRequest: sendHasChildrenMutation,
    loading: hasChildrenLoading,
  } = useSendMutation(HAS_CHILDREN_MUTATION);
  const {
    sendMutationRequest: sendDeleteChildMutation,
    loading: deleteChildLoading,
  } = useSendMutation(DELETE_CHILD_MUTATION);

  const {
    sendMutationRequest: sendDeleteAllChildrenMutation,
    loading: deleteAllChildrenLoading,
  } = useSendMutation(DELETE_ALL_CHILDREN_MUTATION);

  const saveToRedux = useSaveResultToRedux();

  const onSubmit = async (formData: any, e: any) => {
    e.preventDefault();

    if (hasChildren !== false && hasChildren !== true) {
      // didn't choose yet
      setRequiredError(true);

      return;
    }

    if (hasChildren && children.length === 0) {
      setError("Musíte zvolit nějaké děti");

      return;
    }

    if (!hasChildren) {
      const [, hasChildrenResult] = await Promise.all([
        sendDeleteAllChildrenMutation(),
        sendHasChildrenMutation({ hasChildren }),
      ]);

      saveToRedux(aboutYou_hasChildren, hasChildrenResult?.hasChildren);
      saveToRedux(aboutYou_children, []);

      history.push("/o-vas/domaci-zvirata");
    } else {
      const result = await sendHasChildrenMutation({ hasChildren });

      saveToRedux(aboutYou_hasChildren, result?.hasChildren);

      history.push("/o-vas/domaci-zvirata");
    }
  };

  const handleClick = (e: any) => {
    e.target.name === "yes" ? setHasChildren(true) : setHasChildren(false);
  };

  function appendChild() {
    reset({ children: children });
  }

  function addChild() {
    setPersonSelected({ ...initialPerson, type: "child" });
    toggleAddChildModal();
  }

  function editChild(child: any) {
    setPersonSelected({ ...child, type: "child" });
    toggleAddChildModal();
  }

  function deleteConfirmation(child: string) {
    setPersonSelected(child);
    toggleDeleteModal();
  }

  async function deleteChildMutation() {
    const { deleteChild } =
      (await sendDeleteChildMutation({ id: personSelected._id })) ?? {};

    const { children, pets, gifts, inheritEstate } = deleteChild;

    saveToRedux(aboutYou_children, children);
    saveToRedux(aboutYou_pets, pets);
    saveToRedux(getGifts, gifts);
    dispatch(
      getInheritEstate(
        inheritEstate.filter((i: any) => i.person),
        inheritEstate.filter((i: any) => i.charity)
      )
    );

    toggleDeleteModal();
  }

  useEffect(() => {
    if (!hasChildren || (hasChildren && children.length)) {
      setError("");
    }

    if (hasChildren && children.length) {
      reset({ children: children });
    }

    if (hasChildren === true || hasChildren === false) {
      setRequiredError(false);
    }
  }, [hasChildren, children]);

  useEffect(() => {
    if (hasChildrenRedux === true) {
      setHasChildren(true);

      reset({ children: children });
    }

    if (hasChildrenRedux === false) {
      setHasChildren(false);
    }
  }, []);

  const buttonsData: FormButtonI[] = [
    {
      name: "yes",
      className: "single_button_section first_button",
      activeClassName: "pressed",
      text: "Ano",
      active: hasChildren === true,
    },
    {
      name: "no",
      className: "single_button_section",
      activeClassName: "pressed",
      text: "Ne",
      active: hasChildren === false,
    },
  ];

  return (
    <div className="form">
      <SEO page="children" />

      <GoBack />

      <h1 className="form-title">Máte nějaké děti?</h1>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          description={[
            "Možnost „ano“ zvolte i v případě, pokud očekáváte ještě nenarozené dítě.",
            "Nevlastní děti zatím nepřidávejte. Pokud jim budete chtít zanechat část vašeho majetku, můžete tak udělat později.",
          ]}
        >
          <FormButtons onClick={handleClick} buttons={buttonsData} />

          {requiredError && <Alert text="Zvolte prosím jednu z možností" />}
        </FormInput>

        <Tooltip
          id="kids"
          title="Děti"
          customText="Proč potřebujeme vědět jestli čekáte dítě?"
          description={["Na počaté a dosud nenarozené dítě, za předpokladu, že se v budoucnu narodí živé, se dle zákona hledí jako na již narozené. To znamená, že i takové dítě může být způsobilým dědicem a je tedy možné ho do závěti uvést. Současně je ale nutné pamatovat, že takové dítě bude mít právo také na povinný díl stejně jako již narozené děti."]}
        />

        {hasChildren && (
          <FormInput title="Vaše děti">
            {children.length > 0
              ? children.map((el: any, index: number) => (
                  <PersonBox
                    id={el._id}
                    person={el}
                    key={index}
                    name={`children[${index}].value`}
                    personName={el.fullName}
                    description={moment(el.birth, "YYYY-MM-DD").format(
                      "DD.MM.YYYY"
                    )}
                    register={register()}
                    edit={editChild}
                    deletePerson={deleteConfirmation}
                  />
                ))
              : null}

            <AddButton className="partner_add" onClick={addChild}>
              Přidat dítě
            </AddButton>

            <Tooltip
              id={tooltipData["child"].id}
              title={tooltipData["child"].title}
              description={tooltipData["child"].description}
            />
          </FormInput>
        )}

        <Alert text={error} />

        <SaveButton
          readyToRoll={isReadyToContinue}
          loading={deleteAllChildrenLoading || hasChildrenLoading}
        />
      </form>

      {isAddChildModalOpen && (
        <AddPerson
          toggle={toggleAddChildModal}
          personType={personSelected.type}
          reset={reset}
          personSelected={personSelected}
          append={appendChild}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteModal
          headerText="Odstranit dítě"
          toggleDeleteModal={toggleDeleteModal}
          personSelected={personSelected}
          loading={deleteChildLoading}
          sendDeletion={deleteChildMutation}
        />
      )}
    </div>
  );
};

export default Children;
