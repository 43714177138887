import React, { MouseEvent } from "react";
import { useSelector } from "react-redux";

interface LogoutButtonI {
  logged: boolean;
  toggleModal: () => any;
  logout: any;
  logOutQueryLoading: boolean;
}

const LogoutButton = ({
  logged,
  toggleModal,
  logout,
  logOutQueryLoading,
}: LogoutButtonI) => {
  const { paidForWill } = useSelector((state: any) => state.paymentReducer);

  function handleLogout(e: MouseEvent) {
    e.preventDefault();
    if (!paidForWill) {
      toggleModal();
      return;
    }

    logout();
  }

  return (
    <button
      className={`logout_button ${!logged ? "logout_button-logged" : null}`}
      onClick={handleLogout}
    >
      {logged && !logOutQueryLoading
        ? "Uložit a odhlásit se"
        : logged && logOutQueryLoading && "Odhlašujeme..."}
    </button>
  );
};

export default LogoutButton;
