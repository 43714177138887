import { debounce } from "lodash";
import { useEffect, useState } from "react";

export function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", debounce(handleResize, 200));
    return () => window.removeEventListener("resize", debounce(handleResize, 200));
  }, []);

  return windowWidth;
}
