import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormInput } from "../../../Components/Form";
import Modal from "../../../Components/Modal/Modal";
import {
  ADD_ASSET_MUTATION,
  DELETE_ASSET_MUTATION,
  UPDATE_ASSET_MUTATION,
} from "../../../GraphQL/Mutations";
import { useSaveResultToRedux, useSendMutation } from "../../../hooks";
import { AssetI, getAssets } from "../../../Redux/actions";
import { assetsOptions, formNames, labelNames } from "./formData";

interface AddAssetI {
  editingItem: AssetI;
  toggle: any;
  append: (
    type: string,
    name: string,
    mortgage?: string,
    owner?: string
  ) => any;
}

const AddAsset = (props: AddAssetI) => {
  const { toggle, append, editingItem } = props;

  const updatingOlderAsset = !!editingItem._id;

  const { reset, register, handleSubmit, errors, watch } = useForm();

  const assetTypeWatch: string = watch("asset_type");

  const { sendMutationRequest: sendAddAssetMutation, loading: addLoading } =
    useSendMutation(ADD_ASSET_MUTATION);
  const {
    sendMutationRequest: sendUpdateAssetMutation,
    loading: updateLoading,
  } = useSendMutation(UPDATE_ASSET_MUTATION);
  const {
    sendMutationRequest: sendDeleteAssetMutation,
    loading: deleteLoading,
  } = useSendMutation(DELETE_ASSET_MUTATION);

  const onSubmit = (formData: any, e: any) => {
    e.preventDefault();

    if (addLoading || updateLoading) return;

    if (updatingOlderAsset && assetTypeWatch === "property") {
      updateProperty(formData);

      return;
    }

    if (updatingOlderAsset) {
      updateAsset(formData);

      return;
    }

    if (assetTypeWatch === "property") {
      newProperty(formData);

      return;
    }

    newAsset(formData);
  };

  const saveToRedux = useSaveResultToRedux();

  async function deleteAsset() {
    const { deleteAsset } =
      (await sendDeleteAssetMutation({ id: editingItem._id })) ?? {};

    saveToRedux(getAssets, deleteAsset.assets);

    toggle();
  }

  async function newProperty(data: any) {
    const {
      asset_type: type,
      name: address,
      insuranceProvider,
      ...rest
    } = data;

    const { newAsset } =
      (await sendAddAssetMutation({
        type,
        address,
        ...rest,
      })) ?? {};

    saveToRedux(getAssets, newAsset);

    toggle();
  }

  async function updateProperty(data: any) {
    const {
      asset_type: type,
      name: address,
      insuranceProvider,
      ...rest
    } = data;

    const { updateAsset } =
      (await sendUpdateAssetMutation({
        id: editingItem._id,
        type,
        address,
        ...rest,
      })) ?? {};

    saveToRedux(getAssets, updateAsset.assets);

    toggle();
  }

  async function newAsset(data: any) {
    const { newAsset } =
      (await sendAddAssetMutation({
        type: data.asset_type,
        [formNames[assetTypeWatch]]: data[formNames[assetTypeWatch]],
      })) ?? {};

    saveToRedux(getAssets, newAsset);

    append(data.asset_type, data[formNames[assetTypeWatch]]);

    toggle();
  }

  async function updateAsset(data: any) {
    const { updateAsset } =
      (await sendUpdateAssetMutation({
        id: editingItem._id,
        type: data.asset_type,
        [formNames[assetTypeWatch]]: data[formNames[assetTypeWatch]],
      })) ?? {};

    saveToRedux(getAssets, updateAsset.assets);

    toggle();
  }

  useEffect(() => {
    if (updatingOlderAsset && editingItem.type === "property") {
      reset({
        asset_type: editingItem.type,
        name: editingItem.address,
        propertyInfo1: editingItem.propertyInfo1,
        propertyInfo2: editingItem.propertyInfo2,
        propertyInfo3: editingItem.propertyInfo3,
        mortgage: editingItem.mortgage,
        owner: editingItem.owner,
      });
    } else if (updatingOlderAsset) {
      reset({
        asset_type: editingItem.type,
        [formNames[editingItem.type]]:
          // @ts-ignore
          editingItem[formNames[editingItem.type]],
      });
    }
  }, []);

  return (
    <Modal header="Přidat majetek" toggle={toggle}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          className="modal_form"
          label="Typ majetku"
          description={
            assetTypeWatch && assetTypeWatch === "property"
              ? [
                  "Vyplňte adresu nemovitosti tak, aby byla co nejlépe identifikovatelná.",
                ]
              : assetTypeWatch && assetTypeWatch === "other" ? ["Často lidé zapomínají např. na vozidlo a přívěs, hrobové místo a hrobové příslušenství či autorská práva."] : []
          }
        >
          <select
            className="select"
            name="asset_type"
            ref={register({ required: true })}
          >
            {assetsOptions.map((option) => (
              <option className="option" value={option.value}>
                {option.text}
              </option>
            ))}
          </select>
        </FormInput>

        {assetTypeWatch && assetTypeWatch === "property" ? (
          <>
            <FormInput
              className="modal_form"
              label="Adresa"
              errors={errors}
              input={{
                name: "name",
                register: register({
                  required: {
                    value: true,
                    message: "Toto pole je povinné",
                  },
                  maxLength: {
                    value: 100,
                    message: "Maximální počet znaků je 100",
                  },
                }),
                type: "text",
                placeholder:
                  'Např. "Černokostelecká 39, Říčany 123 45, byt č.33"',
              }}
            />
          </>
        ) : (
          assetTypeWatch && (
            <FormInput
              className="modal_form"
              errors={errors}
              label={assetTypeWatch && labelNames[assetTypeWatch]}
              // here key serves as an indicator that the assetType was changed, so it re-renders the component
              // solves registering issue
              key={assetTypeWatch}
              input={{
                name: `${formNames[assetTypeWatch]}`,
                register: register({
                  maxLength: {
                    value: 150,
                    message: "Maximální počet znaků je 150",
                  },
                  required: {
                    value: true,
                    message: "Toto pole je povinné",
                  },
                }),
                type: "text",
              }}
            />
          )
        )}

        <div className="modal_footer">
          {updatingOlderAsset && (
            <button
              type="button"
              disabled={deleteLoading}
              onClick={() => deleteAsset()}
              className="modal_cancel"
            >
              {deleteLoading ? "Odstraňování.." : "Odstranit majetek"}
            </button>
          )}

          <button type="submit" className="modal_save">
            {addLoading || updateLoading ? "Ukládání..." : "Uložit"}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddAsset;

// These are the unused additional form inputs, that were needed at some point.
// If additional information about the asset is needed, these can be used.

/* <FormInput
              className="modal_form"
              label="Parcelní číslo a katastrální území v katastru nemovitostí"
              errors={errors}
              input={{
                name: "propertyInfo1",
                register: register({
                  maxLength: {
                    value: 120,
                    message: "Maximální počet znaků je 120",
                  },
                }),
                type: "text",
                placeholder: "(Nepovinné)",
              }}
            />
            <FormInput
              className="modal_form"
              label="Číslo popisné budovy, ev.č. budovy, obec a katastrální území"
              errors={errors}
              input={{
                name: "propertyInfo2",
                register: register({
                  maxLength: {
                    value: 120,
                    message: "Maximální počet znaků je 120",
                  },
                }),
                type: "text",
                placeholder: "(Nepovinné)",
              }}
            />
            <FormInput
              className="modal_form"
              label="Číslo jednotky, katastrální území a obec"
              errors={errors}
              input={{
                name: "propertyInfo3",
                register: register({
                  maxLength: {
                    value: 120,
                    message: "Maximální počet znaků je 120",
                  },
                }),
                type: "text",
                placeholder: "(Nepovinné)",
              }}
            /> */
/* <FormInput
              className="modal_form modal_form-radio"
              label="Platíte na tuto nemovitost hypotéku?"
            >
              <label className="radio-label">
                <input
                  name="mortgage"
                  value="yes"
                  ref={register}
                  type="radio"
                  className="radio-input"
                />
                <div className="radio-checkbox"></div>
                Ano
              </label>
              <label className="radio-label">
                <input
                  name="mortgage"
                  value="no"
                  ref={register}
                  type="radio"
                  className="radio-input"
                />
                <div className="radio-checkbox"></div>
                Ne
              </label>
              <label className="radio-label">
                <input
                  name="mortgage"
                  value="dont_know"
                  ref={register}
                  type="radio"
                  className="radio-input"
                />
                <div className="radio-checkbox"></div>
                Nevím
              </label>
            </FormInput>
            <FormInput
              className="modal_form modal_form-radio modal_form-ownership"
              label="Kdo je vlastníkem této nemovitosti?"
            >
              <label className="radio-label">
                <input
                  name="owner"
                  value="onlyOwner"
                  ref={register}
                  type="radio"
                  className="radio-input"
                />
                <div className="radio-checkbox"></div>
                Pouze já
              </label>
              <label className="radio-label">
                <input
                  name="owner"
                  value="jointOwner"
                  ref={register}
                  type="radio"
                  className="radio-input"
                />
                <div className="radio-checkbox"></div>
                Spoluvlastním ji s někým jiným
              </label>
              <label className="radio-label">
                <input
                  name="owner"
                  value="dont_know"
                  ref={register}
                  type="radio"
                  className="radio-input"
                />
                <div className="radio-checkbox"></div>
                Nevím
              </label>
            </FormInput> */
