import { gql } from '@apollo/client';

export const PARTNER_CODE_MUTATION = gql`
  mutation createPartnerCodeMutation($email: String!) {
    createRefferalCode(email: $email, codeType: "partner")
  }
`;

export const FRIENDS_CODE_MUTATION = gql`
  mutation {
    createRefferalCode(codeType: "friends")
  }
`;
