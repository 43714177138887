import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import GoBack from "../../../Components/Buttons/GoBack/GoBack";
import SaveButton from "../../../Components/Buttons/SaveButton";
import { Alert, FormInput } from "../../../Components/Form";
import PersonBox from "../../../Components/PersonBox/PersonBox";
import { SEO } from "../../../Components/seo/Seo";
import { INHERIT_ESTATE_BACKUPS } from "../../../GraphQL/Mutations";
import {
  allPeopleArrayFn,
  allPeopleByIdFn,
  preventMoreThan2Decimal,
} from "../../../Helpers";
import { getInheritEstate } from "../../../Redux/actions";
import CharitiesList from "./CharitiesList";
import { RealTimeShareSum } from "./RealTimeShareSum";

interface BackupInheritorSharePropsI {
  redirectTo: string;
  personID: string;
  backupCharities: any[];
  backupInheritors: any[];
}

const BackupInheritorShare = (props: BackupInheritorSharePropsI) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { handleSubmit, register, control, watch, errors } = useForm();
  const {} = useFieldArray({ name: "charities", control });
  const {} = useFieldArray({ name: "inheritors", control });

  const charitiesWatch = watch("charities");
  const inheritorsWatch = watch("inheritors");

  const [inheritEstateMutation, { loading: inheritEstateLoading }] =
    useMutation(INHERIT_ESTATE_BACKUPS);

  const {
    aboutYouReducer: {
      children: childrenRedux,
      marital_status: { partner: partnerRedux },
    },
    inheritEstateReducer: inheritEstateRedux,
    randomPeopleReducer: randomPeopleRedux,
  } = useSelector((state: any) => state);

  const currentInheritorRedux = inheritEstateRedux.inheritors.find(
    (i: any) => i.person === props.personID
  );

  const backupCharitiesIDs = props.backupCharities.map((el: any) => el.charity);
  const backupInheritorsIDs = props.backupInheritors.map(
    (el: any) => el.person
  );

  const [nonDefaultCharities, setNonDefaultCharities] = useState<any[]>([]);
  const [customError, setCustomError] = useState("");

  const [sumRealTime, setSumRealTime] = useState("0");

  const [charitiesListIDs] = useState(
    () => new Set(CharitiesList.map((charity: any) => charity._id))
  );

  const allPeopleById = allPeopleByIdFn(
    childrenRedux,
    partnerRedux,
    randomPeopleRedux
  );
  const inheritors = allPeopleArrayFn(
    childrenRedux,
    partnerRedux,
    randomPeopleRedux
  ).filter((el: any) => backupInheritorsIDs.includes(el._id));

  const charities = [...nonDefaultCharities, ...CharitiesList].filter(
    (el: any) =>
      backupCharitiesIDs.includes(el._id) ||
      backupCharitiesIDs.includes(el.charity)
  );

  function onSubmit(data: any, e: any) {
    e.preventDefault();

    const { inheritors: inheritorsData, charities: charitiesData } = data;

    setCustomError("");

    if (Number(sumRealTime) < 99.99) {
      setCustomError("Součet musí dávat dohromady 100%");

      return;
    }

    let charitiesHaveFalseValue;

    let inheritorsHaveFalseValue;

    if (charitiesData) {
      charitiesHaveFalseValue = Object.values(charitiesData).some(
        (percentage: any) => !!!Number(percentage)
      );
    } else if (inheritorsData) {
      inheritorsHaveFalseValue = Object.values(inheritorsData).some(
        (percentage: any) => !!!Number(percentage)
      );
    }
    if (charitiesHaveFalseValue || inheritorsHaveFalseValue) {
      setCustomError("Majetek musí být rozdělen mezi všechny dědice");

      return;
    }

    sendBackupMutation(inheritorsData, charitiesData);
  }

  async function sendBackupMutation(inheritorsData: any[], charitiesData: any) {
    try {
      const backupsWithShare = currentInheritorRedux.backupInheritors.map(
        (backup: any) => {
          if (backup.charity) {
            return {
              ...backup,
              percentages: Number(charitiesData[backup.charity]),
            };
          } else {
            return {
              ...backup,
              percentages: Number(inheritorsData[backup.person]),
            };
          }
        }
      );

      const inheritorsWithBackupShare = inheritEstateRedux.inheritors.map(
        (i: any) =>
          i.person === props.personID
            ? { ...i, backupInheritors: backupsWithShare }
            : i
      );
      const updatedInheritEstate = await inheritEstateMutation({
        variables: {
          inheritEstate: [
            ...inheritorsWithBackupShare,
            ...inheritEstateRedux.charities,
          ],
        },
      });
      dispatch(
        getInheritEstate(
          updatedInheritEstate.data.inheritEstate.filter(
            (el: any) => el.person
          ),
          updatedInheritEstate.data.inheritEstate.filter(
            (el: any) => el.charity
          )
        )
      );
      history.push(props.redirectTo);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (charitiesWatch || inheritorsWatch) {
      const shareReduced: any = [
        ...Object.values(inheritorsWatch || []),
        ...Object.values(charitiesWatch || []),
      ].reduce((acc, curr): any => Number(acc) + Number(curr), 0);

      setSumRealTime(() => shareReduced.toFixed(2));
    }
  }, [inheritorsWatch, charitiesWatch]);

  useEffect(() => {
    setNonDefaultCharities(() =>
      backupCharitiesIDs
        .filter((charity: any) => !charitiesListIDs.has(charity))
        .map((charity: any) => ({
          _id: charity,
          type: "charity",
          name: charity,
        }))
    );
  }, []);

  return (
    <div className="estate form">
      <SEO page="estate_backups_share" />

      <GoBack />

      <div className="share">
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            title="Rozdělte podíl mezi náhradníky"
            description={[
              `Zde můžete vybrat, jak rozdělit část, kterou měl zdědit ${
                allPeopleById[props.personID].fullName
              }`,
            ]}
            errors={errors}
          >
            {inheritors.map((el: any, index: number) => (
              <div key={index}>
                <PersonBox
                  person={el}
                  id={el._id}
                  description={el.birth}
                  name={`inheritors[${el._id}]`}
                  input={true}
                  personName={el.fullName || el.name}
                  onChange={preventMoreThan2Decimal}
                  register={register}
                />
                {errors?.inheritors?.[el._id] && (
                  <Alert text={errors.inheritors[el._id].message} />
                )}
              </div>
            ))}

            {charities.map((charity: any, index: number) => (
              <div key={index}>
                <PersonBox
                  person={charity}
                  id={charity._id}
                  description="Nezisková organizace"
                  name={`charities["${charity._id}"]`}
                  input={true}
                  personName={charity.name}
                  onChange={preventMoreThan2Decimal}
                  register={register()}
                />

                {errors?.charities?.[charity._id] && (
                  <Alert text={errors.charities[charity._id].message} />
                )}
              </div>
            ))}
          </FormInput>

          <div className="share-right">
            <RealTimeShareSum sumRealTime={sumRealTime} />

            {customError && <Alert text={customError} />}
          </div>

          <SaveButton loading={inheritEstateLoading} readyToRoll={true} />
        </form>
      </div>
    </div>
  );
};

export default BackupInheritorShare;
