import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import GoBack from "../../../Components/Buttons/GoBack/GoBack";
import SaveButton from "../../../Components/Buttons/SaveButton";
import { AddButton, FormButtons, FormInput } from "../../../Components/Form";
import AddPerson from "../../../Components/PersonBox/AddPerson";
import PersonBox, { NonDefaultCharityBox } from "../../../Components/PersonBox/PersonBox";
import { SEO } from "../../../Components/seo/Seo";
import { INHERIT_ESTATE_BACKUPS } from "../../../GraphQL/Mutations/estate";
import { allPeopleArrayFn, allPeopleByIdFn } from "../../../Helpers/";
import { useModal, useSendMutation } from "../../../hooks";
import {
  addBackupInheritor,
  getInheritEstate,
  initialPerson,
} from "../../../Redux/actions";
import AddCharity from "./AddCharity";
import CharitiesList from "./CharitiesList";

interface BackupInheritorPropsI {
  redirectTo: string;
  personID: string;
  redirectToNoShare: string;
}

const whoInheritsOptions = [
  { text: "Jejich děti", name: "theirKids" },
  { text: "Rozdělit mezi zbylé dědice rovným dílem", name: "otherInheritors" },
  { text: "Další lidé či neziskové organizace", name: "other" },
];

type InheritOptionsTypes = "theirKids" | "otherInheritors" | "other" | "";

const BackupInheritor = (props: BackupInheritorPropsI) => {
  const history = useHistory();

  const dispatch = useDispatch();

  const { handleSubmit, register, reset } = useForm();

  const {
    aboutYouReducer: {
      children: childrenRedux,
      marital_status: { partner: partnerRedux },
    },
    randomPeopleReducer: randomPeopleRedux,
    inheritEstateReducer: inheritEstateRedux,
  } = useSelector((state: any) => state);

  const allPeople = allPeopleArrayFn(
    childrenRedux,
    partnerRedux,
    randomPeopleRedux,
    props.personID
  );
  const allPeopleById = allPeopleByIdFn(childrenRedux, partnerRedux, randomPeopleRedux);

  const {
    sendMutationRequest: sendInheritEstateMutation,
    loading: inheritEstateLoading,
  } = useSendMutation(INHERIT_ESTATE_BACKUPS);

  const [chosenInheritors, setChosenInheritors] = useState<InheritOptionsTypes>("");

  const [isAddCharityModalOpen, toggleAddCharity] = useModal();
  const [isAddPersonModalOpen, toggleAddPerson] = useModal();

  const [personSelected, setPersonSelected] = useState<any>({
    ...initialPerson,
    type: "inheritor",
  });
  const [selectedCharities, setSelectedCharities] = useState<string[]>([]);
  const [editingCharity, setEditingCharity] = useState({ charityName: "" });
  const [nonDefaultCharities, setNonDefaultCharities] = useState<any[]>([]);

  const charitiesListIDs = new Set(CharitiesList.map((charity: any) => charity._id));

  function onSubmit(formData: any, e: any) {
    e.preventDefault();

    if (chosenInheritors !== "other") {
      sendInheritorsBackups();

      return;
    }

    if (formData?.nonDefaultCharities) {
      const submitData = Array.isArray(formData.nonDefaultCharities)
        ? formData
        : {
            ...formData,
            nonDefaultCharities: [formData.nonDefaultCharities],
          };

      dispatch(
        addBackupInheritor(
          props.personID,
          Array.isArray(formData.backupInheritors)
            ? formData.backupInheritors
            : [formData.backupInheritors],
          [...selectedCharities, ...submitData.nonDefaultCharities]
        )
      );

      sendInheritorsBackupsWithoutValues(
        Array.isArray(submitData.backupInheritors)
          ? submitData.backupInheritors
          : [submitData.backupInheritors],
        [...selectedCharities, ...submitData.nonDefaultCharities]
      );
    } else {
      let submitData;

      if (formData.backupInheritors && Array.isArray(formData.backupInheritors)) {
        submitData = formData;
      } else if (formData.backupInheritors && !Array.isArray(formData.backupInheritors)) {
        submitData = {
          ...formData,
          backupInheritors: [formData.backupInheritors],
        };
      } else {
        submitData = { ...formData, backupInheritors: [] };
      }

      dispatch(
        addBackupInheritor(props.personID, submitData.backupInheritors, selectedCharities)
      );

      sendInheritorsBackupsWithoutValues(submitData.backupInheritors, selectedCharities);
    }

    history.push(props.redirectTo);
  }

  function selectCharity(e: any, id: string) {
    e.preventDefault();

    if (selectedCharities.includes(id)) {
      setSelectedCharities((prevState) => prevState.filter((ids) => ids !== id));
    } else {
      setSelectedCharities((prevState) => [...prevState, id]);
    }
  }
  function appendFn() {
    reset({ backupInheritors: allPeople });
  }

  function handleChooseInheritors(e: any) {
    e.preventDefault();

    setChosenInheritors(e.target.name);
  }

  function addNewCharity() {
    setEditingCharity({ charityName: "" });

    toggleAddCharity();
  }

  function addNewInheritor() {
    setPersonSelected({ ...initialPerson, type: "inheritor" });

    toggleAddPerson();
  }

  async function sendInheritorsBackupsWithoutValues(
    backupInheritors: any[],
    selectedCharities: any[]
  ) {
    const inheritorWithBackups = inheritEstateRedux.inheritors.map((inheritor: any) =>
      inheritor.person === props.personID
        ? {
            ...inheritor,
            backupInheritors: [
              ...backupInheritors.map((p: string) => ({ person: p })),
              ...selectedCharities.map((ch: string) => ({ charity: ch })),
            ],
          }
        : inheritor
    );

    await sendInheritEstateMutation({
      inheritEstate: [...inheritorWithBackups, ...inheritEstateRedux.charities],
    });
  }

  async function sendInheritorsBackups() {
    const inheritorWithBackups = inheritEstateRedux.inheritors.map((inheritor: any) =>
      inheritor.person === props.personID
        ? {
            ...inheritor,
            [chosenInheritors]: true,
            [chosenInheritors === "otherInheritors" ? "theirKids" : "otherInheritors"]:
              false,
          }
        : inheritor
    );

    const { inheritEstate } =
      (await sendInheritEstateMutation({
        inheritEstate: [...inheritorWithBackups, ...inheritEstateRedux.charities],
      })) ?? {};

    dispatch(
      getInheritEstate(
        inheritEstate.filter((i: any) => i.person),
        inheritEstate.filter((i: any) => i.charity)
      )
    );

    history.push(props.redirectToNoShare);
  }

  useEffect(() => {
    if (Object.keys(inheritEstateRedux.charities).length) {
      setSelectedCharities(
        Object.keys(inheritEstateRedux.charities).filter((id: string) =>
          charitiesListIDs.has(id)
        )
      );

      setNonDefaultCharities(() =>
        inheritEstateRedux.charities.filter(
          (charity: any) => !charitiesListIDs.has(charity.charity)
        )
      );
    }
  }, []);

  return (
    <div className="estate form estate-backup-form">
      <SEO page="estate_backups" />

      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          title={`Pokud ${
            allPeopleById[props.personID].fullName
          } zemře před vámi, kdo by měl dostat jeho/její podíl?`}
          description={[
            "Pokud není náhradní dědic určen, nebo se dědictví stejně jako původní dědic nedožije, tento uvolněný podíl připadne poměrně ostatním dědicům, které jste vybral/a v předchozím kroku. Stane se tak ale pouze v případě, pokud jsou vaši dědicové povolání rovným dílem. V případě, že tomu tak není, uvolněný podíl se stane předmět dědění podle zákonné dědické posloupnosti a jednotlivých dědických tříd.",
          ]}
        >
          <FormButtons
            buttons={whoInheritsOptions.map((option, idx) => ({
              ...option,
              className: `single_button_section ${idx === 0 ? "first_button" : ""}`,
              active: chosenInheritors === option.name,
              activeClassName: "pressed",
            }))}
            onClick={handleChooseInheritors}
          />
        </FormInput>

        {chosenInheritors === "other" && (
          <>
            <FormInput
              title="Lidé"
              description={[
                "Jakou část která osoba dostane, budete moci zvolit v dalším kroku.",
              ]}
            >
              {allPeople.map((person: any, index: number) => (
                <PersonBox
                  key={person._id}
                  description={person.birth}
                  id={person._id}
                  name="backupInheritors"
                  person={person}
                  value={person._id}
                  personName={person.fullName}
                  checkbox={true}
                  register={register}
                />
              ))}

              <AddButton className="partner_add" onClick={addNewInheritor}>
                Přidat dědice
              </AddButton>
            </FormInput>

            <FormInput
              title="Neziskové organizace"
              description={[
                "Jakou část která organizace dostane, budete moci zvolit v dalším kroku.",
              ]}
            >
              <>
                <div className="charities-list">
                  <div className="charities-list-left">
                    {CharitiesList.slice(0, 3).map((el: any, index: number) => (
                      <div
                        onClick={(e) => selectCharity(e, el._id)}
                        className={`charities-box ${
                          selectedCharities.includes(el._id) ? "selected" : ""
                        }`}
                        key={index}
                      >
                        <div className="helper"></div>

                        <img style={el.style} alt={el.name} src={el.img} />
                      </div>
                    ))}
                  </div>

                  <div className="charities-list-right">
                    {CharitiesList.slice(3).map((el: any, index: number) => (
                      <div
                        onClick={(e) => selectCharity(e, el._id)}
                        className={`charities-box ${
                          selectedCharities.includes(el._id) ? "selected" : ""
                        }`}
                        key={index}
                      >
                        <div className="helper"></div>

                        <img style={el.style} alt={el.name} src={el.img} />
                      </div>
                    ))}
                  </div>
                </div>

                {nonDefaultCharities.map((charity, index) => (
                  <NonDefaultCharityBox
                    key={index}
                    charityName={charity.charity || charity}
                    name="nonDefaultCharities"
                    value={charity.charity || charity}
                    checkbox={true}
                    register={register}
                  />
                ))}

                <AddButton className="partner_add" onClick={addNewCharity}>
                  Přidat neziskovou organizaci
                </AddButton>
              </>
            </FormInput>
          </>
        )}

        <SaveButton loading={inheritEstateLoading} readyToRoll={!!chosenInheritors} />
      </form>

      {isAddPersonModalOpen && (
        <AddPerson
          toggle={toggleAddPerson}
          personType={personSelected.type}
          personSelected={personSelected}
          append={appendFn}
        />
      )}

      {isAddCharityModalOpen && (
        <AddCharity
          toggle={toggleAddCharity}
          editingCharity={editingCharity}
          nonDefaultCharities={nonDefaultCharities}
          setNonDefaultCharities={setNonDefaultCharities}
        />
      )}
    </div>
  );
};

export default BackupInheritor;
