import React from "react";
import { useHistory } from "react-router-dom";
import CheckSVG from "../../../Images/check.svg";
import "./Step.scss";

export interface StepPropsI {
  title: string;
  redirectTo: string;

  canBeEdited?: boolean;
  isCompleted?: boolean;
  content?: any[] | any;
  stepN?: string;
  startText?: string;
}

const Step = ({
  stepN,
  title,
  content,
  redirectTo,
  isCompleted,
  canBeEdited,
  startText = "Začít",
}: StepPropsI) => {
  const history = useHistory();

  const defaultText = <p>Doplňte prosím informace</p>;

  return (
    <div className={canBeEdited ? "step" : "step step-disabled"}>
      <div className="step-header">
        {stepN && <span>{stepN}</span>}

        {isCompleted && <img alt="Hotovo" src={CheckSVG} />}
      </div>
      <h2>{title}</h2>

      <div className="step-content">
        {content?.length > 0
          ? content.map((el: any, index: number) => <p key={index}>{el}</p>)
          : defaultText}
      </div>

      {canBeEdited ? (
        isCompleted ? (
          <button className="edit-done" onClick={() => history.push(redirectTo)}>
            Upravit
          </button>
        ) : (
          <button className="edit" onClick={() => history.push(redirectTo)}>
            {startText}
          </button>
        )
      ) : null}
    </div>
  );
};

export default Step;
