import React from 'react';
import { Link } from 'react-router-dom';

import './SurveyButton.scss';

export interface SurveyButtonI {
  to: string;
  text: string;
  subText?: string;
}
const SurveyButton = (props: SurveyButtonI) => {
  const { text, to, subText } = props;

  return (
    <Link className='survey-button save_continue' to={to}>
      <b>{text}</b>
      {subText && <p>{subText}</p>}
    </Link>
  );
};

export default SurveyButton;
