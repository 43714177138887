import React, { FC } from "react";
import { Helmet } from "react-helmet";

import { seoData, SeoDataPages } from "../../Data/seoData";

interface SEOI {
  page: SeoDataPages;
}

export const SEO: FC<SEOI> = ({ page }) => {
  return (
    <Helmet>
      {seoData[page].title.map((str: string, idx: number) => (
        <title key={idx}>{str}</title>
      ))}
      {seoData[page].meta.description.map((str: string, idx: number) => (
        <meta key={idx} name="description" content={str} />
      ))}
    </Helmet>
  );
};
