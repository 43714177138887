export const labelNames: { [key: string]: string } = {
  bank: "Jméno banky",
  pension: "Jméno instituce",
  insurance: "Jméno instituce",
  stocks: "Název společnosti/cenných papírů",
  business: "Jméno společnosti",
  other: "Popisek (Např.: peníze pod matrací)",
};

export const formNames: { [key: string]: string } = {
  bank: "bankName",
  pension: "pensionProvider",
  insurance: "insuranceProvider",
  stocks: "stocksCompany",
  property: "property",
  business: "ownedBusiness",
  other: "other",
};

export const assetsOptions = [
  {
    text: "Vyberte...",
    value: "",
  },
  {
    text: "Bankovní účet",
    value: "bank",
  },
  {
    text: "Penzijní připojištění",
    value: "pension",
  },
  {
    text: "Životní pojištění",
    value: "insurance",
  },
  {
    text: "Akcie a další cenné papíry",
    value: "stocks",
  },
  {
    text: "Nemovitost",
    value: "property",
  },
  {
    text: "Účast v obchodní společnosti",
    value: "business",
  },
  {
    text: "Ostatní",
    value: "other",
  },
];
