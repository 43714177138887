export const stripeReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case "STRIPE_INIT":
      return { ...state, stripe: action.payload };
    case "STRIPE_LOADING":
      return { ...state, loading: action.payload };
    case "STRIPE_ERRORS":
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};
