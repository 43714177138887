import { useSelector } from "react-redux";
import {
  allChildrenU18HaveGuardians,
  allPetsHaveGuardians,
} from "../Pages/Summary/SummaryFn";

const ALL_STEPS = [
  "aboutYou",
  "guardians",
  "assetsProperty",
  "estate",
  "gifts",
  "restingPlace",
] as const;

export type Steps = typeof ALL_STEPS;

type StepsCompleted = { [key in Steps[number]]: boolean };

export const useStepsCompleted = (): StepsCompleted => {
  const {
    aboutYouReducer: aboutYou,
    assetsReducer: assets,
    inheritEstateReducer: inheritEstate,
    restingPlaceReducer: restingPlace,
    giftsReducer: gifts,
  } = useSelector((state: any) => state);

  const childrenUnder18 = aboutYou.children.filter((el: any) => !el.over18);

  const pets = aboutYou.pets;

  const stepsCompleted: StepsCompleted = {
    aboutYou: aboutYou.marital_status.status !== "",
    guardians:
      !!aboutYou.marital_status.status &&
      allChildrenU18HaveGuardians(childrenUnder18) &&
      allPetsHaveGuardians(pets),
    assetsProperty: assets.length > 0,
    estate: inheritEstate.inheritors.length > 0,
    gifts: gifts.length > 0,
    restingPlace: !!restingPlace?.place,
  };

  return stepsCompleted;
};
