import { useCallback, useEffect } from "react";

interface useCloseModalOnEscapeI {
  toggle: () => void;
}

export const useCloseModalOnEscape = ({ toggle }: useCloseModalOnEscapeI) => {
  const escapeKeyPressed = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === "Escape") {
        toggle();
      }
    },
    [toggle]
  );

  useEffect(() => {
    document.addEventListener("keydown", escapeKeyPressed, false);

    return () => document.removeEventListener("keydown", escapeKeyPressed, false);
  }, [escapeKeyPressed]);
};
