import { store } from "../lib/reduxStore";
import * as AllActions from "./actions/index";

export function updateWholeRedux(will: any) {
  store.dispatch(AllActions.willIsLoading(true));

  store.dispatch(AllActions.aboutYou_names(will.names));
  store.dispatch(AllActions.aboutYou_address(will.address));
  store.dispatch(AllActions.aboutYou_maritalStatus(will.maritalStatus.status));
  store.dispatch(AllActions.aboutYou_editPartner(will.maritalStatus.partner));
  store.dispatch(AllActions.aboutYou_children(will.children));
  store.dispatch(AllActions.aboutYou_pets(will.pets));
  store.dispatch(AllActions.aboutYou_hasChildren(will.hasChildren));
  store.dispatch(AllActions.aboutYou_hasPets(will.hasPets));
  store.dispatch(AllActions.getAssets(will.assets));
  store.dispatch(AllActions.getRandomPeople(will.people));
  store.dispatch(
    AllActions.addRestingPlace(will.restingPlace.place, will.restingPlace.message)
  );
  store.dispatch(
    AllActions.getInheritEstate(
      will.inheritEstate.filter((inheritor: any) => inheritor.person),
      will.inheritEstate.filter((inheritor: any) => inheritor.charity),
      will.allowInheritorsToDecide
    )
  );
  store.dispatch(AllActions.addSpecialWish(will.specialWishes));
  store.dispatch(AllActions.getGifts(will.gifts));
  store.dispatch(AllActions.user_login(true));
  store.dispatch(AllActions.willIsLoading(false));
}
