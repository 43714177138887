import { useLazyQuery, useMutation } from "@apollo/client";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Alert } from "../../../Components/Form";
import { SEO } from "../../../Components/seo/Seo";
import { REGISTER_MUTATION } from "../../../GraphQL/Mutations";
import { LOGIN_QUERY } from "../../../GraphQL/Queries";
import { compareCookies } from "../../../Helpers";
import { useToastMessage } from "../../../hooks/useToastMessage";
import { user_logout } from "../../../Redux/actions";
import { updateWholeRedux } from "../../../Redux/UpdateStore";
import "../Login/LoginPage.scss";
import googleRating from "../../../Images/googleRating.svg";
import firmyRating from "../../../Images/firmyRating.svg";
import gdpr from "../../../Images/gdpr.svg";
import greenCheck from "../../../Images/greenCheck.svg";
import family from "../../../Images/family.png";

const RegisterPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { postToastMessage } = useToastMessage();

  const { register, handleSubmit, errors, setError, clearErrors } = useForm();

  const [
    registerUserMutation,
    { error: registerUserError, loading: registerUserLoading },
  ] = useMutation(REGISTER_MUTATION);
  const [loginUserQuery, { data: loginUserData }] = useLazyQuery(LOGIN_QUERY, {
    fetchPolicy: "no-cache",
  });

  const onSubmit = (formData: any, e: any) => {
    e.preventDefault();
    const { email, password, password_repeat } = formData;
    if (password_repeat !== password) {
      setError("password_repeat", {
        type: "validate",
        message: "Hesla se neshodují",
      });
      return;
    }
    sendRegisterMutation(email, password);
  };
  async function sendRegisterMutation(email: string, password: string) {
    try {
      const { data } = await registerUserMutation({
        variables: {
          email,
          password,
        },
      });
      if (data.register) {
        loginUserQuery({ variables: { email, password } });
        postToastMessage("Registrace proběhla úspěšně");
      }
    } catch (err) {}
  }

  useEffect(() => {
    if (loginUserData) {
      const { will, counter } = loginUserData.login;
      const authCookie = Cookies.get("logged");
      if (authCookie) {
        compareCookies()
          .then((res) => {
            updateWholeRedux(will);
            history.push(counter === 2 ? "/zacatek" : "/prehled");
          })
          .catch((err) => {
            dispatch(user_logout());
            history.push("/prihlaseni");
          });
      }
    }
  }, [loginUserData]);

  return (
    <div className="loginPage">
      <SEO page="registerPage" />

      <div className="loginWrap">
        <div className="leftSide">
          <img src={family} alt="family" className="familyPic" />
          <h1>Vše vyzkoušejte zdarma, nezávazně</h1>

          <ul>
            <li><img src={greenCheck} alt="" />Sepište plnohodnotnou závěť přes internet a postarejte se o své nejbližší</li>
            <li><img src={greenCheck} alt="" />Závěť lze vytvořit zdarma, bez kreditní karty. Platíte až těsně před tiskem</li>
            <li><img src={greenCheck} alt="" />Kdykoliv můžete kontaktovat naší podporu s jakýmikoliv dotazy</li>
          </ul>

          <div className="trust">
            <div className="left">
            </div>
            <div className="right">
              <p>Stovky spokojených zákazníků (4,95/5 průměrné hodnocení)</p>
              <div>
                <img src={googleRating} alt="googleRating" />
                <img src={firmyRating} alt="firmyRating" />
              </div>
            </div>
          </div>
        </div>

        <div className="rightSide">
          <form className="login_form" onSubmit={handleSubmit(onSubmit)}>
            {registerUserError?.message && (
              <Alert text={registerUserError?.message} />
            )}
            {errors?.password_repeat?.type === "validate" && (
              <Alert text={errors.password_repeat.message} />
            )}

            <div className="credentialsInput">
              <label>Váš e-mail</label>
              <input
                ref={register({
                  required: {
                    value: true,
                    message: "Vyplňte prosím e-mailovou adresu",
                  },
                })}
                name="email"
                type="email"
                onChange={() => clearErrors()}
                autoComplete="off"
                placeholder="Např.: honza@seznam.cz"
              />
            </div>

            <span className="formError">{errors.email?.message}</span>

            <div className="credentialsInput">
              <label>Vaše heslo</label>
              <input
                ref={register({
                  minLength: {
                    value: 6,
                    message: "Heslo musí mít minimálně 6 znaků",
                  },
                  required: {
                    value: true,
                    message: "Vyplňte prosím všechna pole",
                  },
                })}
                name="password"
                type="password"
                onChange={() => clearErrors()}
                autoComplete="off"
                placeholder=""
              />
            </div>

            <div className="credentialsInput">
              <label>Zopakujte prosím vaše heslo</label>
              <input
                ref={register({
                  required: { message: "Vyplňte prosím všechna pole", value: true },
                  minLength: {
                    message: "Heslo musí mít minimálně 6 znaků",
                    value: 6,
                  },
                })}
                name="password_repeat"
                type="password"
                autoComplete="off"
                placeholder=""
              />
            </div>

            <p>Heslo musí obsahovat alespoň 6 znaků.</p>

            <p>
              Přihlášením do aplikace Goodbye souhlasíte s{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://goodbye.cz/zaveti/obchodni-podminky/"
              >
                obchodními podmínkami
              </a>
            </p>
            <span className="formError">{errors.password?.message}</span>

            <div className="buttons">
              <button type="submit">
                {registerUserLoading ? "Načítání..." : "Zaregistrovat se a začít"}
              </button>

              <img src={gdpr} alt="gdpr" />
            </div>

            <p className="loginLink">
              Máte již založený účet? <Link to="/prihlaseni">Přihlaste se</Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
