export interface DefaultCharities {
  name: string;
  img: string;
  style: {};
  _id: string;
  type: "charity";
}

const charities: DefaultCharities[] = [
  {
    name: "Emoter",
    img: "/default_charities/emoterLogo.png",
    style: {},
    _id: "emoter",
    type: "charity",
  },
  {
    name: "Paraple",
    img: "/default_charities/paraple.png",
    style: {},
    _id: "paraple",
    type: "charity",
  },

  {
    name: "Pomozte Dětem",
    img: "/default_charities/pomoztedetem.png",
    style: {},
    _id: "pomozte_detem",
    type: "charity",
  },

  {
    name: "Amnesty International",
    img: "/default_charities/amnestyinternational.png",
    style: {},
    _id: "amnesty_international",
    type: "charity",
  },
  {
    name: "Červený Kříž",
    img: "/default_charities/cervenykriz.png",
    style: {},
    _id: "cerveny_kriz",
    type: "charity",
  },
  {
    name: "Člověk v Tísni",
    img: "/default_charities/clovekvtisni.png",
    style: {},
    _id: "clovek_v_tisni",
    type: "charity",
  },
  // {
  //   name: "Amelie",
  //   img: "/default_charities/Amelie_logo_2.png",
  //   style: {},
  //   _id: "amelie",
  //   type: "charity",
  // },
];

export default charities;
