import React, { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import GoBack from "../../../Components/Buttons/GoBack/GoBack";
import SaveButton from "../../../Components/Buttons/SaveButton";
import { AddButton, FormInput } from "../../../Components/Form";
import DeleteModal from "../../../Components/Modal/DeleteModal";
import PersonBox from "../../../Components/PersonBox/PersonBox";
import { SEO } from "../../../Components/seo/Seo";
import Tooltip from "../../../Components/Tooltip/Tooltip";
import { DELETE_ASSET_MUTATION } from "../../../GraphQL/Mutations";
import {
  useModal,
  useSaveResultToRedux,
  useSendMutation,
} from "../../../hooks";
import { AssetI, getAssets, initialEditingAsset } from "../../../Redux/actions";
import AddAsset from "./AddAsset";
import {
  assetsNameDictionary,
  assetsTypeDictionary,
  assetTooltip,
} from "./texts";

const AssetsPropertyPage = () => {
  const history = useHistory();
  const saveToRedux = useSaveResultToRedux();

  const [isAddAssetModalOpen, toggleAddAssetModal] = useModal();
  const [isDeleteAssetModalOpen, toggleDeleteAssetModal] = useModal();

  const [editingItem, setEditingItem] = useState<AssetI>(initialEditingAsset);

  const { sendMutationRequest: sendDeleteAssetMutation, loading } =
    useSendMutation(DELETE_ASSET_MUTATION);

  const assets = useSelector((state: any) => state.assetsReducer);

  const { control } = useForm();

  const { append } = useFieldArray({
    name: "assets",
    control,
  });

  function saveAndContinue(e: any) {
    e.preventDefault();

    history.push("/prehled");
  }

  function appendAccount(
    type: string,
    name: string,
    mortgage?: string,
    owner?: string
  ) {
    if (mortgage && owner) {
      append({ type, name, mortgage, owner });
    } else {
      switch (type) {
        case "other":
          append({ type, other: name });
          break;
        case "bank":
          append({ type, bankName: name });
          break;
        case "insurance":
          append({ type, insuranceProvider: name });
          break;
        case "pension":
          append({ type, pensionProvider: name });
          break;
        case "stocks":
          append({ type, stocksCompany: name });
          break;
        case "property":
          append({ type, address: name });
          break;
        case "business":
          append({ type, ownedBusiness: name });
          break;
        default:
          break;
      }
    }
  }

  function addAccount() {
    setEditingItem(initialEditingAsset);

    toggleAddAssetModal();
  }

  function editAccount(asset: any) {
    setEditingItem(asset);

    toggleAddAssetModal();
  }

  function deleteConfirmation(asset: any) {
    setEditingItem(asset);

    toggleDeleteAssetModal();
  }

  async function handleDeletion(id: string) {
    const { deleteAsset } = (await sendDeleteAssetMutation({ id })) ?? {};

    saveToRedux(getAssets, deleteAsset.assets);

    toggleDeleteAssetModal();
  }

  return (
    <div className="form assets_property_page">
      <SEO page="assets" />

      <GoBack />

      <h1 className="form-title">Váš majetek</h1>

      <form>
        <FormInput
          description={[
            "Zde můžete uvést, co vše tvoří váš majetek, který si přejete zůstavit. Může se jednat například o bankovní produkty (běžné účty, stavební spoření, atd.), cenné papíry, nemovitosti, vozidla nebo i osobní cennosti a šperky.",
            "Není třeba sdělovat jakékoliv citlivé údaje, k samotné identifikaci postačí jen základní informace a u bankovních produktů či cenných papírů pouze sdělení instituce, u kterých jsou vedeny (např.: běžný účet a stavební spoření - UniCredit, dluhopisy u společnosti UK Rentier Properties a.s., atd.)",
            "V případě, že by některá položka v budoucnu ve vašem vlastnictví již nebyla, není třeba se ničeho obávat a závěť nijak upravovat, k takové položce se později při projednávání pozůstalosti pouze nebude přihlížet.",
          ]}
        >
          {assets.map((el: any, index: number) => {
            return (
              <PersonBox
                person={el}
                key={index}
                name={`assets[${index}].value`}
                // @ts-ignore
                id={el._id}
                personName={el[assetsNameDictionary[el.type]]}
                description={assetsTypeDictionary[el.type]}
                edit={editAccount}
                deletePerson={deleteConfirmation}
              />
            );
          })}
        </FormInput>

        <AddButton className="partner_add" onClick={addAccount}>
          Přidat majetek
        </AddButton>

        <Tooltip id="assets" title="Majetek" description={assetTooltip} />

        <SaveButton
          loading={false}
          readyToRoll={true}
          handleContinue={saveAndContinue}
        />
      </form>

      {isAddAssetModalOpen && (
        <AddAsset
          toggle={toggleAddAssetModal}
          append={appendAccount}
          editingItem={editingItem}
        />
      )}

      {isDeleteAssetModalOpen && (
        <DeleteModal
          headerText="Odstranit majetek"
          toggleDeleteModal={toggleDeleteAssetModal}
          loading={loading}
          personSelected={editingItem}
          sendDeletion={handleDeletion.bind(null, editingItem._id)}
        />
      )}
    </div>
  );
};

export default AssetsPropertyPage;
