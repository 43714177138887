import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Alert } from "../../../Components/Form";
import PartnerModal from "../../../Components/Modal/PartnerModal";
import { PAY_WITH_TRANSFER_MUTATION } from "../../../GraphQL/Mutations";
import {
  GET_DISCOUNT_PRICE_QUERY,
  GET_PRICE_QUERY,
  GET_USED_CODE_QUERY,
} from "../../../GraphQL/Queries";
import { useModal } from "../../../hooks";
import Check from "../../../Images/greenCheck.svg";

import Phone from "../../../Images/phone.svg";
import Support from "../../../Images/podporaPicHeader.png";
import Zamek from "../../../Images/zamek.svg";
import { cardOptions, whatYouPayForText } from "./paymentTexts";
import { PayWithTransfer } from "./PayWithTransfer";
import { ReviewBox } from "./ReviewBox";

// @ts-ignore
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

const stripeURI =
  process.env.NODE_ENV === "production"
    ? "https://api.goodbye.cz/create-payment"
    : "http://localhost:4000/create-payment";

interface NotPaidPropsI {
  createFriendsCodeLoading: boolean;
  createFriendsCode: any;
  friendsCode: string;
  refetchPaidForWill: any;
}

const NotPaid = (props: NotPaidPropsI) => {
  const {
    data: usedCodeData,
    loading: usedCodeLoading,
    refetch: usedCodeRefetch,
  } = useQuery(GET_USED_CODE_QUERY, { fetchPolicy: "cache-and-network" });

  const [
    payWithTransferMutation,
    {
      loading: payWithTransferLoading,
      data: payWithTransferData,
      called: payWithTransferCalled,
    },
  ] = useMutation(PAY_WITH_TRANSFER_MUTATION, {
    fetchPolicy: "no-cache",
  });

  const [
    getDiscountPrice,
    {
      loading: useDiscountCodeLoading,
      data: useDiscountCodeData,
      error: useDiscountCodeError,
    },
  ] = useLazyQuery(GET_DISCOUNT_PRICE_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  const [getPriceQuery, { data: getPriceData, loading: getPriceLoading }] =
    useLazyQuery(GET_PRICE_QUERY, { fetchPolicy: "cache-and-network" });

  const [price, setPrice] = useState(2190);
  const [code, setCode] = useState("");

  const [isPartnerModalOpen, togglePartnerModal] = useModal();

  const [usedCode, setUsedCode] = useState({ codeType: "", amount: 0 });
  const [refCodeError, setRefCodeError] = useState("");
  const [variableSymbol, setVariableSymbol] = useState("");

  const userName = useSelector(
    (state: any) => state.aboutYouReducer.names.fullName
  );

  const handlePayment = async (e: any) => {
    const stripe = await stripePromise;

    const response = await fetch(stripeURI, {
      method: "POST",
      credentials: "include",
    });

    const session = await response.json();

    await stripe?.redirectToCheckout({
      sessionId: await session.id,
    });
  };

  useEffect(() => {
    if (payWithTransferData) {
      setVariableSymbol(payWithTransferData.payWithTransfer);
    }
  }, [payWithTransferData]);

  useEffect(() => {
    if (useDiscountCodeError) {
      setRefCodeError(useDiscountCodeError.message);

      setTimeout(() => setRefCodeError(""), 5000);
    }
  }, [useDiscountCodeError]);

  useEffect(() => {
    if (useDiscountCodeData) {
      setPrice(useDiscountCodeData.useRefferalCode);

      usedCodeRefetch();

      props.refetchPaidForWill();

      if (payWithTransferCalled) {
        payWithTransferMutation();
      }
    }
  }, [useDiscountCodeData]);

  useEffect(() => {
    if (getPriceData) {
      setPrice(getPriceData.getWill.price);
    }
  }, [getPriceData]);

  useEffect(() => {
    if (usedCodeData) {
      setUsedCode(usedCodeData.usedCode);
    }
  }, [usedCodeData]);

  useEffect(() => {
    getPriceQuery();
  }, []);

  return (
    <div className="payment_page">
      <div className="payment-left">
        <div className="summaryBox">
          <h1>Vaše objednávka</h1>

          <ul>
            {whatYouPayForText.map((text, idx) => (
              <li key={idx}>
                <img alt="" src={Check} />
                <p>{text}</p>
              </li>
            ))}
          </ul>

          {usedCode?.codeType ? (
            <>
              <div className="payment_item payment_item-1">
                <span>Závěť:</span>

                <span className="willPrice crossed">2190 Kč</span>
              </div>

              <div className="payment_item payment_item-2">
                <span>Sleva:</span>

                {usedCodeLoading ? (
                  <span>Načítáme...</span>
                ) : (
                  <span>{`- ${usedCode.amount} Kč`}</span>
                )}
              </div>

              <div className="payment_item payment_item-3">
                <span>Celkem:</span>

                {getPriceLoading ? (
                  <span>Načítáme...</span>
                ) : (
                  <div>
                    <span>{`${price} Kč `} </span>
                    (cena s DPH)
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="payment_item">
              <span>Závěť:</span>
              <div>
                <span
                  className={`willPrice ${usedCode?.codeType && "crossed"}`}
                >
                  {price} Kč{" "}
                </span>
                (cena s DPH)
              </div>
            </div>
          )}

          <div className="payment_discount-code">
            {useDiscountCodeData && (
              <p>Váš slevový kupón byl úspěšně uplatněn.</p>
            )}

            <div className="payment_discount-code-inner">
              <input
                type="text"
                placeholder="Slevový kupón"
                onChange={(e) => setCode(e.target.value)}
              />

              {useDiscountCodeLoading ? (
                <button
                  className="button"
                  role="link"
                  id="checkout-button"
                  onClick={() => {}}
                >
                  Načítáme...
                </button>
              ) : (
                <button
                  className="button"
                  role="link"
                  id="checkout-button"
                  onClick={() =>
                    getDiscountPrice({ variables: { code: code } })
                  }
                >
                  Použít
                </button>
              )}
            </div>

            {refCodeError && <Alert text={refCodeError} />}
          </div>
        </div>

        <ReviewBox />
      </div>

      <div className="payment-right">
        <div className="karta">
          <div className="heading">
            <h2>Platba kartou</h2>

            <div>
              {cardOptions.map((card) => (
                <img key={card.alt} alt={card.alt} src={card.src} />
              ))}
            </div>
          </div>

          <div className="payment_confirmation">
            <button className="button" onClick={(e: any) => handlePayment(e)}>
              Zaplatit
            </button>

            <p>
              <img alt="" src={Zamek} />
              Zabezpečeno pomocí{" "}
              <a
                href="https://stripe.com/"
                style={{ textDecoration: "underline", color: "#808080" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                Stripe
              </a>
            </p>
          </div>
        </div>

        <hr />

        <PayWithTransfer
          payWithTransferMutationWasCalled={payWithTransferCalled}
          payWithTransferLoading={payWithTransferLoading}
          payWithTransferMutation={payWithTransferMutation}
          userName={userName}
          variableSymbol={variableSymbol}
          willPrice={price}
        />

        <hr />

        <div className="support">
          <img alt="Podpora" className="supportImg" src={Support} />

          <div className="suppText">
            <div className="time">
              <div className="greenDot" />

              <span>K dispozici 24/7</span>
            </div>

            <div className="phone">
              <img alt="Ikonka telefonu pro podporu" src={Phone} />

              <span>
                <span>315 558 136</span> — Pomocná linka
              </span>
            </div>
          </div>
        </div>
      </div>

      {isPartnerModalOpen && <PartnerModal toggle={togglePartnerModal} />}
    </div>
  );
};

export default NotPaid;

/* <InviteFriends
          createFriendsCodeLoading={props.createFriendsCodeLoading}
          createFriendsCode={props.createFriendsCode}
          friendsCode={props.friendsCode}
        /> */

/* <div className="payment-invite_partner">
          <p>
            Pozvěte svého partnera aby si také vytvořil závěť.{" "}
            <a onClick={() => togglePartnerModal()}>
              Pozvat (ušetříte 1 000 Kč).
            </a>
          </p>
        </div> */

/* <div className="payment-reccurring_payments">
          <h4>Neomezené úpravy</h4>
          <p>
            Naše životy se neustále mění. Proto počítáme s tím, že ještě někdy
            budete vaši závěť chtít poupravit. To vše za 190 Kč za osobu (první
            rok zdarma).
            <br />
            <br />
            Každá změna je kontrolována našimi specialisty.
          </p>
          <hr />
          <div>
            <span>První rok:</span>
            <span>Zdarma</span>
          </div>
          <div>
            <span>Od {todayString}:</span>
            <span>190 Kč</span>
          </div>
          <p>
            30 dní před platbou vám dáme vědět, abyste ji mohli případně včas
            zrušit.
          </p>
        </div> */
