import React, { FC } from "react";

interface PrepaidBubbleI {
  togglePrepaidModal: () => void;
}

export const PrepaidBubble: FC<PrepaidBubbleI> = ({ togglePrepaidModal }) => {
  return (<>
    <div className="invite-partner prepaid-bubble">
      <h3>
        Předplaťte si pohřeb od 990 Kč měsíčně
      </h3>
      <p>
        Plánujte dopředu a ušetřete svým nejbližším starosti a stres z plánování pohřbu. Spolehlivě a za transparentní cenu.
      </p>
      <button onClick={() => togglePrepaidModal()}>Zjisit více</button>
    </div>
    </>
  );
};
