export const aboutYouReducer = (
  state = {
    names: {},
    address: {},
    marital_status: { partner: {}, status: "" },
    hasChildren: null,
    children: [],
    hasPets: null,
    pets: [],
  },
  action: any
) => {
  switch (action.type) {
    case "ABOUT_YOU_NAMES":
      return { ...state, names: action.payload };
    case "ABOUT_YOU_ADDRESS":
      const newAddress = action.payload;
      return { ...state, address: newAddress };

    case "ABOUT_YOU_NEW_PARTNER":
      return {
        ...state,
        marital_status: {
          status: state.marital_status.status,
          partner: action.payload,
        },
      };
    case "ABOUT_YOU_EDIT_PARTNER":
      return {
        ...state,
        marital_status: {
          status: state.marital_status.status,
          partner: { ...state.marital_status.partner, ...action.payload },
        },
      };
    case "ABOUT_YOU_DELETE_PARTNER":
      return {
        ...state,
        marital_status: {
          status: state.marital_status.status,
          partner: { _id: null },
        },
      };
    case "ABOUT_YOU_STATUS":
      return {
        ...state,
        marital_status: {
          ...state.marital_status,
          status: action.payload,
        },
      };
    case "ABOUT_YOU_CHILDREN":
      return {
        ...state,
        children: [...action.payload].map((child: any) => ({
          ...child,
          type: "child",
        })),
      };
    case "ABOUT_YOU_PETS":
      return {
        ...state,
        pets: action.payload.map((pet: any) => ({ ...pet, type: "pet" })),
      };
    case "HAS_CHILDREN":
      return {
        ...state,
        hasChildren: action.payload,
      };
    case "HAS_PETS":
      return {
        ...state,
        hasPets: action.payload,
      };
    // case 'ABOUT_YOU_PETS_UPDATE':
    //   return {...state, pets: state.pets.map((el: any) => el._id === action.payload._id ? {...state.} :el)}
    default:
      return state;
  }
};
