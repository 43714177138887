import React from "react";
import Phone from "../../../Images/phone-call.svg";
import SupportPic from "../../../Images/podporaPicHeader.png";

import {
  CUSTOMER_PHONE_NUMBER,
  CUSTOMER_PHONE_NUMBER_DISPLAY,
} from "../../../lib/constants";

const CustomerCarePhone = ({ logged }: { logged: boolean }) => (
  <div className={`right ${!logged ? "logout_button-right-logged" : null}`}>

    <div className="available">
      <div className="blinking_dot"></div>
      <span>Jsme k dispozici</span>
    </div>

    <img className="customer_support" src={SupportPic} alt="customer support" />

    <a className="tel" href={`tel:00420${CUSTOMER_PHONE_NUMBER}`}>
      <img className="phone_img" src={Phone} alt="phone icon" />
      <span>{CUSTOMER_PHONE_NUMBER_DISPLAY}</span>
    </a>
  </div>
);

export default CustomerCarePhone;