import React from 'react';

import './PersonBox.scss';

interface PersonBoxI {
  id: string;
  name: string;
  person: any;
  personName: string;
  description: string;
  edit?: any;
  register?: any;
  value?: any;
  onChange?: any;
  input?: any;
  deletePerson?: any;
  checkbox?: boolean;
}
const allowedKeyCodes = [
  37,
  38,
  39,
  40,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  188,
  // 190,period char
  96,
  97,
  98,
  99,
  100,
  101,
  102,
  103,
  104,
  105,
  8,
  9,
  46,
];

const PersonBox = (props: PersonBoxI) => {
  const {
    id,
    name,
    person,
    personName,
    description,
    register,
    checkbox,
    edit,
    value,
    input,
    onChange,
    deletePerson,
  } = props;
  function preventLetters(e: any) {
    const { keyCode } = e;
    if (!allowedKeyCodes.includes(keyCode)) {
      e.preventDefault();
      return;
    }
  }
  return (
    <div className="personBox">
      <div className="personBox_txt">
        <h2>{personName}</h2>
        <p>{description}</p>
      </div>
      <div className="personBox_buttons">
        {edit && (
          <button className="editPerson" type="button" onClick={() => edit(person)}>
            Upravit
          </button>
        )}
        {deletePerson && (
          <button className="deletePerson" type="button" onClick={() => deletePerson(person)}>
            Odstranit
          </button>
        )}
        {input ? (
          <div>
            <input
              type="number"
              step=".01"
              id={id}
              name={name}
              onKeyDown={(e) => preventLetters(e)}
              onChange={onChange ? (e) => onChange(e) : () => {}}
              ref={register}
            />{' '}
            <span>%</span>
          </div>
        ) : null}
        {register && name && checkbox ? (
          <label className="checkbox-label checkbox-label-2">
            <input type="checkbox" name={name} value={value} ref={register} />
            <div className="checkbox-label-box"></div>
          </label>
        ) : null}
      </div>
    </div>
  );
};

interface NonDefaultCharityBoxI {
  charityName: string;
  editCharity?: (name: string) => any;
  onChange?: (e: any) => any;
  input?: any;
  register?: any;
  name?: string;
  checkbox?: boolean;
  value?: any;
  deleteCharity?: any;
}
export const NonDefaultCharityBox = (props: NonDefaultCharityBoxI) => {
  const {
    charityName,
    editCharity,
    name,
    input,
    checkbox,
    value,
    onChange,
    register,
  } = props;
  return (
    <div className="personBox">
      <div className="personBox_txt">
        <h2>{charityName}</h2>
        <p>Nezisková organizace</p>
      </div>
      <div className="personBox_buttons">
        {editCharity && (
          <button className="editPerson" type="button" onClick={() => editCharity(charityName)}>
            Upravit
          </button>
        )}
        {props.deleteCharity && (
          <button className="deletePerson" type="button" onClick={() => props.deleteCharity(charityName)}>
            Odstranit
          </button>
        )}
        {input && (
          <div>
            <input
              type="text"
              step=".01"
              name={charityName}
              onChange={onChange ? (e) => onChange(e) : () => {}}
              ref={register}
            />{' '}
            <span>%</span>
          </div>
        )}
        {register && name && checkbox ? (
          <label className="checkbox-label">
            <input type="checkbox" name={name} value={value} ref={register} />
            <div className="checkbox-label-box"></div>
          </label>
        ) : null}
      </div>
    </div>
  );
};

export default PersonBox;
