import React from "react";
import { useHistory } from "react-router-dom";
import { AddButton } from "../../../Components/Form";

interface GiftContainerPropsI {
  title: string;
  info: string[];
  icon?: any;
  edit?: any;
  remove?: any;
  add?: any;
  id?: String;
}

const GiftContainer = (props: GiftContainerPropsI) => {
  const history = useHistory();

  return (
    <div className="gift_container">
      {props.icon && (
        <div className="gift_icon">
          <img src={props.icon} alt="" />
        </div>
      )}

      <div className="gift_text">
        <h4>{props.title}</h4>

        {props.info.map((info: string, idx: number) => (
          <p key={idx}>{info}</p>
        ))}
      </div>

      {props?.add ? (
        <div className="gift_buttons">
          <AddButton
            onClick={() => history.push(props.add)}
            className="gift_button-add"
          >
            Přidat
          </AddButton>
        </div>
      ) : (
        <div className="gift_buttons">
          <AddButton
            onClick={() => history.push(props.edit)}
            className="gift_button-edit"
          >
            Upravit
          </AddButton>

          <AddButton
            onClick={() => props.remove(props.id, props.title)}
            className="gift_button-remove"
          >
            Odebrat
          </AddButton>
        </div>
      )}
    </div>
  );
};

export default GiftContainer;
