import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import styled from "styled-components";
import Footer from "./Components/Footer/Footer";
import LoadingScreen from "./Components/LoadingScreen/Loading";
import { allowedRoutesRegex } from "./Helpers/constants/allowedRoutes";
import Introduction from "./Pages/Introduction/Introduction";
import LoginPage from "./Pages/UnauthenticatedRoutes/Login/LoginPage";
import ResetPassword from "./Pages/UnauthenticatedRoutes/ForgotPassword/ResetPassword";
import SendEmail from "./Pages/UnauthenticatedRoutes/ForgotPassword/SendEmail";
import Payment from "./Pages/Other/Payment/Payment";
import Settings from "./Pages/Settings/Settings";
import Steps from "./Pages/Steps/index";
import Summary from "./Pages/Summary/Summary";
import RegisterConfirm from "./Pages/UnauthenticatedRoutes/Register/RegisterConfirm";
import RegisterPage from "./Pages/UnauthenticatedRoutes/Register/RegisterPage";
import RegisterSuccess from "./Pages/UnauthenticatedRoutes/Register/RegisterSuccess";
import AdminPage from "./Pages/AdminPanel/AdminPage";

const Wrapper = styled.div`
  min-height: calc(100vh - 55px - 80px - 46px);
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;

  @media (min-width: 900px) {
    padding-top: 60px;
    padding-bottom: 60px;
    min-height: calc(100vh - 55px - 120px - 46px);

  }
`;

interface AppRoutesI {
  cookieIsBeingCompared: boolean;
}

const AppRoutes = (props: AppRoutesI) => {
  const history = useHistory();

  const logged = useSelector((state: any) => state.authenticationReducer);
  const UIStateRedux = useSelector((state: any) => state.UIStateReducer);

  useEffect(() => {
    if (!logged && !props.cookieIsBeingCompared) {
      const path = history.location.pathname;
      if (!allowedRoutesRegex.some((route) => route.test(path))) {
        history.push("/prihlaseni");
      }
    }
  }, [logged, props.cookieIsBeingCompared]);

  return (
    <>
      {props.cookieIsBeingCompared || UIStateRedux.willIsLoading ? (
        <LoadingScreen />
      ) : (
        <Wrapper>
          {logged ? (
            // in app routes
            <>
              <Switch>
                <Redirect from="/" to="/prehled" exact />
                <Redirect from="/prihlaseni" to="/prehled" exact />
                <Redirect from="/registrace" to="/prehled" exact />
                <Route path="/zacatek" component={Introduction} />
                <Route path="/prehled" component={Summary} />
                <Route path="/platba" component={Payment} />
                <Route path="/nastaveni" component={Settings} />
                <Steps />
              </Switch>
            </>
          ) : (
            // Not logged in routes
            <Switch>
              <Redirect from="/" to="/prihlaseni" exact />
              <Route path="/potvrzeni-mail/(.*)" component={RegisterConfirm} />
              <Route exact path="/admin" component={AdminPage} />
              <Route exact path="/prihlaseni" component={LoginPage} />
              <Route path="/registrace-uspech" component={RegisterSuccess} />
              <Route exact path="/registrace" component={RegisterPage} />
              <Route exact path="/zapomenute-heslo" component={SendEmail} />
              <Route exact path="/obnova-hesla/(.*)" component={ResetPassword} />
            </Switch>
          )}
        </Wrapper>
      )}
      {!UIStateRedux.willIsLoading && <Footer />}
    </>
  );
};

export default AppRoutes;
