export const seoData: {
  [key in SeoDataPages]: {
    title: string[];
    meta: {
      description: string[];
    };
  };
} = {
  summary: {
    title: ["Přehled | Goodbye.cz"],
    meta: {
      description: ["meta tag"],
    },
  },
  loginPage: {
    title: ["Přihlásit se | Goodbye.cz"],
    meta: {
      description: [""],
    },
  },
  registerPage: {
    title: ["Registrace | Goodbye.cz"],
    meta: {
      description: [""],
    },
  },
  names: {
    title: ["Obecné informace | Goodbye.cz"],
    meta: {
      description: [""],
    },
  },
  basics: {
    title: ["Adresa | Goodbye.cz"],
    meta: {
      description: [""],
    },
  },
  partner: {
    title: ["Partner | Goodbye.cz"],
    meta: {
      description: [""],
    },
  },
  children: {
    title: ["Děti | Goodbye.cz"],
    meta: {
      description: [""],
    },
  },
  pets: {
    title: ["Domácí zvířata | Goodbye.cz"],
    meta: {
      description: [""],
    },
  },
  guardians_choose: {
    title: ["Poručenství, opatrovnictví | Goodbye.cz"],
    meta: {
      description: [""],
    },
  },
  guardians_responsibility: {
    title: ["Rodičovská odpovědnost | Goodbye.cz"],
    meta: {
      description: [""],
    },
  },
  assets: {
    title: ["Váš majetek | Goodbye.cz"],
    meta: {
      description: [""],
    },
  },
  estate_introduction: {
    title: ["Rozdělení pozůstalosti | Goodbye.cz"],
    meta: {
      description: [""],
    },
  },
  estate_people: {
    title: ["Vyberte dědice | Goodbye.cz"],
    meta: {
      description: [""],
    },
  },
  estate_charities: {
    title: ["Neziskové organizace, charity | Goodbye.cz"],
    meta: {
      description: [""],
    },
  },
  estate_share: {
    title: ["Rozdělení majetku | Goodbye.cz"],
    meta: {
      description: [""],
    },
  },
  estate_backups: {
    title: ["Náhradní dědici | Goodbye.cz"],
    meta: {
      description: [""],
    },
  },
  estate_backups_share: {
    title: ["Rozdělení náhradníků | Goodbye.cz"],
    meta: {
      description: [""],
    },
  },
  restingPlace: {
    title: ["Poslední přání | Goodbye.cz"],
    meta: {
      description: [""],
    },
  },
  gifts: {
    title: ["Odkazy, dárky | Goodbye.cz"],
    meta: {
      description: [""],
    },
  },
  payment: {
    title: ["Platba | Goodbye.cz"],
    meta: {
      description: [""],
    },
  },
};

export type SeoDataPages =
  | "summary"
  | "loginPage"
  | "registerPage"
  | "names"
  | "basics"
  | "partner"
  | "children"
  | "pets"
  | "guardians_choose"
  | "guardians_responsibility"
  | "assets"
  | "estate_introduction"
  | "estate_people"
  | "estate_charities"
  | "estate_share"
  | "estate_backups"
  | "estate_backups_share"
  | "restingPlace"
  | "gifts"
  | "payment";
