import React from "react";
import { useSelector } from "react-redux";
import "./snackbar.scss";

const Snackbar = () => {
  const snackbarsRedux = useSelector((state: any) => state.snackbarReducer);

  return (
    <div className={`snackbar ${snackbarsRedux.isVisible ? "visible" : ""}`}>
      <span>{snackbarsRedux.message}</span>
    </div>
  );
};

export default Snackbar;
