export const guardianUnder18SelectedText =
  "Poručníkem se může stát pouze někdo, kdo už dosáhl plnoletosti (18 let). Při psaní závěti můžete zvolit i osobu, která zatím plnoletá není (např. staršího sourozence), ale nedoporučujeme to. V případě vašeho úmrtí dřív než Vámi vybraný poručník dosáhne 18 let by se totiž soud musel rozhodnout jinak než si přejete.";

export const textDictionary: { [key: string]: string[] } = {
  pet: [
    "Zde zvolíte, kdo by se měl postarat o vašeho mazlíčka.",
    "Nebojte se zvolit i více osob pro případ, že by některá z nich vaše přání nemohla splnit.",
  ],
  child: [
    "V případě vašeho úmrtí, se vaše nezletilé dítě dostane do péče další osoby s rodičovskou odpovědností (nejčastěji druhý rodič). Pro případ, že byste v době své smrti byli jedinou osobou, které náleží rodičovská odpovědnost, je možné určit, kdo by se měl do dovršení zletilosti vašeho dítěte stát jeho poručníkem. Pokud by taková situace potom nastala, soud vaše přání při výběru poručníka zohlední a danou osobu v případě potřeby jmenuje.",
    "Jako možného poručníka tedy nevybírejte druhého rodiče dítěte. V případě, že bude v době vaší smrti druhý rodič naživu a nebude soudem nijak omezen na své svéprávnosti, jmenování poručníka nebude potřeba. Výkon rodičovské odpovědnosti bude totiž jako zákonný zástupce dítěte automaticky vykonávat.",
    "Dobrý nápad je tedy vybrat jednu nebo i dvě osoby (zpravidla manžele), kterým byste rád/a v případě vaší smrti svěřil/a vaše dítě.",
  ],
};
