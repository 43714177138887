import React from "react";

import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <footer>

        <p>© 2024 Goodbye s.r.o. Všechna práva vyhrazena.</p>
        <a
          rel="noreferrer noopenner"
          target="_blank"
          href="https://goodbye.cz/zaveti/obchodni-podminky/"
        >
          Obchodní podmínky
        </a>
      </footer>
    </div>
  );
};

export default Footer;
