export const authenticationReducer = (state = false, action: any) => {
  switch (action.type) {
    case "USER_LOGIN":
      return true;
    case "USER_LOGOUT":
      return false;
    default:
      return state;
  }
};
