import React from "react";
import Person from "../../../Images/alena.png";
import Stars from "../../../Images/stars.svg";

export const ReviewBox = () => {
  return (
    <div className="reviewBox">
      <div className="person">
        <img alt="Alena Chalupová" src={Person} />

        <div>
          <span>Alena, Zákaznice</span>

          <img alt="Hodnocení 5/5" src={Stars} />
        </div>
      </div>

      <div className="text">
        “Líbí se mi, že si závěť můžu udělat v klidu doma a nemusím kvůli tomu
        nikam jezdit. Ještě za rozumnou cenu... Doporučuji”
      </div>
    </div>
  );
};
