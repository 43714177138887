import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useStepsCompleted } from "../../hooks";
import { getGiftsContent, getRestingPlaceContent } from "../../Pages/Summary/SummaryFn";
import Step from "./step/Step";

interface OptionalStepsI {}

export const OptionalSteps: FC<OptionalStepsI> = (props) => {
  const { giftsReducer: gifts, restingPlaceReducer: restingPlace } = useSelector(
    (state: any) => state
  );

  const stepsCompleted = useStepsCompleted();

  return (
    <>
      <Step
        stepN="Volitelné"
        title="Odkazy a dárky"
        redirectTo="/odkazy"
        canBeEdited={stepsCompleted.estate}
        isCompleted={stepsCompleted.gifts}
        content={getGiftsContent(gifts)}
      />
      <Step
        stepN="Volitelné"
        title="Poslední přání"
        redirectTo="/posledni-prani"
        canBeEdited={stepsCompleted.estate}
        isCompleted={stepsCompleted.restingPlace}
        content={getRestingPlaceContent(restingPlace)}
      />
      {stepsCompleted.estate && (
        <Step
          stepN=""
          title="A máte hotovo!"
          redirectTo="/platba"
          isCompleted={false}
          canBeEdited={true}
          content={["Nechte si vše zkontrolovat a získejte platný dokument"]}
          startText="Dokončit závěť"
        />
      )}
    </>
  );
};
