import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import GoBack from "../../../../Components/Buttons/GoBack/GoBack";
import SaveButton from "../../../../Components/Buttons/SaveButton";
import { AddButton, Alert, FormInput } from "../../../../Components/Form";
import DeleteModal from "../../../../Components/Modal/DeleteModal";
import AddPerson from "../../../../Components/PersonBox/AddPerson";
import PersonBox from "../../../../Components/PersonBox/PersonBox";
import { SEO } from "../../../../Components/seo/Seo";
import Tooltip from "../../../../Components/Tooltip/Tooltip";
import { tooltipData } from "../../../../Data/tooltipData";
import {
  CHANGE_MARITAL_STATUS_MUTATION,
  DELETE_PARTNER_MUTATION,
} from "../../../../GraphQL/Mutations";
import {
  useIsReadyToContinue,
  useModal,
  useSaveResultToRedux,
  useSendMutation,
} from "../../../../hooks";
import {
  aboutYou_children,
  aboutYou_deletePartner,
  aboutYou_maritalStatus,
  aboutYou_pets,
  getGifts,
  getInheritEstate,
  initialPerson,
} from "../../../../Redux/actions/";
import "./Partner.scss";
import { buttonsStatus } from "./partnerData";

const Partner = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const { register, handleSubmit, reset } = useForm();

  const saveToRedux = useSaveResultToRedux();

  const {
    sendMutationRequest: deletePartnerMutation,
    loading: deletePartnerLoading,
  } = useSendMutation(DELETE_PARTNER_MUTATION);

  const {
    sendMutationRequest: sendChangeMaritalStatusMutation,
    loading: changeMaritalStatusLoading,
  } = useSendMutation(CHANGE_MARITAL_STATUS_MUTATION);

  const [status, setStatus] = useState("none");
  const [error, setError] = useState("");

  const [personSelected, setPersonSelected] = useState<any>({
    ...initialPerson,
    type: "partner",
  });

  const [isAddPersonModalOpen, toggleAddPersonModal] = useModal();
  const [isDeleteModalOpen, toggleDeleteModal] = useModal();

  const marital_status = useSelector(
    (state: any) => state.aboutYouReducer.marital_status
  );

  const noPartner =
    status === "single" || status === "divorced" || status === "widowed";

  const isReadyToContinue = useIsReadyToContinue({
    conditions: [noPartner, !noPartner && marital_status.partner.fullName],
  });

  const onSubmit = async (formData: any, e: any) => {
    e.preventDefault();

    if (!marital_status.status && (!status || status === "none")) {
      setError("Než budete pokračovat, musíte si vybrat stav");

      return;
    }

    if (!marital_status.partner.fullName && !noPartner) {
      setError("Než budete pokračovat, musíte vybrat partnera");

      return;
    }

    if (noPartner && marital_status.partner._id) {
      sendDeletePartnerMutation(false);
    }

    const changeMaritalStatusResult = await sendChangeMaritalStatusMutation({
      status,
    });

    saveAndRedirect(
      aboutYou_maritalStatus,
      changeMaritalStatusResult?.changeMaritalStatus
    );
  };

  const handleStatusClick = (e: any) => {
    e.preventDefault();

    setStatus(e.target.name);
  };

  function addPartner() {
    setPersonSelected({ ...initialPerson, type: "partner" });

    toggleAddPersonModal();
  }

  function editPartner() {
    setPersonSelected({ ...marital_status.partner, type: "partner" });

    toggleAddPersonModal();
  }

  function deleteConfirmation() {
    setPersonSelected(marital_status.partner);

    toggleDeleteModal();
  }

  function saveAndRedirect(action: any, data?: any) {
    saveToRedux(action, data);

    history.push("/o-vas/deti");
  }

  async function sendDeletePartnerMutation(
    isDeletingFromModal: boolean = true
  ) {
    try {
      const { deletePartner } =
        (await deletePartnerMutation({
          id: marital_status.partner._id,
        })) ?? {};

      const { children, pets, gifts, inheritEstate } = deletePartner;

      dispatch(aboutYou_deletePartner());
      dispatch(aboutYou_children(children));
      dispatch(aboutYou_pets(pets));
      dispatch(getGifts(gifts));
      dispatch(
        getInheritEstate(
          inheritEstate.filter((i: any) => i.person),
          inheritEstate.filter((i: any) => i.charity)
        )
      );

      if (isDeletingFromModal) {
        toggleDeleteModal();
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (noPartner) {
      setError("");
    } else {
      setPersonSelected(marital_status.partner);

      setError("");
    }
  }, [marital_status.partner, noPartner]);

  useEffect(() => {
    setStatus(marital_status.status);

    if (marital_status.partner) {
      setPersonSelected(marital_status.partner);

      reset({ partnerSelected: marital_status.partner ? true : false });
    }
  }, []);

  return (
    <div className="form">
      <SEO page="partner" />

      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          title="Jaký je váš rodinný stav?"
          description={[
            "Vyberte si jaký je váš rodinný stav, i když víte, že se brzy může změnit. Vždy ho u nás můžete dodatečně upravit.",
          ]}
        >
          <div className="buttons_section">
            {buttonsStatus.map((el, index) => (
              <button
                key={index}
                type="button"
                onClick={(e) => handleStatusClick(e)}
                name={el.name}
                className={`single_button_section ${
                  status === el.name ? "pressed" : ""
                } ${index === 0 ? "first_button" : ""}`}
              >
                {el.text}
              </button>
            ))}
          </div>
        </FormInput>





        {(status === "partner") &&
          <Tooltip
            id="relationshipTooltip"
            title="Rodinný stav"
            description={["V případě, že si přejete, aby váš partner cokoliv dědil, je potřeba ho do závěti uvést stejně jako ostatní dědice, váš partner totiž pohledem práva nemá oproti ostatním dědicům v závěti nijak privilegované postavení."]}
          />
        }

        {(status === "married") &&
          <Tooltip
            id="relationshipTooltip"
            title="Rodinný stav"
            description={["V případě, že v závěti pořídíte o všem svém majetku, dědickým titulem (právním důvodem) na základě kterého bude Vámi uvedeným osobám svědčit dědické právo bude pouze závěť, nikoliv zákon a nedojde tedy k dědění dle zákonné posloupnosti. Pokud si přejete část majetku přenechat i vašemu manželovi je proto potřeba partnera do závěti uvést stejně jako ostatní dědice."]}
          />
        }

        {(status === "civil_partnership") &&
          <Tooltip
            id="relationshipTooltip"
            title="Rodinný stav"
            description={["V případě, že v závěti pořídíte o všem svém majetku, dědickým titulem (právním důvodem) na základě kterého bude Vámi uvedeným osobám svědčit dědické právo bude pouze závěť, nikoliv zákon a nedojde tedy k dědění dle zákonné posloupnosti. Pokud si přejete část majetku přenechat i vašemu registrovanému partnerovi je proto potřeba partnera do závěti uvést stejně jako ostatní dědice."]}
          />
        }

        {(status === "engaged") &&
          <Tooltip
            id="relationshipTooltip"
            title="Rodinný stav"
            description={["V případě, že si přejete vašemu snoubenci cokoliv ze svého majetku přenechat, je potřeba ho do závěti uvést stejně jako ostatní dědice, váš snoubenec totiž pohledem práva nemá v závěti oproti ostatním dědicům nijak privilegované postavení."]}
          />
        }

        {(status === "married_separated") &&
          <Tooltip
            id="relationshipTooltip"
            title="Rodinný stav"
            description={["V případě, že v závěti pořídíte o všem svém majetku, dědickým titulem (právním důvodem) na základě kterého bude Vámi uvedeným osobám svědčit dědické právo bude pouze závěť, nikoliv zákon a nedojde tedy k dědění dle zákonné posloupnosti. váš manžel nemá pohledem práva v závěti oproti ostatním dědicům nijak privilegované postavení. Pokud si mu přejete část svého majetku přenechat, je potřeba ho do závěti uvést stejně jako ostatní dědice, pokud je tomu naopak, jednoduše ho v závěti nezmiňujte."]}
          />
        }

        











        {noPartner ? null : (
          <FormInput title="Kdo je váš partner?">
            {marital_status.partner.fullName && (
              <PersonBox
                name="partnerSelected"
                person={marital_status.partner}
                personName={marital_status.partner.fullName}
                description={
                  marital_status.partner.email
                    ? `E-mail: ${marital_status.partner.email}`
                    : marital_status.partner.birth
                    ? `Narození: ${moment(
                        marital_status.partner.birth,
                        "YYYY-MM-DD"
                      ).format("DD.MM.YYYY")}`
                    : "Žádné informace"
                }
                value={1}
                deletePerson={deleteConfirmation}
                register={register({ required: true })}
                edit={editPartner}
                id={marital_status.partner._id}
              />
            )}

            {!marital_status.partner.fullName && (
              <>
                <AddButton className="partner_add" onClick={addPartner}>
                  Přidat partnera
                </AddButton>

                <Tooltip
                  id={tooltipData["partner"].id}
                  title={tooltipData["partner"].title}
                  description={tooltipData["partner"].description}
                />
              </>
            )}
          </FormInput>
        )}

        <Alert text={error} />

        <SaveButton
          readyToRoll={isReadyToContinue}
          loading={changeMaritalStatusLoading}
        />
      </form>

      {isAddPersonModalOpen && (
        <AddPerson
          personType={personSelected.type}
          append={() => {}}
          personSelected={personSelected}
          toggle={toggleAddPersonModal}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteModal
          headerText="Odstranit partnera"
          toggleDeleteModal={toggleDeleteModal}
          loading={deletePartnerLoading}
          personSelected={personSelected}
          sendDeletion={sendDeletePartnerMutation}
        />
      )}
    </div>
  );
};

export default Partner;
