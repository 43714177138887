import { useEffect, useState } from "react";

interface UseReadyToContinueI {
  conditions: boolean[];
  runIfSomeConditionTrue?: any;
}

export const useIsReadyToContinue = ({
  conditions,
  runIfSomeConditionTrue,
}: UseReadyToContinueI) => {
  const [isReadyToContinue, setIsReadyToContinue] = useState(false);

  useEffect(() => {
    if (conditions.some((condition) => Boolean(condition))) {
      setIsReadyToContinue(true);

      if (runIfSomeConditionTrue) {
        runIfSomeConditionTrue();
      }
    } else {
      setIsReadyToContinue(false);
    }
  }, [conditions, runIfSomeConditionTrue]);

  return isReadyToContinue;
};
