import React from "react";
import Modal from "./Modal";

interface DeleteModalPropsI {
  toggleDeleteModal: any;
  personSelected: any;
  headerText: string;
  loading: boolean;
  sendDeletion: any;
}

const DeleteModal = (props: DeleteModalPropsI) => {
  const {
    toggleDeleteModal,
    personSelected,
    headerText,
    loading,
    sendDeletion,
  } = props;
  function deleteText() {
    if (personSelected?.fullName) {
      return personSelected.fullName;
    } else if (personSelected.gift) {
      return personSelected.gift;
    } else if (personSelected?.name) {
      return personSelected.name;
    } else {
      return "tento majetek";
    }
  }
  return (
    <Modal header={headerText} toggle={toggleDeleteModal}>
      <p className="deleteText">Opravdu si přejete odstranit {deleteText()}?</p>
      <div className="modal_footer">
        <button
          type="button"
          className="modal_save modal_save-delete"
          onClick={() => sendDeletion()}
        >
          {loading ? "Odstraňování..." : "Odstranit"}
        </button>
        <button
          type="button"
          onClick={() => toggleDeleteModal()}
          className="modal_cancel"
        >
          Zrušit
        </button>
      </div>
    </Modal>
  );
};

export default DeleteModal;
