import React from "react";
import { useSelector } from "react-redux";
import SurveyButton from "../../Components/Buttons/SurveyButton";
import { useWindowWidth } from "../../hooks";
import firstPicture from "../../Images/1.svg";
import secondThirdFourthPicture from "../../Images/234.svg";
import fifthSixthPicture from "../../Images/56.svg";

interface SummaryInfoBoxPropsI {
  handleLogout: () => any;
  logoutLoading: boolean;
  stepsCompleted: { [key: string]: boolean };
  pets: any[];
  childrenU18: any[];
}

const SummaryInfoBox = (props: SummaryInfoBoxPropsI) => {
  const paidForWillRedux = useSelector((state: any) => state.paymentReducer.paidForWill);

  const windowWidth = useWindowWidth();

  function getProgressText(showButtons: boolean) {
    if (paidForWillRedux) {
      return (
        <>
          <h1>Zaplaceno</h1>
          <p>
            Děkujeme za objednávku. Postupujte prosím podle dalších instrukcí v e-mailu.
          </p>
          <SurveyButton to={"/platba"} text="Stav objednávky" />
        </>
      );
    }
    if (!props.stepsCompleted.aboutYou) {
      return (
        <>
          <h1>Dobrý den,</h1>
          <p>
            v první části nám povíte <b>základní informace o sobě a vaší rodině</b>. Tato
            část by neměla zabrat více než 3 minuty.
          </p>
          {showButtons && <SurveyButton to={`${getRedirection()}`} text="Začít" />}
        </>
      );
    } else if (!props.stepsCompleted.guardians) {
      return (
        <>
          <h1>Jen tak dál!</h1>
          <p>
            První krok je za vámi. V následující části{" "}
            <b>vyberte svým dětem a domácím zvířatům opatrovníky a poručníky</b>. Ujistěte
            se tak, že budou vždy v dobrých rukách.
          </p>
          {showButtons && (
            <SurveyButton to={`${getRedirection()}`} text="Pokračovat v další sekci" />
          )}
        </>
      );
    } else if (!props.stepsCompleted.assetsProperty) {
      return (
        <>
          <h1>Polovinu máte za sebou!</h1>
          <p>
            V následující části máte možnost <b>vypsat váš veškerý majetek</b>. Tento krok
            by neměl zabrat více než 5 minut.
          </p>
          {showButtons && (
            <SurveyButton to={`${getRedirection()}`} text="Pokračovat v další sekci" />
          )}
        </>
      );
    } else if (!props.stepsCompleted.estate) {
      return (
        <>
          <h1>Teď to hlavní...</h1>
          <p>
            V dalším kroku máte možnost určit, <b>kdo zdědí váš majetek</b>. Většině lidem
            zabere tato část okolo 4 minut.
          </p>
          {showButtons && (
            <SurveyButton to={`${getRedirection()}`} text="Pokračovat v další sekci" />
          )}
        </>
      );
    } else if (!props.stepsCompleted.gifts || !props.stepsCompleted.restingPlace) {
      return (
        <>
          <h1>Teď už stačí jen vytisknout a podepsat</h1>
          <p>
            Pokud chcete, aby se závěť stala platnou, je potřeba ji nejdřív nechat
            zkontrolovat našimi experty, následně si hotový dokument vytisknout, a
            podepsat. Stále ještě ale můžete do závěti doplnit i další{" "}
            <a href="#gifts" style={{ color: "black" }}>
              <b>nepovinné části</b>
            </a>{" "}
            jako{" "}
            <a href="#gifts" style={{ color: "black" }}>
              <b>odkazy nebo poslední přání.</b> 
            </a>{" "}
          </p>
          <SurveyButton to="/platba" text="Zaplatit a poslat na kontrolu" />
        </>
      );
    } else {
      return (
        <>
          <h1>Vaše závěť je připravena na kontrolu</h1>
          <p>
            Gratulujeme! Vaše <b>závěť je připravena</b> na to, aby ji zkontrolovali naši
            experti.
          </p>
          <SurveyButton to={`${getRedirection()}`} text="Zaplatit a poslat na kontrolu" />
        </>
      );
    }
  }

  function getRedirection() {
    if (!props.stepsCompleted.aboutYou) {
      return "/o-vas/jmeno";
    } else if (!props.stepsCompleted.guardians) {
      if (props.childrenU18.length > 0) {
        return `/opatrovnictvi/${props.childrenU18[0]._id}/zvolit`;
      } else if (props.pets.length > 0) {
        return `/opatrovnictvi/${props.pets[0]._id}/zvolit`;
      }
    } else if (!props.stepsCompleted.assetsProperty) {
      return "/majetek";
    } else if (!props.stepsCompleted.estate) {
      return "/dedictvi/uvod";
    } else if (!props.stepsCompleted.gifts) {
      return "/odkazy";
    } else if (!props.stepsCompleted.restingPlace) {
      return "/posledni-prani";
    } else {
      return "/platba";
    }
  }
  function getPicture() {
    if (!props.stepsCompleted.aboutYou) {
      return firstPicture;
    } else if (!props.stepsCompleted.estate) {
      return secondThirdFourthPicture;
    } else {
      return fifthSixthPicture;
    }
  }

  return (
    <>
      <img className="main-img" src={getPicture()} alt="Postup fotka" />
      <div className="progress-text">
        {getProgressText(windowWidth < 900)}

        {windowWidth < 900 && (
          <>
            <button
              type="button"
              className="logout-button"
              onClick={() => props.handleLogout()}
            >
              {props.logoutLoading ? "Odhlašujeme..." : "Odhlásit se"}
            </button>

            {/* <div className="hotline">
              <img src={phoneIcon} alt="Ikona telefon" />
              <span>
                <b>315 558 136</b> — Pomocná linka
              </span>
            </div> */}
          </>
        )}
      </div>
    </>
  );
};

export default SummaryInfoBox;
