import React, { VFC } from "react";

interface PayWithTransferI {
  payWithTransferMutationWasCalled: boolean;
  payWithTransferMutation: any;
  payWithTransferLoading: boolean;
  willPrice: number;
  variableSymbol: string;
  userName: string;
}

export const PayWithTransfer: VFC<PayWithTransferI> = ({
  payWithTransferMutationWasCalled,
  payWithTransferMutation,
  payWithTransferLoading,
  willPrice,
  variableSymbol,
  userName,
}) => {
  return (
    <div className="ucet">
      <h2>Platba bankovním převodem</h2>

      {!payWithTransferMutationWasCalled ? (
        <div className="karta">
          <div className="payment_confirmation">
            <button
              className="button"
              onClick={() => payWithTransferMutation()}
            >
              Zaplatit převodem
            </button>
          </div>
        </div>
      ) : payWithTransferLoading ? (
        <div style={{ minHeight: "177px" }} className="ucetInner">
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="ucetInner">
          <div>
            Částka: <span>{willPrice} Kč</span>
            <br />
            Číslo účtu: <span>5735717339/0800</span>
            <br />
            Variabilní symbol: <span>{variableSymbol}</span>
            <br />
            Poznámka: <span>{userName}</span>
            <p>
              Po odeslání nám zašlete potvrzení převodu na e-mail{" "}
              <a href="mailto:podpora@goodbye.cz">podpora@goodbye.cz</a>
            </p>
          </div>

          <img
            alt="QR platba"
            src={`https://api.paylibo.com/paylibo/generator/czech/image?accountNumber=5735717339&bankCode=0800&amount=${willPrice}.00&currency=CZK&vs=${variableSymbol}&message=${userName}&size=200`}
          />
        </div>
      )}
    </div>
  );
};
