import React from "react";
import { useHistory } from "react-router-dom";

import "./GoBack.scss";

const GoBack = () => {
  const history = useHistory();

  function goBackFn() {
    history.goBack();
  }

  return (
    <div className="goBack">
      <button onClick={() => goBackFn()}>← Zpět</button>
    </div>
  );
};

export default GoBack;
