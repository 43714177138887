import { useCallback, useState } from "react";

export const useModal = (): [boolean, () => void] => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, []);

  return [isOpen, toggleModal];
};
