import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import GoBack from "../../../Components/Buttons/GoBack/GoBack";
import SaveButton from "../../../Components/Buttons/SaveButton";
import { AddButton, Alert, FormInput } from "../../../Components/Form";
import DeleteModal from "../../../Components/Modal/DeleteModal";
import AddPerson from "../../../Components/PersonBox/AddPerson";
import PersonBox from "../../../Components/PersonBox/PersonBox";
import { SEO } from "../../../Components/seo/Seo";
import {
  DELETE_PERSON_MUTATION,
  UPDATE_PET_MUTATION,
} from "../../../GraphQL/Mutations";
import { isOlderThan, isPersonOlderThan18Years } from "../../../Helpers";
import {
  useIsReadyToContinue,
  useModal,
  useSaveResultToRedux,
  useSendMutation,
} from "../../../hooks";
import {
  aboutYou_children,
  aboutYou_pets,
  getGifts,
  getInheritEstate,
  getRandomPeople,
  initialPerson,
  OneRandomPeople,
} from "../../../Redux/actions";
import { guardianUnder18SelectedText, textDictionary } from "./texts";
import Tooltip from "../../../Components/Tooltip/Tooltip";

interface ChooseI {
  type: "pet" | "child";
  subjectID: string;
  name: string;
  nextStep: string;
}

const Choose = (props: ChooseI) => {
  const { name, nextStep, subjectID, type } = props;

  const { register, handleSubmit, reset, watch } = useForm();

  const history = useHistory();

  const dispatch = useDispatch();

  const [isAddPersonModalOpen, toggleAddPersonModal] = useModal();
  const [isDeletePersonModalOpen, toggleDeletePersonModal] = useModal();

  const [personSelected, setPersonSelected] = useState<any>({
    ...initialPerson,
    type: "guardian",
  });

  const [error, setError] = useState("");

  const guardiansWatch = watch("guardians");

  const isReadyToContinue = useIsReadyToContinue({
    conditions: [
      Array.isArray(guardiansWatch) && guardiansWatch.length > 0,
      !Array.isArray(guardiansWatch) && guardiansWatch,
    ],
  });

  const {
    aboutYouReducer: {
      children,
      pets,
      marital_status: { partner },
    },
    randomPeopleReducer: randomPeople,
  } = useSelector((state: any) => state);


  

  const thisChild = children.find((el: any) => el._id === subjectID);
  const thisPet = pets.find((el: any) => el._id === subjectID);

  const randomPeopleOlderThanThisChild = randomPeople.filter(
    (randomPerson: OneRandomPeople) =>
      type === "child" ? isOlderThan(randomPerson, thisChild) : true
  );
  const childrenOlderThanThisChild = children.filter((someChild: any) =>
    type === "child" ? isOlderThan(someChild, thisChild) : true
  );

  const eligibleGuardians =
    partner.fullName && isPersonOlderThan18Years(partner)
      ? [
          ...randomPeopleOlderThanThisChild,
          ...childrenOlderThanThisChild,
          {
            _id: partner._id,
            fullName: partner.fullName,
            birth: partner.birth,
            address: {
              city: partner.address.city,
              street: partner.address.street,
            },
            type: "partner",
          },
        ]
      : [...randomPeopleOlderThanThisChild, ...childrenOlderThanThisChild];

  const eligibleGuardiansU18IDs = eligibleGuardians
    .filter((guardian: any) => !isPersonOlderThan18Years(guardian))
    .map((guaridanU18) => guaridanU18._id);

  const isSelectedGuardianUnder18 = Array.isArray(guardiansWatch)
    ? guardiansWatch.some((guardian: string) =>
        eligibleGuardiansU18IDs.includes(guardian)
      )
    : false;

  const {
    sendMutationRequest: sendDeleteGuardianMutation,
    loading: deletePersonLoading,
  } = useSendMutation(DELETE_PERSON_MUTATION);
  const { sendMutationRequest: sendEditPetMutation, loading: editPetLoading } =
    useSendMutation(UPDATE_PET_MUTATION);

  const saveToRedux = useSaveResultToRedux();

  const onSubmit = async (formData: any, e: any) => {
    setError("");

    e.preventDefault();

    if (!formData.guardians.length) {
      setError("Zvolte alespoň jednoho opatrovníka");

      return;
    }

    if (type === "pet") {
      const { updatePet } =
        (await sendEditPetMutation({
          id: thisPet._id,
          fullName: thisPet.name,
          guardian: formData.guardians,
        })) ?? {};

      saveToRedux(aboutYou_pets, updatePet.pets);

      history.push(nextStep);
    } else {
      saveToRedux(
        aboutYou_children,
        children.map((el: any) =>
          el._id === thisChild._id
            ? { ...el, guardian: formData.guardians }
            : el
        )
      );

      history.push(nextStep);
    }
  };

  function deleteConfirmation(person: any) {
    setPersonSelected(person);

    toggleDeletePersonModal();
  }

  async function deleteGuardianMutation() {
    try {
      const { deleteRandomPerson } =
        (await sendDeleteGuardianMutation({
          id: personSelected._id,
        })) ?? {};

      const { people, children, pets, gifts, inheritEstate } =
        deleteRandomPerson;

      saveToRedux(getRandomPeople, people);
      saveToRedux(aboutYou_children, children);
      saveToRedux(aboutYou_pets, pets);
      saveToRedux(getGifts, gifts);

      dispatch(
        getInheritEstate(
          inheritEstate.filter((i: any) => i.person),
          inheritEstate.filter((i: any) => i.charity)
        )
      );

      toggleDeletePersonModal();
    } catch (err) {
      console.log(err);
    }
  }

  function editPerson(person: any) {
    setPersonSelected(person); // This causes the header of the EDIT Modal to be whomever we're editing right now. Which might cause confusion --> We're on guardians page and editing an heir

    toggleAddPersonModal();
  }

  function addGuardian() {
    setPersonSelected({ ...initialPerson, type: "guardian" });

    toggleAddPersonModal();
  }

  useEffect(() => {
    if (thisPet) {
      // If eligibleGuardians has only one element, RHF behaves differently than when registering multiple elements with the same name
      if (eligibleGuardians.length > 1) {
        reset({ guardians: thisPet.guardian });
      } else {
        reset({ guardians: thisPet.guardian[0] });
      }
    } else if (thisChild) {
      if (eligibleGuardians.length > 1) {
        reset({ guardians: thisChild.guardian });
      } else {
        reset({ guardians: thisChild.guardian[0] });
      }
    }
  }, []);

  return (
    <div className="form">
      <SEO page="guardians_choose" />

      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          title={`Kdo byste chtěl/a, aby se postaral o ${name}?`}
          description={[
            textDictionary[type][0],
            textDictionary[type][1],
            textDictionary[type][2],
          ]}
        >
          {isSelectedGuardianUnder18 && (
            <p className="input_section-description">
              {guardianUnder18SelectedText}
            </p>
          )}



          {thisChild && 
            <Tooltip
              id="guardian"
              title="Poručníci"
              description={["Nezletilé dítě jako účastník pozůstalostního řízení nemůže v tomto řízení za sebe samostatně jednat. V případě, že nemůže být zastoupeno zákonným zástupcem (např. proto, že rodič je stejně jako on dědicem zůstavitele a hrozí tak střet zájmů) notář mu musí pro účely pozůstalostního řízení jmenovat opatrovníka k ochraně jeho práv v tomto řízení."]}
              customText="Jak by probíhalo řízení o pozůstalosti?"
            />
          }

          {eligibleGuardians.map((el: any, index: number) => {
            return (
              <PersonBox
                person={el}
                key={index}
                id={el._id}
                register={register}
                value={el._id}
                name="guardians"
                deletePerson={
                  el.type === "guardian" ? deleteConfirmation : false
                }
                edit={editPerson}
                personName={el.fullName}
                description={
                  moment(el.birth, "YYYY-MM-DD").format("DD.MM.YYYY") ||
                  "Opatrovník"
                }
                checkbox={true}
              />
            );
          })}

          {error && <Alert text={error} />}

          <AddButton className="partner_add" onClick={addGuardian}>
            Přidat {thisChild ? "poručníka" : "osobu"}
          </AddButton>
        </FormInput>

        <SaveButton loading={editPetLoading} readyToRoll={isReadyToContinue} />
      </form>

      {isAddPersonModalOpen && (
        <AddPerson
          toggle={toggleAddPersonModal}
          personType={personSelected.type}
          personSelected={personSelected}
          reset={reset}
          append={() => {}}
        />
      )}

      {isDeletePersonModalOpen && (
        <DeleteModal
          headerText="Smazat opatrovníka"
          loading={deletePersonLoading}
          personSelected={personSelected}
          sendDeletion={deleteGuardianMutation}
          toggleDeleteModal={toggleDeletePersonModal}
        />
      )}
    </div>
  );
};

export default Choose;
