import { RestingPlaceStateI } from "../actions";

export const restingPlaceReducer = (
  state: RestingPlaceStateI = { place: "", message: "" },
  action: any
) => {
  switch (action.type) {
    case "RESTING_PLACE":
      return { place: action.payload.place, message: action.payload.message };
    default:
      return state;
  }
};
