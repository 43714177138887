import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Names from './Names/Names';
import Address from './Basics/Address';
import Partner from './Partner/Partner';
import Children from './Children/Children';
import Pets from './Pets/Pets';

const index = (props: any) => {
  return (
    <Switch>
      <Route path='/o-vas/jmeno' component={Names} />
      <Route path='/o-vas/adresa' component={Address} />
      <Route path='/o-vas/partner' component={Partner} />
      <Route path='/o-vas/deti' component={Children} />
      <Route path='/o-vas/domaci-zvirata' component={Pets} />
      <Redirect from='/o-vas' to='/o-vas/jmeno' />
    </Switch>
  );
};

export default index;
