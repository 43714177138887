export const getInheritEstate = (
  inheritors: any[],
  charities: any[],
  allowOtherInheritorsToDecide?: boolean
) => ({
  type: "GET_INHERIT_ESTATE",
  payload: { inheritors, charities, allowOtherInheritorsToDecide },
});

export const addInheritors = (inheritors: string[]) => {
  return {
    type: "ADD_INHERITORS",
    payload: inheritors,
  };
};
export const addCharities = (charities: string[]) => ({
  type: "ADD_CHARITIES",
  payload: charities,
});

export const addInheritorsPercentages = (inheritorsPercentages: any[]) => ({
  type: "ADD_INHERITORS_PERCENTAGES",
  payload: inheritorsPercentages,
});

export const addCharitiesPercentages = (charitiesPercentages: any[]) => ({
  type: "ADD_CHARITIES_PERCENTAGES",
  payload: charitiesPercentages,
});

export const addBackupInheritor = (
  inheritorID: string,
  backupInheritors: any[],
  charities?: string[]
) => ({
  type: "ADD_BACKUP_INHERITOR",
  payload: { inheritorID, backupInheritors, charities },
});

export const allowOtherInheritorsToDecide = (allow: boolean) => ({
  type: "ALLOW_OTHERS_TO_DECIDE",
  payload: allow,
});
