export const assetTooltip = [
  "Specifikovat majetek není v závěti nutně potřeba. Při pozůstalostním řízení notář majetek zůstavitele za pomoci dědiců podrobně zjišťuje. Často se ale stává, že o některých položkách ale neví ani samotní dědicové a tak se na ně tak bohužel zapomene. Nejčastěji se tak stává u majetku, který se neeviduje v žádném veřejném rejstříku (např. katastru nemovitostí, registru silničních vozidel nebo obchodním rejstříku) a také u majetku, který je evidovaný v zahraničí - notář v těchto případech nemá, jak se o takovém majetku dozvědět.",
  "Nejen, že se složitým dohledáváním majetku pozůstalostní řízení zbytečně protáhne, ale u některých položek také nemusí být jejich vlastnictví převedeno. Následně je potom nutné, aby proběhlo dodatečné projednání pozůstalosti, ve kterém se proces u notáře opakuje. Sepsáním vašeho majetku můžete takovéto situaci jednoduše předejít.",
];

export const assetsNameDictionary: { [key: string]: string } = {
  other: "other",
  bank: "bankName",
  insurance: "insuranceProvider",
  pension: "pensionProvider",
  stocks: "stocksCompany",
  property: "address",
  business: "ownedBusiness",
  "": "type",
};

export const assetsTypeDictionary: { [key: string]: string } = {
  other: "Ostatní",
  bank: "Banka",
  insurance: "Zdravotní pojištění",
  pension: "Penzijní připojištění",
  stocks: "Akcie, cenné papíry",
  property: "Nemovitost",
  business: "Účast v obchodní společnosti",
  "": "type",
};
