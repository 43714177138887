import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import GoBack from "../../../Components/Buttons/GoBack/GoBack";
import SaveButton from "../../../Components/Buttons/SaveButton";
import { AddButton, Alert, FormButtons, FormInput } from "../../../Components/Form";
import DeleteModal from "../../../Components/Modal/DeleteModal";
import { NonDefaultCharityBox } from "../../../Components/PersonBox/PersonBox";
import { SEO } from "../../../Components/seo/Seo";
import { useModal, useSaveResultToRedux } from "../../../hooks";
import { addCharities } from "../../../Redux/actions/inheritEstate";
import AddCharity from "./AddCharity";
import CharitiesList from "./CharitiesList";

const Charities = () => {
  const history = useHistory();

  const { handleSubmit } = useForm();

  const charitiesIDsRedux = useSelector(
    (state: any) => state.inheritEstateReducer.charities
  ).map((char: any) => char.charity);

  const [isAddCharityModalOpen, toggleAddCharityModal] = useModal();
  const [isDeleteCharityModalOpen, toggleDeleteCharityModal] = useModal();

  const [includeCharity, setIncludeCharity] = useState<any>(
    charitiesIDsRedux.length ? true : ""
  );

  const [selectedCharity, setSelectedCharity] = useState("");

  const [selectedCharities, setSelectedCharities] = useState<string[]>([]);

  const [editingCharity, setEditingCharity] = useState({ charityName: "" });

  const [nonDefaultCharities, setNonDefaultCharities] = useState<string[]>([]);

  const [charitiesListIDs] = useState(
    () => new Set(CharitiesList.map((charity: any) => charity._id))
  );

  const [requiredError, setRequiredError] = useState(false);

  const saveToRedux = useSaveResultToRedux();

  function onSubmit(formData: any, e: any) {
    e.preventDefault();

    if (includeCharity !== false && includeCharity !== true) {
      setRequiredError(true);

      return;
    }

    saveToRedux(addCharities, [...selectedCharities, ...nonDefaultCharities]);

    if (!includeCharity) {
      setNonDefaultCharities([]);
    }

    history.push("/dedictvi/rozdeleni");
  }

  function handleIncludeCharity(e: any) {
    if (e.target.name === "yes") {
      setIncludeCharity(true);
    } else {
      setIncludeCharity(false);
    }
  }

  function selectCharity(e: any, id: string) {
    e.preventDefault();

    if (selectedCharities.includes(id)) {
      setSelectedCharities((prevState) => prevState.filter((ids) => ids !== id));
    } else {
      setSelectedCharities((prevState) => [...prevState, id]);
    }
  }

  function addNewCharity() {
    setEditingCharity({ charityName: "" });

    toggleAddCharityModal();
  }

  function handleCharityEdit(charityName: string) {
    setEditingCharity({ charityName });

    toggleAddCharityModal();
  }

  function deleteCharityConfirmation(charityName: string) {
    setSelectedCharity(charityName);

    toggleDeleteCharityModal();
  }

  function deleteNonDefaultCharity() {
    setNonDefaultCharities((prevState) =>
      prevState.filter((charity: string) => charity !== selectedCharity)
    );

    toggleDeleteCharityModal();
  }

  useEffect(() => {
    if (includeCharity === true || includeCharity === false) {
      setRequiredError(false);
    }
  }, [includeCharity]);

  useEffect(() => {
    if (charitiesIDsRedux) {
      const defaultCharities = charitiesIDsRedux.filter((charity: string) =>
        charitiesListIDs.has(charity)
      );

      const customCharities = charitiesIDsRedux.filter(
        (charity: string) => !charitiesListIDs.has(charity)
      );

      setSelectedCharities(() => defaultCharities);

      setNonDefaultCharities(() => customCharities);
    }
  }, []);

  return (
    <div className="estate charities-form form">
      <SEO page="estate_charities" />

      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          title={"Přál/a byste si do své závěti zahrnout i některé neziskové organizace?"}
          description={[
            "Spousta lidí zůstaví část svého majetku nějaké neziskové organizaci.",
            "Budete chtít tuto možnost využít? Pokud byste raději nějaké organizaci zanechali pouze určitou peněžní částku, můžete si ji přidat až v dalším kroku.",
          ]}
        >
          <FormButtons
            buttons={[
              {
                name: "yes",
                className: "single_button_section first_button",
                active: includeCharity === true,
                activeClassName: "pressed",
                text: "Ano",
              },
              {
                name: "no",
                className: "single_button_section",
                active: includeCharity === false,
                activeClassName: "pressed",
                text: "Ne",
              },
            ]}
            onClick={handleIncludeCharity}
          />

          {requiredError && <Alert text="Zvolte prosím jednu z možností" />}

          {includeCharity && (
            <>
              <div className="charities-list">
                <div className="charities-list-left">
                  {CharitiesList.slice(0, 3).map((el: any, index: number) => (
                    <div
                      onClick={(e) => selectCharity(e, el._id)}
                      className={`charities-box ${
                        selectedCharities.includes(el._id) ? "selected" : ""
                      }`}
                      key={index}
                    >
                      <div className="helper"></div>
                      <img style={el.style} alt={el.name} src={el.img} />
                    </div>
                  ))}
                </div>
                <div className="charities-list-right">
                  {CharitiesList.slice(3).map((el: any, index: number) => (
                    <div
                      onClick={(e) => selectCharity(e, el._id)}
                      className={`charities-box ${
                        selectedCharities.includes(el._id) ? "selected" : ""
                      }`}
                      key={index}
                    >
                      <div className="helper"></div>
                      <img style={el.style} alt={el.name} src={el.img} />
                    </div>
                  ))}
                </div>
              </div>

              {nonDefaultCharities.map((charity, index) => (
                <NonDefaultCharityBox
                  key={index}
                  charityName={charity}
                  editCharity={handleCharityEdit}
                  deleteCharity={deleteCharityConfirmation}
                />
              ))}

              <AddButton className="partner_add" onClick={addNewCharity}>
                + Přidat vlastní neziskovou organizaci
              </AddButton>
            </>
          )}
        </FormInput>

        <SaveButton loading={false} readyToRoll={true} />
      </form>

      {isAddCharityModalOpen && (
        <AddCharity
          toggle={toggleAddCharityModal}
          editingCharity={editingCharity}
          nonDefaultCharities={nonDefaultCharities}
          setNonDefaultCharities={setNonDefaultCharities}
        />
      )}

      {isDeleteCharityModalOpen && (
        <DeleteModal
          headerText="Odstranit neziskovou organizaci"
          loading={false}
          personSelected={{ fullName: selectedCharity }}
          sendDeletion={deleteNonDefaultCharity}
          toggleDeleteModal={toggleDeleteCharityModal}
        />
      )}
    </div>
  );
};

export default Charities;
