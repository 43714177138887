import React, { VFC } from "react";

interface RealTimeShareSumI {
  sumRealTime: string;
}

export const RealTimeShareSum: VFC<RealTimeShareSumI> = ({ sumRealTime }) => {
  return (
    <div className={`share-sum share-sum-${parseFloat(sumRealTime) > 100 ? "101" : parseInt(sumRealTime)}`}>
      <span>
        Rozdělený majetek:{" "}
        <b>{sumRealTime}%</b>
      </span>
    </div>
  );
};
