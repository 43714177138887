import React from 'react';
import LoadingImg from '../../Images/loading.svg';

import './Loading.scss';

const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <div className="loading-screen-animation">
        <img src={LoadingImg} alt="loading spinner" />
      </div>
    </div>
  );
};

export default LoadingScreen;
