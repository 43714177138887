import { gql } from '@apollo/client';

export const GET_PRICE_QUERY = gql`
  query getPrice {
    getWill {
      price
    }
  }
`;

export const GET_DISCOUNT_PRICE_QUERY = gql`
  query getDiscountPrice($code: String, $email: String) {
    useRefferalCode(code: $code, email: $email)
  }
`;

export const GET_PAID_FOR_WILL = gql`
  query {
    paidForWill
  }
`;

export const GET_ORDER_STATUS = gql`
  query {
    orderStatus
  }
`;

export const GET_USED_CODE_QUERY = gql`
  query {
    usedCode {
      amount
      codeType
    }
  }
`;

export const GET_WILL_DOWNLOAD_URL = gql`
  query {
    willDownloadURL
  }
`;
