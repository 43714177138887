export const getGifts = (gifts: any[]) => ({
  type: 'GET_GIFTS',
  payload: gifts,
});

export const postGift = (gift: any) => ({
  type: 'POST_GIFT',
  payload: gift,
});

export const editGift = (gift: any) => ({
  type: 'EDIT_GIFT',
  payload: gift,
});
