import Auto from "../../../Images/auto.svg";
import Cash from "../../../Images/cash.svg";
import Dum from "../../../Images/dum.svg";
import Prsten from "../../../Images/prsten.svg";

export const giftOptions = [
  {
    icon: Prsten,
    title: "Přidat věc nebo sbírku věcí",
    info: ["Např.: snubní prsten, kousek umění, hudební kolekce..."],
    redirectTo: "/odkazy/vec-sbirka",
  },
  {
    icon: Auto,
    title: "Přidat vozidlo",
    info: ["Např.: auto, motorka, loď..."],
    redirectTo: "/odkazy/vozidlo",
  },
  {
    icon: Dum,
    title: "Přidat nemovitost",
    info: ["Např.: rodinný dům, pozemek..."],
    redirectTo: "/odkazy/nemovitost",
  },
  {
    icon: Cash,
    title: "Přidat peníze",
    info: ["Např.: 10 000 Kč"],
    redirectTo: "/odkazy/penize",
  },
];
