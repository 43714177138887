import React from "react";
import { useSelector } from "react-redux";
import { useLogout } from "../../hooks";
import NotPaidForWillModal from "../Modal/NotPaidForWillModal";
import CustomerCarePhone from "./CustomerCarePhone/CustomerCarePhone";
import "./Header.scss";
import Logo from "./Logo/Logo";
import LogoutButton from "./LogoutButton/LogoutButton";

const Header = () => {
  const { isNotPaidForWillModalOpen, toggleNotPaidForWillModal, logout, logoutLoading } =
    useLogout();

  const logged = useSelector((state: any) => state.authenticationReducer);

  return (
    <div className="header-wrapper">
      <nav>
        <Logo logged={logged} />

        

        <CustomerCarePhone logged={logged} />

        <LogoutButton
          logged={logged}
          toggleModal={toggleNotPaidForWillModal}
          logout={logout}
          logOutQueryLoading={logoutLoading}
        />
      </nav>

      {isNotPaidForWillModalOpen && (
        <NotPaidForWillModal
          toggleNotPaidForWillModal={toggleNotPaidForWillModal}
          logout={logout}
        />
      )}
    </div>
  );
};

export default Header;
