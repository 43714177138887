import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LOGOUT_QUERY } from "../GraphQL/Queries";
import { redirectTo } from "../Helpers/routing";
import { user_logout } from "../Redux/actions";
import { useModal } from "./useModal";

export const useLogout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { paidForWill } = useSelector((state: any) => state.paymentReducer);
  const [isNotPaidForWillModalOpen, toggleNotPaidForWillModal] = useModal();

  const [logOutQuery, { client, loading: logoutLoading, data: logoutData }] =
    useLazyQuery(LOGOUT_QUERY, {
      fetchPolicy: "network-only",
    });

  function handleLogout(skipModal?: boolean) {
    if (paidForWill || skipModal) {
      logOutQuery();

      return;
    }

    toggleNotPaidForWillModal();
  }

  useEffect(() => {
    if (logoutData) {
      dispatch(user_logout());

      redirectTo("/prihlaseni", history);

      client?.clearStore();

      if (isNotPaidForWillModalOpen) {
        toggleNotPaidForWillModal();
      }
    }
  }, [logoutData]);

  return {
    logout: handleLogout,
    logoutLoading,
    isNotPaidForWillModalOpen,
    toggleNotPaidForWillModal,
  };
};
