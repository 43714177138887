import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Alert } from "../../../Components/Form";
import InviteFriends from "../../../Components/PaymentComponents/InviteFriends.tsx/InviteFriends";
import OrderStatus, {
  OrderStatusStatus,
} from "../../../Components/PaymentComponents/OrderStatus/OrderStatus";
import { ASK_FOR_RECHECKING_MUTATION } from "../../../GraphQL/Mutations";

interface ProcessingPropsI {
  createFriendsCode: any;
  createFriendsCodeLoading: boolean;
  friendsCode: string;
  orderStatusLoading: boolean;
  orderStatus: OrderStatusStatus;
}

const Processing = (props: ProcessingPropsI) => {
  const [askForRecheckingMutation, { called: askForRecheckingCalled }] =
    useMutation(ASK_FOR_RECHECKING_MUTATION);

  const [askedForRefetchingInfo, setAskedForRefetchingInfo] = useState(false);

  async function sendAskForRechecking() {
    try {
      const { data } = await askForRecheckingMutation();
      if (data.askForRechecking === "processing") {
        setAskedForRefetchingInfo(true);
        setTimeout(() => {
          setAskedForRefetchingInfo(false);
        }, 10000);
      }
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <div className="payment-processing">
      <div>
        <OrderStatus
          status={props.orderStatus}
          loading={props.orderStatusLoading}
        />
      </div>

      {!askForRecheckingCalled && (
        <div className="askForRecheck">
          <button
            className="edit-done"
            onClick={sendAskForRechecking}
            type="button"
          >
            Zažádat o překontrolování
          </button>
        </div>
      )}

      {askedForRefetchingInfo && (
        <Alert
          text="Vaše závěť byla úspěšně zaslána na kontrolu."
          type="info"
        />
      )}

      <div className="payment-right">
        <InviteFriends
          friendsCode={props.friendsCode}
          createFriendsCode={props.createFriendsCode}
          createFriendsCodeLoading={props.createFriendsCodeLoading}
        />
      </div>
    </div>
  );
};

export default Processing;
