import { gql } from '@apollo/client';

export const ADD_ASSET_MUTATION = gql`
  mutation addAsset(
    $type: String!
    $bankName: String
    $insuranceProvider: String
    $pensionProvider: String
    $stocksCompany: String
    $ownedBusiness: String
    $address: String
    $mortgage: String
    $owner: String
    $other: String
    $propertyInfo1: String
    $propertyInfo2: String
    $propertyInfo3: String
  ) {
    newAsset(
      newAsset: {
        type: $type
        bankName: $bankName
        insuranceProvider: $insuranceProvider
        pensionProvider: $pensionProvider
        stocksCompany: $stocksCompany
        ownedBusiness: $ownedBusiness
        address: $address
        mortgage: $mortgage
        owner: $owner
        other: $other
        propertyInfo1: $propertyInfo1
        propertyInfo2: $propertyInfo2
        propertyInfo3: $propertyInfo3
      }
    ) {
      _id
      type
      bankName
      insuranceProvider
      pensionProvider
      stocksCompany
      ownedBusiness
      address
      mortgage
      owner
      other
      propertyInfo1
      propertyInfo2
      propertyInfo3
    }
  }
`;
export const UPDATE_ASSET_MUTATION = gql`
  mutation updateAsset(
    $id: ID!
    $type: String!
    $bankName: String
    $insuranceProvider: String
    $pensionProvider: String
    $stocksCompany: String
    $ownedBusiness: String
    $address: String
    $mortgage: String
    $owner: String
    $other: String
    $propertyInfo1: String
    $propertyInfo2: String
    $propertyInfo3: String
  ) {
    updateAsset(
      asset: {
        type: $type
        bankName: $bankName
        insuranceProvider: $insuranceProvider
        pensionProvider: $pensionProvider
        stocksCompany: $stocksCompany
        ownedBusiness: $ownedBusiness
        address: $address
        mortgage: $mortgage
        owner: $owner
        other: $other
        propertyInfo1: $propertyInfo1
        propertyInfo2: $propertyInfo2
        propertyInfo3: $propertyInfo3
      }
      _id: $id
    ) {
      assets {
        _id
        type
        bankName
        insuranceProvider
        pensionProvider
        stocksCompany
        ownedBusiness
        address
        mortgage
        owner
        other
        propertyInfo1
        propertyInfo2
        propertyInfo3
      }
    }
  }
`;

export const DELETE_ASSET_MUTATION = gql`
  mutation deleteAssetMutation($id: ID!) {
    deleteAsset(_id: $id) {
      assets {
        _id
        type
        bankName
        insuranceProvider
        pensionProvider
        stocksCompany
        ownedBusiness
        address
        mortgage
        owner
        other
        propertyInfo1
        propertyInfo2
        propertyInfo3
      }
    }
  }
`;
