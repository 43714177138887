import { useLazyQuery, useMutation } from "@apollo/client";
import Cookies from "js-cookie";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Alert } from "../../Components/Form";
import { ADMIN_QUERY } from "../../GraphQL/Queries";
import { preventEnglishErrorMessage } from "../../Helpers/authentication";
import "./AdminPage.scss";
import ChangeUserForm from "./ChangeUserForm";

const AdminPage = () => {
  
  // create useState for different states of the app (login, app)
  const [error, setError] = useState("");
  const { register, handleSubmit, errors } = useForm();
  const [randomState, setRandomState] = useState(Math.random());

  const [adminQuery, { data: dataAdmin, error: errorAdmin, loading: loadingAdmin }] =
    useLazyQuery(ADMIN_QUERY, { fetchPolicy: "no-cache" });




  const onSubmit = (formData: any, e: any) => {
    e.preventDefault();
    const { userId, password } = formData;

    adminQuery({
      variables: { userId, password },
    });

  };

// console.log(dataAdmin);
// console.log(errorAdmin);
// console.log(loadingAdmin);

  let user = dataAdmin?.admin?.user;
  let will = dataAdmin?.admin?.will;
  console.log(user);
  console.log(will);

  let allPeople: Object[] = [];

  will?.children?.forEach((child: Object) => {
    allPeople.push(child);
  });

  allPeople.push(will?.maritalStatus?.partner);

  will?.people?.forEach((person: Object) => {
    allPeople.push(person);
  });


  const fromIdToPerson = (id: string) => {
    let person = allPeople.find((person: any) => person._id === id);
    return person;
  };





  return (
    <div>
      {dataAdmin ?
        <div className="adminMainScreen">
          <div className="top">
            <div className="left">
              <div className="sec">
                <span>Jméno zákazníka</span>
                <h2>{will.names.fullName}</h2>
              </div>

              {will.names.nickname &&
                <div className="sec">
                  <span>Přezdívka</span>
                  <h2>{will.names.nickname}</h2>
                </div>
              }

              <div className="sec">
                <span>Datum narození</span>
                <h2>{will.names.birth}</h2>
              </div>

              <div className="sec">
                <span>ID zákazníka</span>
                <h2>{user._id}</h2>
              </div>

              <div className="sec">
                <span>ID závěti</span>
                <h2>{user.will}</h2>
              </div>

              <div className="sec">
                <span>Cena</span>
                <h2>{will.price} Kč</h2>
              </div>
              
              {/* <div className="sec">
                <span>Uplatněný kód</span>
                <h2>"pomoztedetem"</h2>
              </div> */}

              <div className="sec">
                <span>Pohlaví</span>
                <h2>{will.names.gender}</h2>
              </div>
            </div>

            <div className="right">
              <div className="sec">
                <span>Status</span>
                <h2>{user.orderStatus}</h2>
              </div>

              {user.willDownloadURL &&
                <div className="sec">
                  <span>URL Závěti</span>
                  <a href={user.willDownloadURL} target="_blank">Odkaz</a>
                </div>
              }

              <ChangeUserForm 
                currentUser={user}
                reloadData={() => window.location.reload()}
              />

              <p>{randomState}</p>

              <div className="sec">
                <span>Email</span>
                <h2>{user.email}</h2>
              </div>

              <div className="sec">
                <span>Telefon</span>
                <h2>{will.address.phoneNumber}</h2>
              </div>
              
            </div>
          </div>
          <div className="bot">
            <section>
              <h2>O klientovi</h2>
              <h3>Adresa</h3>
              <ul>
                <li>{will.address.street}</li>
                <li>{will.address.city}</li>
                <li>{will.address.zipCode}</li>
              </ul>
              
              <h3>Povoluji dědickům se domluvit jinak?</h3>
              <ul>
                {will.allowInheritorsToDecide ? "Ano" : "Ne"}
              </ul>
              
            </section>

            <section>
              <h2>Partner</h2>
              {will.maritalStatus.partner ? <>
                <h3>Status</h3>
                <ul>
                  {will.maritalStatus.status}
                </ul>

                <h3>Informace o partnerovi</h3>
                <ul>
                  <li>Celé jméno: <b>{will.maritalStatus.partner.fullName}</b></li>
                  <li>Datum narození: <b>{will.maritalStatus.partner.birth}</b></li>
                  {/* <li>Je mu více než 18?: <b>{will.maritalStatus.partner.over18 ? "Ano" : "Ne"}</b></li> */}
                  {/* <li>Email: <b>{will.maritalStatus.partner.email}</b></li> */}
                  <li>Adresa: <b>{will.maritalStatus.partner.address.street}</b></li>
                  {/* <li>Adresa (Město): <b>{will.maritalStatus.partner.address.city}</b></li> */}
                </ul>
              </>:
                <h3>Nemá partnera</h3>
              }

            </section>

            <section>
              <h2>Děti</h2>

              {will.children.length > 0 ? will.children.map((child: any, index: number) => {
                return (
                  <div key={index}>
                    <h3>Dítě {index + 1}</h3>
                    <ul>
                      <li>Celé jméno: <b>{child.fullName}</b></li>
                      <li>Datum narození: <b>{child.birth}</b></li> 
                      {/* <li>Je mu více než 18?: <b>{child.over18 ? "Ano" : "Ne"}</b></li> */}
                      <li>Adresa: <b>{child.address.street}</b></li>
                      {child.guardian.length > 0 && <li>Opatrovnci:</li>}
                      {child.guardian.map((o: string) => {
                        let p: any = fromIdToPerson(o);
                        
                        return (
                          <ul key={o}>
                            {/* <li>ID Opatrovníka: <b>{o}</b></li> */}
                            <li>Celé jméno: <b>{p.fullName}</b></li>
                            <li>Datum narození: <b>{p.birth}</b></li>
                            {/* <li>Je mu více než 18?: <b>{p.over18 ? "Ano" : "Ne"}</b></li> */}
                            <li>Adresa: <b>{p.address?.street}</b></li>
                            {/* <li>Adresa (Město): <b>{p.address?.city}</b></li> */}
                            <br/>
                          </ul>
                        )
                      })}

                    </ul>
                  </div>
                )
              }) : <h3>Nemá děti</h3>}
            </section>

            {/* <section>
              <h2>Ostatní lidi</h2>
            </section> */}

            <section>
              <h2>Poslední přání</h2>

              <ul>
                <li>Jak: <b>{will.restingPlace.place}</b></li>
                <li>Zpráva: <b>{will.restingPlace.message}</b></li>
              </ul>
            </section>

            <section>
              <h2>Mazlíčci</h2>

              {will.pets.length > 0 ? will.pets.map((pet: any, index: number) => {
                return (
                  <div key={index}>
                    <h3>Mazlíček {index + 1}</h3>
                    <ul>
                      <li>Jméno: <b>{pet.name}</b></li>
                      {pet.guardian.length > 0 && <li>Opatrovnci:</li>}
                      {pet.guardian.map((o: string) => {
                        let p: any = fromIdToPerson(o);
                        
                        return (
                          <ul key={o}>
                            <li>Celé jméno: <b>{p.fullName}</b></li>
                            <li>Datum narození: <b>{p.birth}</b></li>
                            {/* <li>Je mu více než 18?: <b>{p.over18 ? "Ano" : "Ne"}</b></li> */}
                            <li>Adresa: <b>{p.address?.street}</b></li>
                            <br/>
                          </ul>
                        )
                      })}
                    </ul>
                  </div>
                )
              }) : <h3>Nemá mazlíčky</h3>}
            </section>

            <section>
              <h2>Rozdělení majetku</h2>

              <ul>
                {will.inheritEstate.length > 0 ? will.inheritEstate.map((estate: any, index: number) => {

                  let p: any = fromIdToPerson(estate.person);

                  return (
                    <li key={index}>
                      <h3>{index + 1}. část: {estate.percentageFromAssets}%</h3>
                      <ul>
                        {estate.person && <li>Celé jméno: <b>{p.fullName}</b></li>}
                        {estate.person && <ul>
                          <li>Datum narození: <b>{p.birth}</b></li>
                          {/* <li>Je mu více než 18?: <b>{p.over18 ? "Ano" : "Ne"}</b></li> */}
                          <li>Adresa: <b>{p.address?.street}</b></li>
                        </ul>}
                        {estate.charity && <li>Neziskovka: <b>{estate.charity}</b></li>}
                        {estate.theirKids && <li>Pokud zemře zdědí to <b>její děti</b></li>}
                        {estate.otherInheritors && <li>Pokud zemře rozdělí se to <b>mezi ostatní dědice</b></li>}
                        {estate.backupInheritors.length > 0 && estate.backupInheritors.map((backup: any, index: number) => {
                          let p: any = fromIdToPerson(backup.person);
                          
                          return (
                            <ul key={index}>
                              <li>{index + 1}. rezervní dědic: {backup.percentages}%</li>
                              <ul key={index}>
                                {backup.person && <li>Celé jméno: <b>{p.fullName}</b></li>}
                                {backup.person && <ul>
                                  <li>Datum narození: <b>{p.birth}</b></li>
                                  {/* <li>Je mu více než 18?: <b>{p.over18 ? "Ano" : "Ne"}</b></li> */}
                                  <li>Adresa: <b>{p.address?.street}</b></li>
                                </ul>}
                                {backup.charity && <li>Neziskovka: <b>{backup.charity}</b></li>}

                                <br/>
                              </ul>
                            </ul>
                          )
                        })}
                      </ul>
                    </li>
                  )
                }) : <h3>Nemá majetek</h3>}
              </ul>
            </section>

            <section>
              <h2>Dárky</h2>
              {will.gifts.length > 0 ? will.gifts.map((gift: any, index: number) => {
                return (
                  <div key={index}>
                    <h3>Dárek {index + 1}</h3>
                    <ul>
                      <li>Jméno: <b>{gift.gift}</b></li>
                      <li>Zpráva: <b>{gift.giftMessage}</b></li>
                      <li>Typ odkazu: <b>{gift.giftType}</b></li>
                      {gift.giftType === "property" && <ul>
                        <li>Hypotéka: <b>{gift.mortgage}</b></li>
                        <li>Majitel: <b>{gift.owner}</b></li>
                        <li>Parcelní číslo a katastrální území v katastru nemovitostí: <b>{gift.propertyInfo1}</b></li>
                        <li>Číslo popisné budovy, ev.č. budovy, obec a katastrální území: <b>{gift.propertyInfo2}</b></li>
                        <li>Číslo jednotky, katastrální území a obec: <b>{gift.propertyInfo3}</b></li>
                      </ul>}
                      <li>Lidi kterým to odkazuje: <b>{gift.amount}</b></li>
                      {gift.leaveItTo.length > 0 && gift.leaveItTo.map((o: string) => {
                        let p: any = fromIdToPerson(o);
                        
                        return (
                          <ul key={o}>
                            <li>Celé jméno: <b>{p?.fullName}</b></li>
                            <li>Datum narození: <b>{p?.birth}</b></li>
                            {/* <li>Je mu více než 18?: <b>{p.over18 ? "Ano" : "Ne"}</b></li> */}
                            <li>Adresa: <b>{p?.address?.street}</b></li>
                            <br/>
                          </ul>
                        )
                      })}
                      {gift.charities.length > 0 && <li>Neziskovky kterým to odkazujeme:</li> }
                      {gift.charities.length > 0 && gift.charities.map((o: string) => {
                        return (
                          <ul key={o}>
                            <li><b>{o}</b></li>
                          </ul>
                        )
                      })}
                    </ul>
                  </div>
                )
              }) : <h3>Nemá odkazy</h3>}
            </section>
            <section>
              <h2>Výpis majetku</h2>
              {will.assets.length > 0 ? will.assets.map((asset: any, index: number) => {
                return (
                  <div key={index}>
                    <h3>Majetek {index + 1}</h3>

                    {/* address, bankName, insuranceProvider, mortgage, other, ownedBusiness, owner, pensionProvider, stocksCompany, type */}

                    <ul>
                      {asset.address && <li>Adresa: <b>{asset.address}</b></li>}
                      {asset.bankName && <li>Název banky: <b>{asset.bankName}</b></li>}
                      {asset.insuranceProvider && <li>Poskytovatel pojištění: <b>{asset.insuranceProvider}</b></li>}
                      {asset.mortgage && <li>Hypotéka: <b>{asset.mortgage}</b></li>}
                      {asset.other && <li>Jiné: <b>{asset.other}</b></li>}
                      {asset.ownedBusiness && <li>Vlastněný podnik: <b>{asset.ownedBusiness}</b></li>}
                      {asset.owner && <li>Majitel: <b>{asset.owner}</b></li>}
                      {asset.pensionProvider && <li>Poskytovatel důchodu: <b>{asset.pensionProvider}</b></li>}
                      {asset.stocksCompany && <li>Společnost s akciemi: <b>{asset.stocksCompany}</b></li>}
                      {asset.type && <li>Typ: <b>{asset.type}</b></li>}
                    </ul>
                  </div>
                )
              }) : <h3>Nemá majetek</h3>}

            </section>
          </div>
        </div>
        :
        <div className="loginPage adminLogin">
          <h1>Admin Panel</h1>

          <form className="login_form" onSubmit={handleSubmit(onSubmit)}>
            {error && <Alert text={error} />}
            {errorAdmin?.message && (
              <Alert text={preventEnglishErrorMessage(errorAdmin.message)} />
            )}

            <div className="credentialsInput">
              <label>ID Uživatele</label>
              <input
                ref={register({
                  required: {
                    value: true,
                    message: "Vyplňte prosím ID Uživatele",
                  },
                })}
                name="userId"
                type="text"
                placeholder="Např.: 6422cc80aec12a0014f7b1d1"
              />
            </div>

            <span className="formError">{errors.userId?.message}</span>

            <div className="credentialsInput">
              <label>Heslo</label>
              <input
                ref={register({
                  required: {
                    value: true,
                    message: "Vyplňte prosím toto pole",
                  },
                })}
                name="password"
                type="password"
                autoComplete="current-password"
              />
            </div>

            <span className="formError">{errors.password?.message}</span>

            {loadingAdmin ? (
              <span className="save-continue">Načítání...</span>
            ) : (
              <button type="submit">Pokračovat</button>
            )}
          </form>
        </div>
      }
    </div>
  );
};

export default AdminPage;
