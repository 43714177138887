import GooglePay from "../../../Images/googlepay.png";
import ApplePay from "../../../Images/applePay.svg";
import MasterCard from "../../../Images/masterCard.svg";
import Visa from "../../../Images/visa.svg";
import React from "react";

export const whatYouPayForText = [
  "Vaše závěť podle plně platného vzoru vytvořeného advokátní kanceláří",
  "Profesionální kontrola dokumentu našimi experty",
  "Kompletní návod pro výběr svědků, podepsání a úschovy dokumentu",
  "Neomezený počet úprav dokumentu po dobu 12 měsíců",
  <>Vlastní <a href="http://goodbye.cz/digitalni-archiv/" target="_blank">digitální archiv</a> (včetně podpory digitální konverze dokumentů na CzechPoint)</>,
  "1 000 Kč sleva na předplacený pohřeb (nepovinné)",
];

export const cardOptions = [
  {
    src: MasterCard,
    alt: "MasterCard",
  },
  {
    src: Visa,
    alt: "Visa",
  },
  {
    src: GooglePay,
    alt: "GooglePay",
  },
  {
    src: ApplePay,
    alt: "ApplePay",
  },
];
