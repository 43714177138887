import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import NotFoundPage from "../../Other/404/NotFound";
import Choose from "./Choose";
import Responsibility from "./Responsibility";

const Guardians: any = () => {
  const children = useSelector((state: any) => state.aboutYouReducer.children);
  const childrenUnder18 = children.filter((el: any) => !el.over18);
  const pets = useSelector((state: any) => state.aboutYouReducer.pets);

  return (
    <Switch>
      {childrenUnder18.length > 0 &&
        childrenUnder18.map((el: any, index: number) => {
          const nextChildren =
            childrenUnder18.length - 1 === index ? -0 : index + 1;
          return (
            <Route
              key={index}
              path={`/opatrovnictvi/${el._id}/`}
              render={(props) => (
                <Switch key={index} {...props}>
                  <Route
                    path={`/opatrovnictvi/${el._id}/zvolit`}
                    render={(props) => (
                      <Choose
                        name={el.fullName}
                        subjectID={el._id}
                        type="child"
                        nextStep={`/opatrovnictvi/${el._id}/odpovednost`}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={`/opatrovnictvi/${el._id}/odpovednost`}
                    render={(props) => (
                      <Responsibility
                        name={el.fullName}
                        subjectID={el._id}
                        type="child"
                        nextStep={
                          nextChildren
                            ? `/opatrovnictvi/${childrenUnder18[nextChildren]._id}/zvolit`
                            : pets.length > 0
                            ? `/opatrovnictvi/${pets[0]._id}/zvolit`
                            : "/prehled"
                        }
                        {...props}
                      />
                    )}
                  />
                  <Route path="">
                    <NotFoundPage />
                  </Route>
                </Switch>
              )}
            />
          );
        })}
      {pets.length > 0 &&
        pets.map((el: any, index: number) => {
          const nextPet = pets.length - 1 === index ? -0 : index + 1;
          return (
            <Route
              key={index}
              path={`/opatrovnictvi/${el._id}/zvolit`}
              render={(props) => (
                <Choose
                  type="pet"
                  subjectID={el._id}
                  name={el.name}
                  nextStep={
                    nextPet
                      ? `/opatrovnictvi/${pets[nextPet]._id}/zvolit`
                      : "/prehled"
                  }
                  {...props}
                />
              )}
            />
          );
        })}
      <Route path="">
        <NotFoundPage />
      </Route>
    </Switch>
  );
};

export default Guardians;
