export interface OneRandomPeople {
  _id: string;
  fullName: string;
  birth: string;
}

export const addToRandomPeople = (onePeople: OneRandomPeople) => {
  return {
    type: 'ADD_ONE_RANDOM_PEOPLE',
    payload: onePeople
  };
};
export const getRandomPeople = (people: OneRandomPeople[]) => {
  return {
    type: 'GET_RANDOM_PEOPLE',
    payload: people
  };
};
