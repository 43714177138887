export enum CharitiesActions {
  FILL_CHARITIES = "FILL_CHARITIES",
  GET_FEATURED_CHARITIES = "GET_FEATURED_CHARITIES",
  GET_OTHER_CHARITIES = "GET_OTHER_CHARITIES",
  ADD_MY_CHARITY = "ADD_MY_CHARITY",
}

export const fillCharities = (
  featuredCharities: any[],
  otherCharities: any[]
) => ({
  type: CharitiesActions.FILL_CHARITIES,
  payload: { featuredCharities, otherCharities },
});

export const getFeaturedCharities = (featuredCharities: any[]) => ({
  type: CharitiesActions.GET_FEATURED_CHARITIES,
  payload: featuredCharities,
});

export const getOtherCharities = (otherCharities: any[]) => ({
  type: CharitiesActions.GET_OTHER_CHARITIES,
  payload: otherCharities,
});

export const addMyCharity = (charity: any) => ({
  type: CharitiesActions.ADD_MY_CHARITY,
  payload: charity,
});
