import React from "react";
import { Link } from "react-router-dom";
// @ts-ignore
import LogoImg from "../../../Images/logo.svg";

const Logo = ({ logged }: { logged: boolean }) => {
  return (
    <div className="left">
      {logged ? (
        <Link className="home_link" to={logged ? "/prehled" : "/"}>
          <img src={LogoImg} alt="Logo" />
        </Link>
      ) : (
        <a
          className="home_link"
          href="https://goodbye.cz/zaveti"
          rel="noopener noreferrer"
        >
          <img src={LogoImg} alt="Logo" />
        </a>
      )}
    </div>
  );
};

export default Logo;
