import React from "react";
import { Link, useHistory } from "react-router-dom";
import Modal from "../../Components/Modal/Modal";
import { DELETE_USER_MUTATION } from "../../GraphQL/Mutations";
import { useModal, useSendMutation } from "../../hooks";
import "./Settings.scss";

const Settings = () => {
  const history = useHistory();

  const {
    sendMutationRequest: sendDeleteUserMutation,
    loading: deleteUserLoading,
  } = useSendMutation(DELETE_USER_MUTATION);

  const [isDeleteConfirmationOpen, toggleDeleteConfirmation] = useModal();

  const removeUser = async () => {
    await sendDeleteUserMutation();

    history.push("/prihlaseni");
  };

  return (
    <div className="settingsPage">
      <Link className="backToHomepage" to="/prehled">
        ← Zpět na hlavní stránku
      </Link>

      <h1>Nastavení</h1>

      <div className="settingsPage-section settingsPage-section-delete">
        <h2>Smazat účet a všechna data</h2>

        <button onClick={() => toggleDeleteConfirmation()}>Smazat účet</button>
      </div>

      {isDeleteConfirmationOpen && (
        <Modal header={"Smazat účet"} toggle={toggleDeleteConfirmation}>
          <p className="deleteText">Opravdu si přejete smazat váš účet?</p>

          <div className="modal_footer">
            <button
              type="button"
              className="modal_save modal_save-delete"
              onClick={() => removeUser()}
            >
              {deleteUserLoading ? "Odstraňování..." : "Odstranit"}
            </button>

            <button
              type="button"
              className="modal_cancel"
              onClick={() => toggleDeleteConfirmation()}
            >
              Zrušit
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Settings;
