export interface RestingPlaceStateI {
  place: string;
  message: string;
}

export const addRestingPlace = (place: string, message: string) => {
  return {
    type: 'RESTING_PLACE',
    payload: { place, message }
  };
};

export const addSpecialWish = (wish: string) => {
  return {
    type: 'SPECIAL_WISH',
    payload: wish
  };
};
