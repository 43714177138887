import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import Gifts from "./Gifts";
import { giftTypeDic } from "./giftsHelpers";
import Money from "./GiftTypes/Money";
import PropertyAsGift from "./GiftTypes/PropertyAsGift";
import SingleItem from "./GiftTypes/SingleItem";
import Vehicle from "./GiftTypes/Vehicle";

const Index: any = () => {
  const giftsRedux = useSelector((state: any) => state.giftsReducer);

  return (
    <Switch>
      {giftsRedux.map((gift: any) => (
        <Route
          key={gift._id}
          path={`/odkazy/${giftTypeDic[gift.giftType]}/${gift._id}`}
          render={(props) => {
            switch (gift.giftType) {
              case "itemCollection":
                return <SingleItem {...props} editingGift={gift} />;
              case "property":
                return <PropertyAsGift {...props} editingGift={gift} />;
              case "vehicle":
                return <Vehicle {...props} editingGift={gift} />;
              case "money":
                return <Money {...props} editingGift={gift} />;
            }
          }}
        />
      ))}

      <Route path="/odkazy/vec-sbirka" component={SingleItem} />

      <Route path="/odkazy/nemovitost" component={PropertyAsGift} />

      <Route path="/odkazy/vozidlo" component={Vehicle} />

      <Route path="/odkazy/penize" component={Money} />

      <Route path="/odkazy" component={Gifts} />
    </Switch>
  );
};

export default Index;
