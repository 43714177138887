import React from "react";

import "./OrderStatus.scss";
import Loading from "../../../Images/loading.svg";

interface OrderStatusPropsI {
  status: OrderStatusStatus;
  loading: boolean;
  willLink?: string;
}

export type OrderStatusStatus = "paid" | "processing" | "finished" | "not_paid";

const OrderStatus = (props: OrderStatusPropsI) => {
  const messageTextDictionary = {
    paid: {
      status: "Zaplaceno",
      text: [
        "Za objednávku bylo úspěšně zaplaceno a na závěti začneme pracovat co nevidět.",
        "Stáhnout ji bude možné hned, jak ji ověříme. O všem vás budeme informovat e-mailem.",
      ],
      link: "",
    },
    processing: {
      status: "Zpracováváme",
      text: [
        "Vaši závěť právě zpracováváme.",
        "Jakmile ji ověříme, zašleme vám e-mail s dalším postupem.",
      ],
      link: "",
    },
    finished: {
      status: "Hotovo",
      text: [
        "Vaše závěť je hotová a připravená na stažení.",
        "Klikněte na odkaz níže a postupujte dle instrukcí v dokumentu.",
      ],
      link: props?.willLink,
    },
    not_paid: {
      status: "Nezaplaceno",
      text: [
        "Něco se nepovedlo.",
        "Kontaktujte nás prosím na čísle 315 558 136.",
      ],
      link: "",
    },
  };

  return (
    <div className="orderStatus">
      <h3>Stav vaší objednávky:</h3>
      {props.loading ? (
        <img alt="" src={Loading} />
      ) : (
        <div className={"orderStatus-text " + props.status}>
          <div className="orderStatus-status">
            {messageTextDictionary[props.status].status}
          </div>
          {messageTextDictionary[props.status].text.map(
            (text: string, i: number) => (
              <p key={i} className="orderStatus-paragraph">
                {text}
              </p>
            )
          )}
          {messageTextDictionary[props.status].link && (
            <a
              target="_blank"
              rel="noreferrer noopenner"
              href={messageTextDictionary[props.status].link}
            >
              Stáhnout
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export default OrderStatus;
