import React, { FC } from "react";
import { Link } from "react-router-dom";
import { OptionalSteps } from "./OptionalSteps";
import { RequiredSteps } from "./RequiredSteps";

interface StepsI {}

export const Steps: FC<StepsI> = (props) => {
  return (
    <div className="steps">
      <RequiredSteps />

      <h3 id="gifts" className="optional-h">
        Volitelné
      </h3>

      <OptionalSteps />

      <div className="settingsStep">
        <h2>Nastavení</h2>
        <Link to="/nastaveni">Otevřít</Link>
      </div>
    </div>
  );
};
