import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CHANGE_ADMIN_MUTATION } from "../../GraphQL/Mutations";
import { useSendMutation } from "../../hooks";

const ChangeUserForm = ({currentUser, reloadData}: any) => {
  const { register, handleSubmit, errors } = useForm();
  const [isDone, setIsDone] = useState(false);

  const {
    sendMutationRequest: changeAdminMutation,
    loading: loading,
  } = useSendMutation(CHANGE_ADMIN_MUTATION);



  async function onSubmit(formData: any, e: any) {
    e.preventDefault();

    const { willDownloadURL, orderStatus } = formData;
    let userId = currentUser._id;

    const result = await changeAdminMutation({ userId, willDownloadURL, orderStatus });

    if (result) {
      setIsDone(true);
      reloadData();
    }

    else {
      console.log(result);
    }
  };






  //"not_paid", "paid", "processing", "finished"

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p>Změnit status</p>
      <select
        ref={register()}
        name="orderStatus"
        defaultValue={currentUser.orderStatus}
      >

        
        <option value="not_paid">not_paid</option>
        <option value="paid">paid</option>
        <option value="processing">processing</option>
        <option value="finished">finished</option>
      </select>

      <p>Změnit URL</p>
      <input
        ref={register()}
        name="willDownloadURL"
        type="text"
        placeholder="Tady pastni URL"
        defaultValue={currentUser.willDownloadURL}
      />

      <button type="submit">Uložit</button>
    </form>
  );
};

export default ChangeUserForm;
