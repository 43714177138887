export const propertyInfoFormOptions = [
  {
    label: "Parcelní číslo a katastrální území v katastru nemovitostí",
    inputName: "propertyInfo1",
    placeholder: "",
  },
  {
    label: "Číslo popisné budovy, ev.č. budovy, obec a katastrální území",
    inputName: "propertyInfo2",
    placeholder: "",
  },
  {
    label: "Číslo jednotky, katastrální území a obec",
    inputName: "propertyInfo3",
    placeholder: "",
  },
];

export const mortgageFormOptions = [
  {
    value: "yes",
    text: "Ano",
  },
  {
    value: "no",
    text: "Ne",
  },
  {
    value: "dont_know",
    text: "Nevím",
  },
];

export const ownerFormOptions = [
  {
    value: "onlyOwner",
    text: "Pouze já",
  },
  {
    value: "jointOwner",
    text: "Spoluvlastním ji s někým jiným",
  },
  {
    value: "dont_know",
    text: "Nevím",
  },
];
